import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useParams } from 'react-router-dom';
import { firebase_app } from '../Config/Config';
import { authHeader, handleResponse } from '../Services/Fack.Backend';
import { checkAuthTokenAvailability } from '../utils/cookieReducer';

const PrivateRoute = () => {
    const params = useParams()
    const [currentUser, setCurrentUser] = useState(false);
    const [authenticated, setAuthenticated] = useState(false);
    const [isMounted, setIsMounted] = useState(false);
    let jwt_token = checkAuthTokenAvailability();
    console.log('PrivateRoute >> jwt_token:', jwt_token)

    useEffect(() => {
        setIsMounted(true);
        if (isMounted) {
            // const requestOptions = { method: 'GET', headers: authHeader() };
            // fetch('/users', requestOptions).then(handleResponse);
            // firebase_app.auth().onAuthStateChanged(setCurrentUser);
            // setAuthenticated(JSON.parse(localStorage.getItem('authenticated')));
            // localStorage.setItem('authenticated', authenticated);
            // localStorage.setItem('currentUser', currentUser);

            jwt_token = checkAuthTokenAvailability();
        }
        return () => {
            setIsMounted(false);
        };
    }, []);

    console.log('PrivateRoute >> currentUser,authenticated,jwt_token:', currentUser, authenticated, jwt_token)

    return (
        currentUser || authenticated || jwt_token ?
            <Outlet />
            :
            <Navigate exact to={`${process.env.PUBLIC_URL}/${params.hotelcode}/login`} />
    );
};

export default PrivateRoute;

