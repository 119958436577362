import React from 'react'
import { Btn, H5 } from '../../../../AbstractElements'
import { Card, CardBody } from 'reactstrap'

export const TextViewType = ({ data }) => {
  return (
   <>
      <Card className="rounded-0 my-2">
         <CardBody className="py-3 px-4">
            <div className="d-flex p-0 m-0 justify-content-between align-items-center">
               <H5 attrH5={{ className: 'mb-0' }}>{data.name}</H5>
               <Btn
                  attrBtn={{
                     color: "secondary",
                     outline: true,
                     className: "rounded-0 m-0"
                  }}
               >
                  Request
               </Btn>
            </div>
         </CardBody>
      </Card>
   </>
  )
}