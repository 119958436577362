import React, { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import { Btn, H6 } from '../../../../../AbstractElements';
import Increment from '../DisplayComponents/Increment';
import { useSelector } from 'react-redux';
import { categoriesSelector } from '../categoriesSlice';
import { Container } from 'reactstrap';
import BarLoader from '../../../../../Layout/Loader/BarLoader';
import { useDispatch } from 'react-redux';
import { deleteCategoryComponent } from '../categoriesApi';
import { toast } from 'react-toastify';
import { EssentialsModal } from '../EssentialsModal';

const Essentials = ({ searchText }) => {
  const { categoryComponentsData, departmentsData, isLoading } = useSelector(categoriesSelector);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(null);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  
  const handlePerPageRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  function deleteCB(data) {
    if(data.success) {
      toast.success("Deleted successfully");
    } else {
      toast.error("Something went wrong. Please try again later");
    }
  }

  const handleEdit = (data) => {
    setIsEdit(!isEdit);
    setEditData(data);
  };
  
  const handleDelete = (id) => {
    let conf = window.confirm("Are you sure that you want to delete this item ?");
    if(conf) {
      dispatch(deleteCategoryComponent({ CB: deleteCB, id }));
    }
  };

  const existingViewRanks = [];

  let dataRows =
    categoryComponentsData?.component?.Essentials?.filter((obj) =>
      obj.name.toLowerCase().includes(searchText.toLowerCase()) ||
      obj.description.toLowerCase().includes(searchText.toLowerCase()) ||
      obj.viewType.toLowerCase().includes(searchText.toLowerCase())
    ).map((obj) => {
        existingViewRanks.push(obj.viewRank);
        let departmentId = obj.department;
        let departmentName = departmentsData?.find(obj => obj._id == departmentId)?.departmentName;

        return {
          ...obj,
          departmentName: departmentName ?? obj.department,
          action: (
            <Container className="d-flex btn-showcase">
              <Btn
                attrBtn={{
                  color: "primary",
                  outline: true,
                  onClick: () => handleEdit(obj),
                  disabled: isLoading
                }}
              >
                {"Edit"}
              </Btn>
              <Btn
                attrBtn={{
                  color: "danger",
                  outline: true,
                  onClick: () => handleDelete(obj._id),
                  disabled: isLoading
                }}
              >
                {"Delete"}
              </Btn>
            </Container>
          )
        };
      }
    )?.sort((a, b) => a.viewRank - b.viewRank) ?? [];
  
  const dataColumns = [
    {
      name: <H6>#</H6>,
      selector: (row, index) => ((currentPage - 1) * perPage) + (index + 1),
      center: true,
      wrap: true,
      width: "77px"
    },
    {
      name: <H6 attrH6={{
        className: "m-l-15"
      }}>Name</H6>,
      selector: (row) => row.name,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "233px",
    },
    {
      name: <H6 attrH6={{
        className: "m-l-15"
      }}>Description</H6>,
      selector: (row) => row.description,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "213px",
    },
    {
      name: <H6 attrH6={{
        className: "m-l-15"
      }}>Department</H6>,
      selector: (row) => row.departmentName,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "213px"
    },
    {
      name: <H6 attrH6={{
        className: "m-l-15"
      }}>Quantity</H6>,
      selector: (row) => row.quantity,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6 attrH6={{
        className: "m-l-15"
      }}>Max Quantity Per Room</H6>,
      selector: (row) => row.maxQuantityPerRoom,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "250px",
    },
    {
      name: <H6 attrH6={{
        className: "m-l-15"
      }}>Amount</H6>,
      selector: (row) => row.amount,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "173px",
    },
    {
      name: <H6 attrH6={{
        className: "m-l-15"
      }}>Component Name</H6>,
      selector: (row) => row.viewType,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "203px",
    },
    {
      name: <H6>Component</H6>,
      selector: (row) => row.viewType == "increment" ? <Increment quantity={row.quantity} /> : null,
      center: true,
      wrap: true,
      minWidth: "203px",
    },
    {
      name: <H6 attrH6={{
        className: "m-l-15"
      }}>View Rank</H6>,
      selector: (row) => row.viewRank,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "183px",
    },
    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
      width: "333px"
    }
  ];
  
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <>
      {isEdit ? (
        <EssentialsModal
          isOpen={isEdit}
          ModalTitle={"Edit Essential Item"}
          toggler={handleEdit}
          editData={editData}
          existingViewRanks={existingViewRanks}
        />
      ) : null}
      
      <div className="order-history table-responsive m-l-15">
        <DataTable
          pagination
          paginationComponentOptions={paginationOptions}
          data={dataRows}
          columns={dataColumns}
          responsive={true}
          progressPending={isLoading}
          progressComponent={<BarLoader />}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerPageRowsChange}
        />
      </div>
    </>
  );
}

export default Essentials;