import React, { useEffect, useMemo, useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import InputPrev from "../../../../mycomponents/InputPrev";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { addRoomTypes, updateRoomTypes } from "./api";
import { useDispatch } from "react-redux";

const RoomTypesModal = ({ isOpen, ModalTitle, toggler, editData }) => {
  console.log("editData:", editData);
  const dispatch = useDispatch();

  const [isPrimary, setIsPrimary] = useState(false);

  const [imageFileA, setImageFileA] = useState(null);
  const [imageFileB, setImageFileB] = useState(null);
  const [imageFileC, setImageFileC] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return editData;
    }, [editData]),
  });

  useEffect(() => {
    setValue("isPrimary", isPrimary);
    setValue("imageFileC", imageFileC);

    setValue("images", [
      { image: [imageFileA], tag: "primary" },
      { image: [imageFileB], tag: "secondary" },
    ]);
  }, [isPrimary, imageFileA, imageFileB, imageFileC]);

  useEffect(() => {
    let cldURL = `https://res.cloudinary.com/shashigroup/image/upload/`;

    if (editData) {
      setIsPrimary(editData?.isPrimary);

      setImageFileA(`${cldURL}${editData?.images[0]?.image[0]}`);
      setImageFileB(`${cldURL}${editData?.images[1]?.image[0]}`);

      setImageFileC(editData?.images[1]?.image[0]);
    }
  }, [editData]);

  const functionCB = (data) => {
    if (data.success == true) {
      toggler();
      reset();
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message || "Oops.. Something went wrong. Please try again."
      );
    }
  };

  const onSubmit = (data) => {
    console.log(data);

    if (editData) {
      dispatch(updateRoomTypes({ ...data, CB: functionCB }));
    } else {
      dispatch(addRoomTypes({ ...data, CB: functionCB }));
    }
  };

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={toggler}
        togglerSave={handleSubmit(onSubmit)}
        isUploading={isUploading}
      >
        <Container fluid={true} className="bd-example-row">
          <form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Name:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="AND-01"
                    name="name"
                    {...register("name", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.name && "Name is required"}
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Code:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="AND"
                    name="code"
                    {...register("code", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.code && "Code is required"}
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Amount:"}</Label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="AND"
                    name="amount"
                    {...register("amount", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.amount && "Amount is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup className="mb-0">
                  <Label>{"Room Description:"}</Label>
                  <textarea
                    className="form-control"
                    name="roomDescription"
                    rows="3"
                    {...register("roomDescription", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.roomDescription && "Room Description is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="mb-0">
                  <Label>{"Technology:"}</Label>
                  <textarea
                    className="form-control"
                    name="technology"
                    rows="3"
                    {...register("technology", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.technology && "Technology is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup className="mb-0">
                  <Label>{"In Room:"}</Label>
                  <textarea
                    className="form-control"
                    name="inRoom"
                    rows="3"
                    {...register("inRoom", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.inRoom && "In Room is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <InputPrev
              inputLabel={"Change Image:"}
              inputLabelClass={"col-sm-6"}
              imageLabel={"Primary Image:"}
              imageLabelClass={"col-sm-5"}
              src={imageFileA}
              setImageFile={setImageFileA}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
            />
            <InputPrev
              inputLabel={"Change Image:"}
              inputLabelClass={"col-sm-6"}
              imageLabel={"Secondary Image:"}
              imageLabelClass={"col-sm-5"}
              src={imageFileB}
              setImageFile={setImageFileB}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
            />
            <InputPrev
              inputLabel={"Change Image:"}
              inputLabelClass={"col-sm-6"}
              imageLabel={"Floor Image:"}
              imageLabelClass={"col-sm-5"}
              src={imageFileC}
              setImageFile={setImageFileC}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
            />
            <Row className="mb-2">
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="isPrimary"
                    name="isPrimary"
                    type="checkbox"
                    checked={isPrimary}
                    onChange={(e) => setIsPrimary(e.target.checked)}
                  />
                  <Label className="d-block" htmlFor="isPrimary">
                    Make Default?
                  </Label>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </CommonModal>
    </>
  );
};

export default RoomTypesModal;
