import { createSlice } from "@reduxjs/toolkit";
import { fetchAdmin } from "./api";

const initialState = {
  adminData: {},
  isLoading: false,
  status: {
    apiMessage: "",
    apiSuccess: true,
  },
};

const adminSlice = createSlice({
  initialState,
  name: "fetchAdmin",
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAdmin.fulfilled, (state, action) => {
        // console.log("admin fulfilled:", action.payload);

        state.isLoading = false;
        state.adminData = action.payload;
      })
      .addCase(fetchAdmin.rejected, (state, { payload }) => {
        console.log("admin rejected: payload.status", payload.status); // Getting using rejectWithValue

        state.isLoading = false;
        state.status = payload.status;
      });
  },
});

export default adminSlice.reducer;
export const { setStatus } = adminSlice.actions;
export const adminSelector = (state) => state.fetchAdmin;
