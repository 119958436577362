import React, { useEffect, useMemo, useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import InputPrev from "../../../../mycomponents/InputPrev";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addOrEditItemInsideCategory } from "./api";
import { toast } from "react-toastify";

const MExploreModal = ({
  isOpen,
  ModalTitle,
  toggler,
  editItemData,
  activeTab,
  apiResponseData,
  setApiResponseData
}) => {
  const dispatch = useDispatch();

  const [imageFileA, setImageFileA] = useState(null);
  const [imageFileB, setImageFileB] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [desktopImageUploading, setDesktopImageUploading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return editItemData;
    }, [editItemData]),
  });

  useEffect(() => {
    setValue("image", imageFileA);
    setValue("desktopImage", imageFileB);
  }, [imageFileA, imageFileB]);

  useEffect(() => {
    if (editItemData) {
      setImageFileA(editItemData?.image);
      setImageFileB(editItemData?.desktopImage);
    }
  }, [editItemData]);

  const addOrEditItemInsideCategoryCallback = (data) => {
    reset();
    if(data?.success) {
      setApiResponseData(data);
      if(editItemData) return toast.success("Edited successfully!");
      else return toast.success("Added successfully!");
    } else {
      return toast.error("Something went wrong, please try again later.");
    }
  }

  const onSubmit = (data) => {
    if (data) {
      dispatch(addOrEditItemInsideCategory({
        ...data,
        categoryId: apiResponseData?.result?.data[activeTab]?.categoryId,
        CB: addOrEditItemInsideCategoryCallback
      }));
      toggler();
    }
  };

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={toggler}
        togglerSave={handleSubmit(onSubmit)}
        isUploading={isUploading}
      >
        <Container fluid={true} className="bd-example-row">
          <Row>
            <Col>
              <FormGroup>
                <Label>{"Title:"}</Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Item name"
                  name="title"
                  {...register("title", { required: true })}
                />
                <span style={{ color: "#ff5370" }}>
                  {errors.title && "Title is required"}
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{"Title Link:"}</Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Title link"
                  name="titleUrl"
                  {...register("titleUrl")}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{"Description:"}</Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Item description"
                  name="description"
                  {...register("description", { required: true })}
                />
                <span style={{ color: "#ff5370" }}>
                  {errors.description && "Description is required"}
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{"Details (+):"}</Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Item Additional Details"
                  name="details"
                  {...register("details")}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{"Display Order:"}</Label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="Display Order"
                  name="index"
                  {...register("index", { required: true })}
                />
                <span style={{ color: "#ff5370" }}>
                  {errors.index && "Display Order is required"}
                </span>
              </FormGroup>
            </Col>
          </Row>

          <Row className="mb-2">
            <Col>
              <FormGroup>
                <Label>{"Additional Content:"}</Label>
                <textarea
                  className="form-control"
                  rows="3"
                  name="additionalContent"
                  {...register("additionalContent")}
                />
              </FormGroup>
            </Col>
          </Row>

          <InputPrev
            inputLabel={"Change Image:"}
            inputLabelClass={"col-sm-7"}
            imageLabel={"Image:"}
            imageLabelClass={"col-sm-5"}
            src={imageFileA}
            setImageFile={setImageFileA}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
          />
          <InputPrev
            inputLabel={"Change Desktop Image:"}
            inputLabelClass={"col-sm-7"}
            imageLabel={"Desktop Image:"}
            imageLabelClass={"col-sm-5"}
            src={imageFileB}
            setImageFile={setImageFileB}
            isUploading={desktopImageUploading}
            setIsUploading={setDesktopImageUploading}
          />
        </Container>
      </CommonModal>
    </>
  );
};

export default MExploreModal;
