import React, { useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";

const ComponentModal = ({ isOpen, ModalTitle, toggler, editData }) => {
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <CommonModal isOpen={isOpen} title={ModalTitle} toggler={toggler}>
        <Container fluid={true} className="bd-example-row">
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit}
          >
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Component Type:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="increment"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Required Keys:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="write keys with commaseperated"
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Container>
      </CommonModal>
    </>
  );
};

export default ComponentModal;
