import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn, H6, H4 } from "../../../../AbstractElements";
import DepartmentsModal from "./DepartmentsModal";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import { hotelStaffSelector } from "../Slice/hotelStaffSlice";
import { useDispatch, useSelector } from "react-redux";
import { fetchDepartments, removeDepartment } from "../Api/api";
import BarLoader from "../../../../Layout/Loader/BarLoader";
import { toast } from "react-toastify";

const Departments = () => {
  const dispatch = useDispatch();
  const { departmentsData, isLoading, status } =
    useSelector(hotelStaffSelector);

  const [searchText, setSearchText] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    dispatch(fetchDepartments());
  }, []);

  const handleAdd = () => {
    setIsAdd(!isAdd);
  };

  const handleEdit = (data) => {
    setIsEdit(!isEdit);
    setEditData(data);
  };

  const handleSearch = (e) => {
    e.preventDefault();
  };

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Data successfully deleted");
    } else {
      return toast.error(
        data.result?.message || "Oops.. Something went wrong Please try again"
      );
    }
  };

  const handleDelete = (id) => {
    console.log("handle delete id", id);
    let conf = window.confirm(
      "Are you sure you want to delete this department?"
    );

    if (conf) {
      dispatch(removeDepartment({ id, CB: functionCB }));
    }
  };

  const DepartmentsData = departmentsData?.result?.data
    ?.filter((val, i) => {
      return (
        val &&
        Object.values(val).some((field) => {
          if (typeof field === "string") {
            return field.toLowerCase().includes(searchText.toLowerCase());
          }
          return false;
        })
      );
    })
    .map((val, i) => {
      if (val) {
        return {
          departmentName: val.departmentName,
          action: (
            <Container className="d-flex btn-showcase">
              <Btn
                attrBtn={{
                  color: "primary",
                  outline: true,
                  onClick: () => handleEdit(val),
                }}
              >
                {"Edit"}
              </Btn>
              <Btn
                attrBtn={{
                  color: "danger",
                  outline: true,
                  onClick: () => handleDelete(val._id),
                }}
              >
                {"Delete"}
              </Btn>
            </Container>
          ),
        };
      } else {
        return null;
      }
    });

  const DepartmentsColumns = [
    {
      name: <H6>#</H6>,
      selector: (row, index) => (currentPage - 1) * perPage + (index + 1),
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Name</H6>,
      selector: (row) => row.departmentName,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  return (
    <Fragment>
      {(isAdd || isEdit) && (
        <DepartmentsModal
          isOpen={true}
          ModalTitle={isAdd ? "Add Department" : "Edit Department"}
          toggler={isAdd ? handleAdd : handleEdit}
          editData={isEdit && editData}
        />
      )}

      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs
            parent="Department Configuration"
            title="Department Configuration"
          />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />

            <Btn
              attrBtn={{
                className: "m-r-15 m-l-15 d-block",
                color: "success",
                // outline: true,
                onClick: handleAdd,
              }}
            >
              Add Department
            </Btn>
          </div>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab">
                  <H4>DEPARTMENTS DATA</H4>
                </div>
              </Col>

              <CardBody>
                <div className="order-history table-responsive">
                  <DataTable
                    data={DepartmentsData}
                    columns={DepartmentsColumns}
                    responsive={true}
                    pagination
                    progressPending={isLoading}
                    progressComponent={<BarLoader />}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Departments;
