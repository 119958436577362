import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_RESERVATION } from "../../../../utils/axiosInterceptors";
import { config } from "../../../../config";

export const fetchAmenities = createAsyncThunk(
  "amenities/getallamenities",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("amenities payload:", state);

    try {
      let { data } = await AXIOS_RESERVATION.get(`getallamenities`);
      // console.log("data:", data);

      return data;
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const addAmenities = createAsyncThunk(
  "amenities/saveamenities",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("amenities payload:", state);

    let { CB, ...others } = state;
    others.hotelId = config.HOTELID;

    console.log("others:", others);

    var headers = {
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.post(`saveamenities`, others, {
        headers,
      });
      // console.log("data:", data);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchAmenities());

        return data;
      }
    } catch (err) {
      // console.log("err:", err);

      CB && CB(err);
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const editAmenities = createAsyncThunk(
  "amenities/editAmenities",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("amenities payload:", state);

    let { CB, ...others } = state;
    // console.log("others:", others);
    // console.log("CB:", CB);

    try {
      let { data } = await AXIOS_RESERVATION.post(`editAmenities`, others);
      // console.log("data:", data);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchAmenities());

        return data;
      }
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const removeAmenities = createAsyncThunk(
  "amenities/removeAmenities",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("amenities payload:", state);

    let { id, CB } = state;
    console.log("_id:", id);

    var headers = {
      _id: id,
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.delete(`removeAmenities`, {
        headers,
      });
      // console.log("data:", data);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchAmenities());

        return data;
      }
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);
