import React, { useEffect, useMemo, useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import MyDropdown from "../../../../mycomponents/MyDropdown";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { addOrEditAddonPackage } from "./api";
import { useDispatch } from "react-redux";
import MySelectDropd from "../../../../mycomponents/MySelectDropd";
import { useSelector } from "react-redux";
import { packagesSelector } from "./packagesSlice";
import PackageImagesInput from "./PackageImagesInput";

const PackagesModal = ({ isOpen, ModalTitle, toggler, editData, packagesData }) => {
  let { status } = useSelector(packagesSelector);
  const dispatch = useDispatch();

  const [componentType, setComponentType] = useState("singleSelect");
  const [isActive, setIsActive] = useState(false);
  const [imageFiles, setImageFiles] = useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [disablePackages, setDisablePackages] = useState([]);
  const [darkMode, setDarkMode] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return editData;
    }, [editData]),
  });

  useEffect(() => {
    setValue("componentType", componentType);
    setValue("isActive", isActive);
    setValue("disablePackages", disablePackages);
    setValue("darkMode", darkMode);
    setValue("images", imageFiles);
    if(imageFiles?.length) setDarkMode(false);
  }, [componentType, isActive, disablePackages, imageFiles, darkMode]);

  useEffect(() => {
    if (editData) {
      setComponentType(editData?.componentType ?? "singleSelect");
      setImageFiles(editData?.images?.length ? editData.images : []);
      setIsActive(editData?.isActive);
      setDarkMode(editData?.darkMode);
    }
  }, [editData]);

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data?.message ||
        "Something went wrong. Please try again later"
      );
    } else if (status) {
      return toast.error(status?.apiMessage ? status.apiMessage : "Something went wrong. Please try again later");
    }
  };

  const onSubmit = (data) => {
    toggler();
    let payload = {
      title: data.title,
      requestBodyKey: data.requestBodyKey,
      componentType: data.componentType,
      darkMode: data.darkMode,
      displayOrder: parseInt(data.displayOrder),
      isActive: data.isActive,
      terms: data.terms,
      images: imageFiles,
      disablePackages: data.disablePackages
    };

    if (editData) {
      dispatch(
        addOrEditAddonPackage({
          ...payload,
          id: editData._id,
          CB: functionCB,
        })
      );
    } else {
      dispatch(addOrEditAddonPackage({ ...payload, CB: functionCB }));
    }

    reset();
    setComponentType("singleSelect");
    setDisablePackages([]);
    setIsActive(true);
    setDarkMode(false);
    setImageFiles([]);
  };

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={toggler}
        togglerSave={handleSubmit(onSubmit)}
        isUploading={isUploading}
      >
        <Container fluid={true} className="bd-example-row">
          <form
            onSubmit={(e) => e.preventDefault()}
            className="needs-validation"
            noValidate=""
          >
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Package Title"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="title"
                    placeholder="Package Title"
                    {...register("title", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.title && "Package title is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"requestBodyKey"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="requestBodyKey"
                    name="requestBodyKey"
                    {...register("requestBodyKey")}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.requestBodyKey && "requestBodyKey is required"}
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Terms"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="terms"
                    name="terms"
                    {...register("terms")}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.terms && "Terms is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Display Order"}</Label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="0"
                    name="displayOrder"
                    {...register("displayOrder", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.displayOrder && "Display order is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm="6" col="9" className="btn-showcase">
                <FormGroup>
                  <Label>{"Component Type"}</Label>
                  <MyDropdown
                    title={componentType}
                    setTitle={setComponentType}
                    data={[{ opt: "singleSelect" }, { opt: "doubleSelect" }]}
                  />
                </FormGroup>
              </Col>
            </Row>

            <PackageImagesInput
              inputLabel={"Images"}
              imagesLabel={"Selected Images"}
              allImages={imageFiles}
              setImageFiles={setImageFiles}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
            />

            <Row className="mt-2 mb-3">
              <Col>
                <Label className="f-16">Disable Packages</Label>
                <MySelectDropd
                  name={"disablePackages"}
                  selectedOpt={(pkg) => {
                    setDisablePackages(pkg);
                  }}
                  options={
                    editData
                      ? packagesData
                        ?.filter(
                          (packageObj) => packageObj._id != editData._id
                        )
                        ?.map((packageObj) => {
                          return {
                            key: packageObj._id,
                            label: packageObj.title,
                          };
                        })
                      : packagesData?.map((packageObj) => {
                        return {
                          key: packageObj._id,
                          label: packageObj.title,
                        };
                      })
                  }
                  selectedValues={
                    editData
                      ? editData.disablePackages?.map(packageId => packageId)
                      : disablePackages
                  }
                />
              </Col>
            </Row>

            {
              (!imageFiles?.length)
                ? <Row className="mb-2">
                    <Col>
                      <div className="d-flex">
                        <Input
                          className="checkbox_animated"
                          id="darkMode"
                          name="darkMode"
                          type="checkbox"
                          checked={darkMode}
                          onChange={(e) => setDarkMode(e.target.checked)}
                        />
                        <Label className="d-block" htmlFor="darkMode">
                          Dark Mode
                        </Label>
                      </div>
                    </Col>
                  </Row>
                : null
            }

            <Row className="mb-2">
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="isActive"
                    name="isActive"
                    type="checkbox"
                    checked={isActive}
                    onChange={(e) => setIsActive(e.target.checked)}
                  />
                  <Label className="d-block" htmlFor="isActive">
                    Active Status
                  </Label>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </CommonModal>
    </>
  );
};

export default PackagesModal;
