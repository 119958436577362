import React from "react";
import Default from "../../DashBoard/Default/Default";

const Dashboard = () => {
  return (
    <>
      <Default />
    </>
  );
};

export default Dashboard;
