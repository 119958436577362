import React, { useEffect, useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import MyDropdown from "../../../../mycomponents/MyDropdown";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { categoriesSelector } from "./categoriesSlice";
import { config } from "../../../../config";
import { addNewCategoryComponent, editCategoryComponent } from "./categoriesApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

export const ComponentModal = ({
   isOpen,
   ModalTitle,
   currentTabData,
   isMainComponent,
   isEdit,
   editData,
   toggler
}) => {
   if(currentTabData && isEdit) editData = currentTabData;
   const dispatch = useDispatch();
   const { departmentsData, error } = useSelector(categoriesSelector);
   const [componentName, setComponentName] = useState("Select Component");
   const [department, setDepartment] = useState("Select Department");
   const [isVisible, setIsVisible] = useState(false);
   const [inactive, setInactive] = useState(false);

   useEffect(() => {
      setValue("categoryName", currentTabData.goTo);
   }, [currentTabData]);

   useEffect(() => {
      if (editData) {
         setComponentName(editData.viewType);
         if (editData.department) {
            setDepartment(departmentsData?.find(obj => obj._id == editData.department)?.departmentName ?? "Select Department");
         }
         setIsVisible(editData.isVisible);
         setInactive(editData.inactive ?? false);

         setValue("categoryName", editData.categoryName);
         setValue("componentName", editData.viewType);
         if(editData.viewType == "webView") setValue("name", editData.title);
         else setValue("name", editData.name);
         if(editData.hasOwnProperty("subtitle")) setValue("subtitle", editData.subtitle);
         if(editData.hasOwnProperty("goTo")) setValue("goTo", editData.goTo);
         if(editData.hasOwnProperty("amount")) setValue("amount", editData.amount);
         if(editData.hasOwnProperty("link")) setValue("link", editData.link);
         if(editData.hasOwnProperty("description")) setValue("description", editData.description);
         if(editData.hasOwnProperty("type")) setValue("type", editData.type);
         if(editData.hasOwnProperty("qty")) setValue("qty", editData.qty);
         if(editData.hasOwnProperty("quantity")) setValue("quantity", editData.quantity);
         if(editData.hasOwnProperty("price")) setValue("price", editData.price);
         if(editData.hasOwnProperty("maxQuantityPerRoom")) setValue("maxQuantityPerRoom", editData.maxQuantityPerRoom);
         if(editData.hasOwnProperty("viewRank")) setValue("viewRank", editData.viewRank);
         if(editData.hasOwnProperty("department")) setValue("department", editData.department);
         if(editData.hasOwnProperty("gatewayCart")) setValue("gatewayCart", editData.gatewayCart);
         else setValue("gatewayCart", "");
      }
   }, [editData]);

   const {
      register,
      handleSubmit,
      setValue,
      reset,
      formState: { errors },
   } = useForm();

   useEffect(() => {
      if(componentName !== "Select Component") setValue("componentName", componentName);
   }, [componentName]);

   useEffect(() => {
      if (department !== "Select Department") setValue("department", department);
   }, [department]);

   useEffect(() => {
      setValue("isVisible", isVisible);
   }, [isVisible]);

   useEffect(() => {
      setValue("inactive", inactive);
   }, [inactive]);

   let departments = JSON.parse(JSON.stringify(departmentsData ?? []));
   departments = departments?.map((obj) => {
      obj.opt = obj.departmentName;
      return obj;
   });

   const addCB = (data) => {
      if(data.success) {
         return toast.success("Added Successfully");
      } else {
         return toast.error(error ?? "Something went wrong. Please try again later.");
      }
   }

   const editCB = (data) => {
      if(data?.success) {
         toast.success("Edited Successfully");
      } else {
         toast.error(data?.message || data?.response?.data?.message || "Something went wrong. Please try again later.");
      }
   };

   const handleClose = () => {
      toggler();
      reset();
      setComponentName("Select Component");
      setDepartment("Select Department");
      setIsVisible(false);
      setValue("isVisible", false);
      setInactive(false);
      setValue("inactive", false);
   };

   const submenus = ["submenu", "submenuwithcart"];
   const increments = ["increment", "incrementwithimagewithoutrequest"];
   const invalidValues = [null, undefined, ""];

   const onSubmit = (formValues) => {
      handleClose(true);

      if(formValues.componentName == "webView") {
         formValues.title = formValues.name;
         delete formValues.name;

         if(invalidValues.includes(formValues.qty)) formValues.qty = "0"; // "0" value found in every documents as of now
         if(invalidValues.includes(formValues.price)) formValues.price = "0"; // "0" value found in every documents as of now
      } else {
         delete formValues.subtitle;
         delete formValues.link;
         delete formValues.qty;
         delete formValues.price;
      }

      if(!increments.includes(formValues.componentName)) {
         delete formValues.amount
         delete formValues.description;
         delete formValues.quantity;
         delete formValues.maxQuantityPerRoom;
         if(!submenus.includes(formValues.componentName)) delete formValues.type;
         delete formValues.viewRank
      } else {
         formValues.amount = parseInt(formValues.amount);
         if(invalidValues.includes(formValues.quantity)) formValues.quantity = 0;
         else formValues.quantity = parseInt(formValues.quantity);

         if(invalidValues.includes(formValues.maxQuantityPerRoom)) formValues.maxQuantityPerRoom = 0;
         else formValues.maxQuantityPerRoom = parseInt(formValues.maxQuantityPerRoom);
         formValues.viewRank = parseInt(formValues.viewRank);
      }

      if(!submenus.includes(formValues.componentName)) delete formValues.goTo;
      else formValues.goTo = formValues.goTo.split(" ").join("");

      if(invalidValues.includes(formValues.gatewayCart)) delete formValues.gatewayCart;
      if(invalidValues.includes(formValues.department)) delete formValues.department;

      delete formValues.departmentName;
      delete formValues.viewType;
  
      let { categoryName, componentName, isVisible, ...data } = formValues;
      data.hotelId = config.HOTELID;
  
      for(let key in data) {
        delete formValues[`${key}`];
      }
  
      if(data.department) data.department = departmentsData?.find(obj => obj.departmentName == data.department)?._id;
  
      delete data._id;
      formValues.data = data;

      if(editData) {
         dispatch(editCategoryComponent({ ...formValues, id: editData._id, CB: editCB }));
      } else {
         if(submenus.includes(componentName)) {
            formValues.isSubmenu = true;
         }
         dispatch(addNewCategoryComponent({ ...formValues, CB: addCB }));
      }
   };

   let allComponents = [];

   if(isMainComponent) {
      allComponents = [
         { opt: "submenu" },
         { opt: "webView" },
         { opt: "increment" },
         { opt: "submenuwithcart" },
         { opt: "incrementwithimagewithoutrequest" },
         // { opt: "fixedbutton" }
      ];
   } else {
      allComponents = [
         { opt: "webView" },
         { opt: "increment" },
         { opt: "incrementwithimagewithoutrequest" },
         // { opt: "fixedbutton" }
      ];
   }

   return (
      <>
         <CommonModal
            isOpen={isOpen}
            title={ModalTitle}
            toggler={handleClose}
            togglerSave={handleSubmit(onSubmit)}
         >
            <Container>
               <form className="needs-validation" noValidate="">
                  <Row className="d-flex">
                     <Col>
                        <FormGroup>
                           <Label>{"Category Name"}</Label>
                           <input
                              className="form-control"
                              type="text"
                              name="categoryName"
                              defaultValue={currentTabData?.goTo}
                              {...register("categoryName", { required: !currentTabData?.goTo })}
                              disabled={true}
                           />
                           <span style={{ color: "#ff5370" }}>
                              {errors.categoryName && "Category Name is required"}
                           </span>
                        </FormGroup>
                     </Col>
                  </Row>

                  <Row className="mb-3">
                     <Col sm="6" className="btn-showcase">
                        <FormGroup>
                           <Label>{"Component Name"}</Label>
                           <MyDropdown
                              title={componentName}
                              setTitle={setComponentName}
                              data={allComponents}
                              registerProps={register("componentName", {
                                 required: true,
                              })}
                              disable={isEdit ? true : false}
                           />
                           <span style={{ color: "#ff5370" }}>
                              {errors.componentName && "Component Name is required"}
                           </span>
                        </FormGroup>
                     </Col>
                     {
                        submenus.includes(componentName) ?
                        <Col>
                           <FormGroup>
                              <Label>{"goTo"}</Label>
                              <input
                                 className="form-control"
                                 type="text"
                                 name="goTo"
                                 {...register("goTo", { required: true })}
                                 disabled={isEdit}
                              />
                              <span style={{ color: "#ff5370" }}>
                                 {errors.goTo && "goTo is required for submenu"}
                              </span>
                           </FormGroup>
                        </Col> : null
                     }
                  </Row>

                  <Row className="d-flex">
                     <Col sm="6">
                        <FormGroup>
                           <Label>{"Item Name/Title"}</Label>
                           <input
                              className="form-control"
                              type="text"
                              name="name"
                              {...register("name", { required: true })}
                           />
                           <span style={{ color: "#ff5370" }}>
                              {errors.name && "Item Name/Title is required"}
                           </span>
                        </FormGroup>
                     </Col>
                     {
                        isMainComponent && submenus.includes(componentName) ?
                        <Col sm="6">
                           <FormGroup>
                              <Label>{"Type"}</Label>
                              <input
                                 className="form-control"
                                 type="text"
                                 name="type"
                                 {...register("type")}
                              />
                           </FormGroup>
                        </Col> : null
                     }
                     {
                        componentName == "webView" ?
                        <Col sm="6">
                           <FormGroup>
                              <Label>{"Subtitle"}</Label>
                              <input
                                 className="form-control"
                                 type="text"
                                 name="subtitle"
                                 {...register("subtitle")}
                              />
                           </FormGroup>
                        </Col> : null
                     }
                     {
                        increments.includes(componentName) ?
                        <Col sm="6">
                           <FormGroup>
                              <Label>{"Amount"}</Label>
                              <input
                                 className="form-control"
                                 type="number"
                                 name="amount"
                                 {...register("amount", { required: true })}
                              />
                              <span style={{ color: "#ff5370" }}>
                                 {errors.amount && "Amount is required"}
                              </span>
                           </FormGroup>
                        </Col> : null
                     }
                  </Row>

                  {
                     componentName == "webView" ?
                     <Row>
                        <Col sm="12">
                           <FormGroup>
                              <Label>{"Webview Link"}</Label>
                              <input
                                 className="form-control"
                                 type="text"
                                 name="link"
                                 {...register("link", { required: true })}
                              />
                              <span style={{ color: "#ff5370" }}>
                                 {errors.link && "Link is required"}
                              </span>
                           </FormGroup>
                        </Col>
                        <Col sm="6">
                           <FormGroup>
                              <Label>{"Quantity"}</Label>
                              <input
                                 className="form-control"
                                 type="number"
                                 name="qty"
                                 {...register("qty")}
                              />
                           </FormGroup>
                        </Col>
                        <Col sm="6">
                           <FormGroup>
                              <Label>{"Price"}</Label>
                              <input
                                 className="form-control"
                                 type="number"
                                 name="price"
                                 {...register("price")}
                              />
                           </FormGroup>
                        </Col>
                     </Row> : null
                  }

                  {
                     increments.includes(componentName) ?
                     <Row>
                        <Col sm="12">
                           <FormGroup>
                              <Label>{"Description"}</Label>
                              <input
                                 className="form-control"
                                 type="text"
                                 name="description"
                                 {...register("description")}
                              />
                           </FormGroup>
                        </Col>
                        <Col sm="6">
                           <FormGroup>
                              <Label>{"Type"}</Label>
                              <input
                                 className="form-control"
                                 type="text"
                                 name="type"
                                 {...register("type")}
                              />
                           </FormGroup>
                        </Col>
                        <Col sm="6">
                           <FormGroup>
                              <Label>{"Quantity"}</Label>
                              <input
                                 className="form-control"
                                 type="number"
                                 name="quantity"
                                 {...register("quantity")}
                              />
                           </FormGroup>
                        </Col>
                        <Col sm="6">
                           <FormGroup>
                              <Label>{"Max Quantity Per Room"}</Label>
                              <input
                                 className="form-control"
                                 type="number"
                                 name="maxQuantityPerRoom"
                                 {...register("maxQuantityPerRoom")}
                              />
                              <span style={{ color: "#ff5370" }}>
                                 {errors.maxQuantityPerRoom && "Max quantity is required"}
                              </span>
                           </FormGroup>
                        </Col>
                     </Row> : null
                  }

                  <Row className="mb-3">
                     <Col sm="6" className="btn-showcase">
                        <FormGroup>
                           <Label>{"Department"}</Label>
                           <MyDropdown
                              title={department}
                              setTitle={setDepartment}
                              data={departments}
                              registerProps={register("department")}
                           />
                        </FormGroup>
                     </Col>
                     {
                        increments.includes(componentName) ?
                        <Col>
                           <FormGroup>
                              <Label>{"View Rank"}</Label>
                              <input
                                 className="form-control"
                                 type="number"
                                 name="viewRank"
                                 {...register("viewRank", {
                                    required: true,
                                    min: { value: 1 },
                                 })}
                              />
                              <span style={{ color: "#ff5370" }}>
                                 {errors.viewRank?.type == "min" && "View rank should be at least 1"}
                                 {errors.viewRank?.type == "required" && "View rank is required"}
                              </span>
                           </FormGroup>
                        </Col> : null
                     }
                  </Row>

                  <Row className="mb-2">
                     <Col>
                        <div className="d-flex">
                           <Input
                              className="checkbox_animated"
                              id="inactive"
                              name="inactive"
                              type="checkbox"
                              checked={inactive}
                              onChange={(e) => setInactive(e.target.checked)}
                           />
                           <Label className="d-block" htmlFor="inactive">
                              Inactive ?
                           </Label>
                        </div>
                     </Col>
                     <Col>
                        <div className="d-flex">
                           <Input
                              className="checkbox_animated"
                              id="isVisible"
                              name="isVisible"
                              type="checkbox"
                              checked={isVisible}
                              onChange={(e) => setIsVisible(e.target.checked)}
                           />
                           <Label className="d-block" htmlFor="isVisible">
                              Is Visible ?
                           </Label>
                        </div>
                     </Col>
                  </Row>
               </form>
            </Container>
         </CommonModal>
      </>
   );
};
