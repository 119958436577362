import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_RESERVATION } from "../../../../utils/axiosInterceptors";
import { getAuthToken } from "../../../../utils/cookieReducer";

export const fetchLoyaltyUnsettled = createAsyncThunk(
  "loyaltyRewards/getLoyaltyUnsetteled",
  async (params, { getState, rejectWithValue, dispatch }) => {
    const { setTotalRows, CB, ...others } = params;

    var headers = {
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.get(`getLoyaltyUnsetteled`, {
        params: others,
        headers,
      });

      setTotalRows(data?.totalRows);
      return data;
    } catch (err) {
      CB && CB(err);
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong Please try again later",
        },
      });
    }
  }
);

export const settleLoyalty = createAsyncThunk(
  "loyaltyUnsetteled/settleLoyalty",
  async (state, { getState, rejectWithValue, dispatch }) => {

    let { CB, reservationId } = state;
    const authToken = getAuthToken();
    console.log(`settleLoyalty/api authToken`, authToken);

    try {
      let { data } = await AXIOS_RESERVATION.post(`settleLoyalty`, {
        reservationId: reservationId,
        token: authToken,
      });

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchLoyaltyUnsettled());

        return data;
      }
    } catch (err) {
      alert(err.response.data.message);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);
