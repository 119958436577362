import React, { Fragment, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn, H6, H4, H3 } from "../../../../AbstractElements";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import CategoryModal from "./CategoryModal";
import TableHeaderComp from "../../../../mycomponents/TableHeaderComp";

const Category = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();

    console.log("searchText:", searchText);
  };

  const headingData = [
    { title: "DINING" },
    { title: "ESSENTIALS" },
    { title: "SERVICES" },
  ];

  const [isAdd, setIsAdd] = useState(false);
  const handleAdd = () => setIsAdd(!isAdd);

  const [isEdit, setIsEdit] = useState(false);
  const handleEdit = () => setIsEdit(!isEdit);

  const handleDelete = () => {
    console.log("click");
    let conf = window.confirm("Are you sure you want to delete this amenity?");

    console.log(conf);
  };

  const CategoryRow = [
    {
      index: "1",
      name: "Breakfast for One",
      parent_key: "Local",
      rank: "2",
      action: (
        <Container className="d-flex btn-showcase">
          <Btn
            attrBtn={{
              color: "primary",
              outline: true,
              onClick: handleEdit,
            }}
          >
            {"Edit"}
          </Btn>
          <Btn
            attrBtn={{
              color: "danger",
              outline: true,
              onClick: handleDelete,
            }}
          >
            {"Delete"}
          </Btn>
        </Container>
      ),
    },
    {
      index: "2",
      name: "Breakfast for Two",
      parent_key: "Dining",
      rank: "3",
      action: (
        <Container className="d-flex btn-showcase">
          <Btn
            attrBtn={{
              color: "primary",
              outline: true,
              onClick: handleEdit,
            }}
          >
            {"Edit"}
          </Btn>
          <Btn
            attrBtn={{
              color: "danger",
              outline: true,
              onClick: handleDelete,
            }}
          >
            {"Delete"}
          </Btn>
        </Container>
      ),
    },
  ];

  const CategoryColumns = [
    {
      name: <H6>#</H6>,
      selector: (row) => row.index,
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Name</H6>,
      selector: (row) => row.name,
      sortable: true,
      center: true,
      wrap: true,
    },
    // {
    //   name: <H6>Essentials</H6>,
    //   selector: (row) => row.name,
    //   sortable: true,
    //   center: true,
    //   wrap: true,
    // },
    // {
    //   name: <H6>Services</H6>,
    //   selector: (row) => row.name,
    //   sortable: true,
    //   center: true,
    //   wrap: true,
    // },
    // {
    //   name: <H6>Dining</H6>,
    //   selector: (row) => row.name,
    //   sortable: true,
    //   center: true,
    //   wrap: true,
    // },
    {
      name: <H6>Parent Key</H6>,
      selector: (row) => row.parent_key,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Rank</H6>,
      selector: (row) => row.rank,
      sortable: true,
      center: true,
      wrap: true,
    },

    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
      width: "333px",
    },
  ];

  return (
    <Fragment>
      <CategoryModal
        isOpen={isAdd}
        ModalTitle={"Add Category"}
        toggler={handleAdd}
      />
      <CategoryModal
        isOpen={isEdit}
        ModalTitle={"Category | diningoptions"}
        toggler={handleEdit}
      />

      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs
            parent="Category Configuration"
            title="Category Configuration"
          />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />

            <Btn
              attrBtn={{
                className: "m-r-15 m-l-15 d-block",
                color: "success",
                // outline: true,
                onClick: handleAdd,
              }}
            >
              Add
            </Btn>
          </div>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab align-items-baseline">
                  <TableHeaderComp
                    headingData={headingData}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </div>
              </Col>

              <div className="social-tab p-t-0">
                <H3>{headingData[activeTab].title}</H3>
              </div>

              <CardBody className="p-t-15">
                <div className="order-history table-responsive">
                  <DataTable
                    pagination
                    paginationServer
                    data={CategoryRow}
                    columns={CategoryColumns}
                    responsive={true}

                    // progressPending={loading}
                    // progressComponent={<Loader />}
                    // paginationTotalRows={totalRows}
                    // paginationDefaultPage={currentPage}
                    // onChangeRowsPerPage={handlePerRowsChange}
                    // onChangePage={handlePageChange}
                    // searching={true}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Category;
