import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn, H6, H4 } from "../../../../AbstractElements";
import StaffConfigModal from "./StaffConfigModal";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import { useDispatch, useSelector } from "react-redux";
import { fetchDepartments, fetchStaffUser, removeStaff } from "../Api/api";
import { hotelStaffSelector } from "../Slice/hotelStaffSlice";
import BarLoader from "../../../../Layout/Loader/BarLoader";
import { toast } from "react-toastify";

const StaffConfig = () => {
  const dispatch = useDispatch();
  const { hotelStaffsData, departmentsData, isLoading, status } =
    useSelector(hotelStaffSelector);

  const [searchText, setSearchText] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [editData, setEditData] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    dispatch(fetchStaffUser());
    dispatch(fetchDepartments());
  }, []);

  console.log("hotelStaffsData", hotelStaffsData);

  const handleSearch = (e) => {
    e.preventDefault();

    console.log("searchText:", searchText);
  };

  const handleAdd = () => setIsAdd(!isAdd);

  const handleEditToggler = () => setIsEdit(!isEdit);

  const handleEdit = (data) => {
    setIsEdit(!isEdit);
    const departments = data?.department?.map((a) => a?.departmentName);
    setEditData({ ...data, password: "", selectedDepartment: departments });
  };

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message || "Something went wrong. Please try again laetr."
      );
    }
  };

  const handleDelete = (id) => {
    console.log("handle delete id", id);
    let conf = window.confirm(
      "Are you sure you want to delete this Staff details?"
    );

    if (conf) {
      dispatch(removeStaff({ id, CB: functionCB }));
    }
  };

  const StaffConfigRow = hotelStaffsData?.result?.data
    ?.filter((val, i) => {
      return (
        val &&
        Object.values(val).some((field) => {
          if (typeof field === "string") {
            return field.toLowerCase().includes(searchText.toLowerCase());
          }
          return false;
        })
      );
    })
    .map((val, i) => {
      if (val) {
        return {
          email: val.email,
          name: val.name,
          department:
            val.department && val.department.length
              ? val.department.map((a) => a && a.departmentName).join(", ")
              : "",
          action: (
            <Container className="d-flex btn-showcase">
              <Btn
                attrBtn={{
                  color: "primary",
                  outline: true,
                  onClick: () => handleEdit(val),
                }}
              >
                {"Edit"}
              </Btn>
              <Btn
                attrBtn={{
                  color: "danger",
                  outline: true,
                  onClick: () => handleDelete(val._id),
                }}
              >
                {"Delete"}
              </Btn>
            </Container>
          ),
        };
      } else {
        return null;
      }
    });

  const StaffConfigColumns = [
    {
      name: <H6>#</H6>,
      selector: (row, index) => (currentPage - 1) * perPage + (index + 1),
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Email</H6>,
      selector: (row) => row.email,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Name</H6>,
      selector: (row) => row.name,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Department</H6>,
      selector: (row) => row.department,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
    },
  ];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  return (
    <Fragment>
      {(isAdd || isEdit) && (
        <StaffConfigModal
          isOpen={true}
          ModalTitle={isAdd ? "Add Staff" : "Edit Staff"}
          toggler={isAdd ? handleAdd : handleEditToggler}
          editData={isEdit && editData}
          departmentsData={departmentsData?.result?.data}
        />
      )}

      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs
            parent="Staff Configuration"
            title="Staff Configuration"
          />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />

            <Btn
              attrBtn={{
                className: "m-r-15 m-l-15 d-block",
                color: "success",
                // outline: true,
                onClick: handleAdd,
              }}
            >
              Add New Staff
            </Btn>
          </div>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab">
                  <H4>STAFF DATA</H4>
                </div>
              </Col>

              <CardBody className="p-t-0">
                <div className="order-history table-responsive">
                  <DataTable
                    data={StaffConfigRow}
                    columns={StaffConfigColumns}
                    responsive={true}
                    pagination
                    progressPending={isLoading}
                    progressComponent={<BarLoader />}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}

                    // progressComponent={<Loader />}
                    // paginationTotalRows={totalRows}
                    // paginationDefaultPage={currentPage}
                    // onChangeRowsPerPage={handlePerRowsChange}
                    // onChangePage={handlePageChange}
                    // searching={true}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default StaffConfig;
