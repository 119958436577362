import React, { Fragment, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn, H6, H4 } from "../../../../AbstractElements";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import MeetingTableLayoutModal from "./MeetingTableLayoutModal";

const MeetingTableLayout = () => {
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();

    console.log("searchText:", searchText);
  };

  const [isAdd, setIsAdd] = useState(false);
  const handleAdd = () => setIsAdd(!isAdd);

  const [isEdit, setIsEdit] = useState(false);
  const handleEdit = () => setIsEdit(!isEdit);

  const handleDelete = () => {
    console.log("click");
    let conf = window.confirm("Are you sure you want to delete this amenity?");

    console.log(conf);
  };

  const MeetingTLRData = [
    {
      index: "1",
      name: "cocktail",
      meeting_room_capacity: "77",
      large_meeting_room_capacity: "777",
      is_enabled: "No",
      action: (
        <Container className="d-flex btn-showcase">
          <Btn
            attrBtn={{
              color: "primary",
              outline: true,
              onClick: handleEdit,
            }}
          >
            {"Edit"}
          </Btn>
          <Btn
            attrBtn={{
              color: "danger",
              outline: true,
              onClick: handleDelete,
            }}
          >
            {"Delete"}
          </Btn>
        </Container>
      ),
    },
    {
      index: "2",
      name: "Theater",
      meeting_room_capacity: "50",
      large_meeting_room_capacity: "555",
      is_enabled: "Yes",
      action: (
        <Container className="d-flex btn-showcase">
          <Btn
            attrBtn={{
              color: "primary",
              outline: true,
              onClick: handleEdit,
            }}
          >
            {"Edit"}
          </Btn>
          <Btn
            attrBtn={{
              color: "danger",
              outline: true,
              onClick: handleDelete,
            }}
          >
            {"Delete"}
          </Btn>
        </Container>
      ),
    },
  ];

  const MeetingTLColumns = [
    {
      name: <H6>#</H6>,
      selector: (row) => row.index,
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Name</H6>,
      selector: (row) => row.name,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6> Meeting Room Capacity</H6>,
      selector: (row) => row.meeting_room_capacity,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6> Large Meeting Room Capacity</H6>,
      selector: (row) => row.large_meeting_room_capacity,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6> Enabled </H6>,
      selector: (row) => row.is_enabled,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
      width: "555px",
    },
  ];

  return (
    <Fragment>
      <MeetingTableLayoutModal
        isOpen={isAdd}
        ModalTitle={"Add Meeting Table"}
        toggler={handleAdd}
      />
      <MeetingTableLayoutModal
        isOpen={isEdit}
        ModalTitle={"Edit Meeting Table"}
        toggler={handleEdit}
      />

      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs
            parent="Meeting Table Configuration"
            title="Meeting Table Configuration"
          />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />

            <Btn
              attrBtn={{
                className: "m-r-15 m-l-15 d-block",
                color: "success",
                // outline: true,
                onClick: handleAdd,
              }}
            >
              Add Meeting Table
            </Btn>
          </div>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab">
                  <H4>MEETING TABLE LAYOUT DATA</H4>
                </div>
              </Col>

              <CardBody className="p-t-0">
                <div className="order-history table-responsive">
                  <DataTable
                    pagination
                    paginationServer
                    data={MeetingTLRData}
                    columns={MeetingTLColumns}
                    responsive={true}

                    // progressPending={loading}
                    // progressComponent={<Loader />}
                    // paginationTotalRows={totalRows}
                    // paginationDefaultPage={currentPage}
                    // onChangeRowsPerPage={handlePerRowsChange}
                    // onChangePage={handlePageChange}
                    // searching={true}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MeetingTableLayout;
