import React, { useEffect, useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import MyDropdown from "../../../../mycomponents/MyDropdown";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { categoriesSelector } from "./categoriesSlice";
import { config } from "../../../../config";
import { addNewCategoryComponent, editCategoryComponent, getAllCategories } from "./categoriesApi";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

export const DiningModal = ({
   isOpen,
   ModalTitle,
   editData,
   setEditData,
   toggler
}) => {
   const dispatch = useDispatch();
   const { categoryComponentsData, departmentsData, allCategories, error } = useSelector(categoriesSelector);
   const [componentName, setComponentName] = useState("Select Component");
   const [department, setDepartment] = useState("Select Department");
   const [isVisible, setIsVisible] = useState(false);
   const [inactive, setInactive] = useState(false);

   useEffect(() => {
      if (editData) {
         setComponentName(editData.viewType);
         if (editData.department) {
            setDepartment(departmentsData?.find(obj => obj._id == editData.department)?.departmentName ?? "Select Department");
         }
         setIsVisible(editData.isVisible);
         setInactive(editData.inactive ?? false);

         setValue("categoryName", editData.categoryName);
         setValue("componentName", editData.viewType);
         setValue("name", editData.name);
         if(editData.hasOwnProperty("goTo")) setValue("goTo", editData.goTo);
         if(editData.hasOwnProperty("amount")) setValue("amount", editData.amount);
         if(editData.hasOwnProperty("description")) setValue("description", editData.description);
         if(editData.hasOwnProperty("type")) setValue("type", editData.type);
         if(editData.hasOwnProperty("maxQuantityPerRoom")) setValue("maxQuantityPerRoom", editData.maxQuantityPerRoom);
         if(editData.hasOwnProperty("viewRank")) setValue("viewRank", editData.viewRank);
         if(editData.hasOwnProperty("department")) setValue("department", editData.department);
         if(editData.hasOwnProperty("gatewayCart")) setValue("gatewayCart", editData.gatewayCart);
         else setValue("gatewayCart", "");
      }
   }, [editData]);

   const {
      register,
      handleSubmit,
      setValue,
      reset,
      formState: { errors },
   } = useForm({
      defaultValues: editData
         ? { ...editData }
         : { categoryName: "Dining" },
   });

   useEffect(() => {
      dispatch(getAllCategories());
   }, []);

   useEffect(() => {
      if(componentName !== "Select Component") setValue("componentName", componentName);
   }, [componentName]);

   useEffect(() => {
      if (department !== "Select Department") setValue("department", department);
   }, [department]);

   useEffect(() => {
      setValue("isVisible", isVisible);
   }, [isVisible]);

   useEffect(() => {
      setValue("inactive", inactive);
   }, [inactive]);

   let departments = JSON.parse(JSON.stringify(departmentsData ?? []));
   departments = departments?.map((obj) => {
      obj.opt = obj.departmentName;
      return obj;
   });

   const addCB = (data) => {
      if(data.success) {
         return toast.success("Dining Added Successfully");
      } else {
         return toast.error(error ?? "Something went wrong. Please try again later.");
      }
   }

   const editCB = (data) => {
      if(data?.success) {
         toast.success("Edited Successfully");
      } else {
         toast.error(data?.message || data?.response?.data?.message || "Something went wrong. Please try again later.");
      }
   };

   const handleClose = () => {
      toggler();
      reset();
      setComponentName("Select Component");
      setDepartment("Select Department");
      setIsVisible(false);
      setValue("isVisible", false);
      setInactive(false);
      setValue("inactive", false);
      if(editData) setEditData(null);
   };

   const invalidValues = [undefined, null, ""];
   const onSubmit = (formValues) => {
      handleClose();
      if(formValues.componentName !== "text") {
         delete formValues.amount
         delete formValues.description;
         delete formValues.maxQuantityPerRoom;
         delete formValues.type;
      } else {
         if(invalidValues.includes(formValues.amount)) delete formValues.amount;
         else formValues.amount = parseInt(formValues.amount);

         if(invalidValues.includes(formValues.maxQuantityPerRoom)) delete formValues.maxQuantityPerRoom;
         else formValues.maxQuantityPerRoom = parseInt(formValues.maxQuantityPerRoom);
      }

      if(!["submenu", "submenuwithcart"].includes(formValues.componentName)) delete formValues.goTo;
      else formValues.goTo = formValues.goTo.split(" ").join("");

      if(invalidValues.includes(formValues.gatewayCart)) delete formValues.gatewayCart;
      if(invalidValues.includes(formValues.department)) delete formValues.department;

      formValues.viewRank = parseInt(formValues.viewRank);
      delete formValues.departmentName;
      delete formValues.viewType;
  
      let { categoryName, componentName, isVisible, ...data } = formValues;
      data.hotelId = config.HOTELID;
  
      for(let key in data) {
        delete formValues[`${key}`];
      }
  
      if(data.department) data.department = departmentsData?.find((obj) => obj.departmentName == data.department)?._id;
  
      delete data._id;
      formValues.data = data;

      if(editData) {
         dispatch(editCategoryComponent({ ...formValues, id: editData._id, CB: editCB }));
      } else {
         if(componentName == "submenu" || componentName == "submenuwithcart") {
            formValues.isSubmenu = true;
            formValues.isDining = true;
         }
         dispatch(addNewCategoryComponent({ ...formValues, CB: addCB }));
      }
   };

   let existingViewRanks =
     categoryComponentsData?.component?.Dining?.map(obj => obj.viewRank) ??
     categoryComponentsData?.component?.dining?.map(obj => obj.viewRank) ??
     [];
      
   if(editData) existingViewRanks = existingViewRanks.filter(val => val != editData.viewRank);

   let existingCategories = allCategories?.map(obj => obj.categoryName) ?? [];
   if(editData) existingCategories = existingCategories.filter(val => val != editData.goTo);

   return (
      <>
         <CommonModal
            isOpen={isOpen}
            title={ModalTitle}
            toggler={handleClose}
            togglerSave={handleSubmit(onSubmit)}
         >
            <Container>
               <form className="needs-validation" noValidate="">
                  <Row className="d-flex">
                     <Col>
                        <FormGroup>
                           <Label>{"Category Name"}</Label>
                           <input
                              className="form-control"
                              type="text"
                              name="categoryName"
                              {...register("categoryName", { required: true })}
                              disabled={true}
                           />
                           <span style={{ color: "#ff5370" }}>
                              {errors.categoryName && "Category Name is required"}
                           </span>
                        </FormGroup>
                     </Col>
                  </Row>

                  <Row className="mb-3">
                     <Col sm="6" col="9" className="btn-showcase">
                        <FormGroup>
                           <Label>{"Component Name"}</Label>
                           <MyDropdown
                              title={componentName}
                              setTitle={setComponentName}
                              data={[
                                 { opt: "submenu" },
                                 { opt: "text" },
                                 { opt: "submenuwithcart" }
                              ]}
                              registerProps={register("componentName", {
                                 required: true,
                              })}
                              disable={editData ? true : false}
                           />
                           <span style={{ color: "#ff5370" }}>
                              {errors.componentName && "Component Name is required"}
                           </span>
                        </FormGroup>
                     </Col>
                     {
                        componentName == "submenu" || componentName == "submenuwithcart" ?
                        <Col>
                           <FormGroup>
                              <Label>{"goTo"}</Label>
                              <input
                                 className="form-control"
                                 type="text"
                                 name="goTo"
                                 {...register("goTo", {
                                    required: true,
                                    validate: {
                                       existing: (value) => !existingCategories.includes(value),
                                    }
                                 })}
                                 disabled={editData ? true : false}
                              />
                              <span style={{ color: "#ff5370" }}>
                                 {errors.goTo?.type == "existing" && "Already exists"}
                                 {errors.goTo?.type == "required" && "goTo is required for submenu"}
                              </span>
                           </FormGroup>
                        </Col> : null
                     }
                  </Row>

                  <Row className="d-flex">
                     <Col>
                        <FormGroup>
                           <Label>{"Item Name"}</Label>
                           <input
                              className="form-control"
                              type="text"
                              name="name"
                              {...register("name", { required: true })}
                           />
                           <span style={{ color: "#ff5370" }}>
                              {errors.name && "Item Name is required"}
                           </span>
                        </FormGroup>
                     </Col>
                     {
                        componentName == "text" ?
                        <Col>
                           <FormGroup>
                              <Label>{"Amount"}</Label>
                              <input
                                 className="form-control"
                                 type="number"
                                 name="amount"
                                 {...register("amount")}
                              />
                           </FormGroup>
                        </Col> : null
                     }
                  </Row>

                  {
                     componentName == "text" ?
                     <Row>
                        <Col>
                           <FormGroup>
                              <Label>{"Description"}</Label>
                              <input
                                 className="form-control"
                                 type="text"
                                 name="description"
                                 {...register("description")}
                              />
                           </FormGroup>
                        </Col>
                     </Row> : null
                  }

                  {
                     componentName == "text" ?
                     <Row>
                        <Col sm="6">
                           <FormGroup>
                              <Label>{"Type"}</Label>
                              <input
                                 className="form-control"
                                 type="text"
                                 name="type"
                                 {...register("type")}
                              />
                           </FormGroup>
                        </Col>
                     </Row> : null
                  }

                  <Row>
                     {
                        componentName == "text" ?
                        <Col>
                           <FormGroup>
                              <Label>{"Max Quantity Per Room"}</Label>
                              <input
                                 className="form-control"
                                 type="number"
                                 name="maxQuantityPerRoom"
                                 {...register("maxQuantityPerRoom")}
                              />
                           </FormGroup>
                        </Col> : null
                     }
                     <Col>
                        <FormGroup>
                           <Label>{"View Rank"}</Label>
                           <input
                              className="form-control"
                              type="number"
                              name="viewRank"
                              {...register("viewRank", {
                                 required: true,
                                 min: { value: 1 },
                                 validate: {
                                    existing: (value) => !existingViewRanks.includes(parseInt(value)),
                                 }
                              })}
                           />
                           <span style={{ color: "#ff5370" }}>
                              {errors.viewRank?.type == "min" && "View rank should be at least 1"}
                              {errors.viewRank?.type == "existing" && "This view rank already exists"}
                              {errors.viewRank?.type == "required" && "View rank is required"}
                           </span>
                        </FormGroup>
                     </Col>
                  </Row>

                  <Row className="mb-3">
                     <Col sm="6" col="9" className="btn-showcase">
                        <FormGroup>
                           <Label>{"Department"}</Label>
                           <MyDropdown
                              title={department}
                              setTitle={setDepartment}
                              data={departments}
                              registerProps={register("department")}
                           />
                        </FormGroup>
                     </Col>
                     <Col>
                        <FormGroup>
                           <Label>{"Gateway Cart"}</Label>
                           <input
                              className="form-control"
                              type="text"
                              name="gatewayCart"
                              {...register("gatewayCart")}
                           />
                        </FormGroup>
                     </Col>
                  </Row>

                  <Row className="mb-2">
                     <Col>
                        <div className="d-flex">
                           <Input
                              className="checkbox_animated"
                              id="inactive"
                              name="inactive"
                              type="checkbox"
                              checked={inactive}
                              onChange={(e) => setInactive(e.target.checked)}
                           />
                           <Label className="d-block" htmlFor="inactive">
                              Inactive ?
                           </Label>
                        </div>
                     </Col>
                     <Col>
                        <div className="d-flex">
                           <Input
                              className="checkbox_animated"
                              id="isVisible"
                              name="isVisible"
                              type="checkbox"
                              checked={isVisible}
                              onChange={(e) => setIsVisible(e.target.checked)}
                           />
                           <Label className="d-block" htmlFor="isVisible">
                              Is Visible ?
                           </Label>
                        </div>
                     </Col>
                  </Row>
               </form>
            </Container>
         </CommonModal>
      </>
   );
};
