import React from "react";
import { Btn, H6, Image } from "../../../AbstractElements";
import { Button, Form } from "react-bootstrap";
import { Input } from "reactstrap";

export const RoomServiceRData = [
  {
    index: "1",
    room_no: "202",
    items: "Microwave",
    creation_time: "Thu May 17 2021 17:49",
    time_spent: "2 years",
    status: "pending",
    department: "Housekeeping",
  },

  {
    index: "2",
    room_no: "201",
    items: "Bath Towel",
    creation_time: "Thu Jun 17 2021 17:49",
    time_spent: "2 years",
    status: "pending",
    department: "Housekeeping",
  },
];

export const RoomServiceRColumns = [
  {
    name: <H6>#</H6>,
    selector: (row) => row.index,
    sortable: true,
    center: true,
    wrap: true,
    width: "77px",
  },
  {
    name: <H6>RoomNo</H6>,
    selector: (row) => row.room_no,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Items</H6>,
    selector: (row) => row.items,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Creation Time</H6>,
    selector: (row) => row.creation_time,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Time Spent</H6>,
    selector: (row) => row.time_spent,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Status</H6>,
    selector: (row) => row.status,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Department</H6>,
    selector: (row) => row.department,
    sortable: true,
    center: true,
    wrap: true,
  },
];

// Completed

export const RoomServiceRDataComp = [
  {
    index: "1",
    room_no: "202",
    title: "Microwave",
    creation_time: "Thu May 17 2021 17:49",
    completed_time: "Thu May 15 2022 17:49",
    department: "Housekeeping",
    assign_to: "staff@gmail.com",
  },

  {
    index: "2",
    room_no: "203",
    title: "Hand Towel",
    creation_time: "Thu June 16 2022 17:49",
    completed_time: "Thu June 15 2023 17:49",
    department: "Housekeeping",
    assign_to: "staff3@gmail.com",
  },
];

export const RoomServiceRColumnsComp = [
  {
    name: <H6>#</H6>,
    selector: (row) => row.index,
    sortable: true,
    center: true,
    wrap: true,
    width: "77px",
  },
  {
    name: <H6>RoomNo</H6>,
    selector: (row) => row.room_no,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Title</H6>,
    selector: (row) => row.title,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Creation Time</H6>,
    selector: (row) => row.creation_time,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Completed Time</H6>,
    selector: (row) => row.completed_time,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Department</H6>,
    selector: (row) => row.department,
    sortable: true,
    center: true,
    wrap: true,
  },
  {
    name: <H6>Assign To</H6>,
    selector: (row) => row.assign_to,
    center: true,
    wrap: true,
  },
];
