import { createAsyncThunk } from '@reduxjs/toolkit';
import { AXIOS_RESERVATION } from '../../../../utils/axiosInterceptors';
import { config } from '../../../../config';

export const fetchLoyaltyConfig = createAsyncThunk(
  'hotel/gethotel',
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("hotel payload:", state);

    var headers = {
      'Access-Control-Allow-Origin': '*',
    };

    try {
      let { data } = await AXIOS_RESERVATION.get(`gethotel`, { headers });
      //   console.log("data:", data);

      return data;
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            'Something went wrong Please try again later',
        },
      });
    }
  }
);

export const edithotel = createAsyncThunk(
  'hotel/edithotel',
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.("hotel payload:", state)

    let { CB, loyConfigData } = state;
    // console.log("CB:", CB);

    var headers = {
      'Access-Control-Allow-Origin': '*',
    };

    try {
      let { data } = await AXIOS_RESERVATION.post(`edithotel`, loyConfigData, {
        headers,
      });

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchLoyaltyConfig());

        return data;
      }
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            'Something went wrong please try again later',
        },
      });
    }
  }
);

export const fetchRatePlansSourceCodesAndFloors = createAsyncThunk(
  'RatePlansSouceCodesAndFloors/getRatePlansSouceCodesAndFloors',
  async (state, { getState, rejectWithValue, dispatch }) => {
    var headers = {
      'Access-Control-Allow-Origin': '*',
    };
    try {
      let { data } = await AXIOS_RESERVATION.get(
        `getRatePlansSouceCodesAndFloors`,
        { headers }
      );

      return data;
    } catch (err) {


      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            'Something went worng Please try again later',
        },
      });
    }
  }
);
