import React from 'react';
import { Btn, H4 } from '../../../../AbstractElements';

export const WebViewComponent = ({ handleBackClick, webObj, isLoading, handleEdit }) => {
   return (
      <div>
         <H4 attrH4={{ className: "m-b-30" }}>
            <i onClick={handleBackClick} className="fa fa-arrow-left m-r-10 p-0 cursor-pointer"></i>
            {
               webObj?.title
            }
         </H4>
         <a
            href={webObj?.link}
            target='_blank'
            rel="noopener"
            className="m-r-15"
         >
            <span className="text-dark">Webview url: </span> {webObj?.link}
         </a>
         <Btn
            attrBtn={{
               color: "primary",
               className: "mt-2",
               outline: true,
               disabled: isLoading,
               onClick: () => handleEdit(webObj)
            }}
         >
            {"Edit Link"}
         </Btn>
      </div>
   );
}