import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Col, Form, FormGroup, Label, ModalFooter, Row } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { hotelConfigSelector } from "./hotelConfigSlice";
import { editHotel, fetchHotelConfig } from "./api";
import { toast } from "react-toastify";

const PolicyTC = () => {
  const dispatch = useDispatch();

  const { hotelConfigData, isLoading, status } =
    useSelector(hotelConfigSelector);

  console.log("isLoading:", isLoading);
  console.log("status:", status);
  console.log("hotelConfigData:", hotelConfigData);

  useEffect(() => {
    dispatch(fetchHotelConfig());
  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return hotelConfigData;
    }, [hotelConfigData]),
  });

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message ||
          "Oops.. Something went wrong Please try again later"
      );
    }
  };

  const onSubmit = (data) => {
    console.log(data);
    if (hotelConfigData) {
      dispatch(editHotel({ ...data, CB: functionCB }));
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
        <Row>
          <Col>
            <FormGroup>
              <Label>{"Cancellation Policy:"}</Label>
              <textarea
                className="form-control"
                name="cancellationPolicy"
                {...register("cancellationPolicy", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.cancellation_policy &&
                  "Cancellation policy is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Guarantee Policy:"}</Label>
              <textarea
                className="form-control"
                name="guaranteePolicy"
                {...register("guaranteePolicy", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.guarantee_policy && "Guarantee policy is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Pets Policy:"}</Label>
              <textarea
                className="form-control"
                name="petsNotice"
                {...register("petsNotice", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.pets_policy && "Pets policy is required"}
              </span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>{"Loyalty Text:"}</Label>
              <textarea
                className="form-control"
                name="loyaltyText"
                {...register("loyaltyText", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.loyalty_text && "About hotel URL is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Personalisation:"}</Label>
              <textarea
                className="form-control"
                name="personalization"
                {...register("personalization", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.personalisation && "Personalisation is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Profile Setup:"}</Label>
              <textarea
                className="form-control"
                name="profileSetup"
                {...register("profileSetup", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.profile_setup && "Profile setup is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Express CheckIn:"}</Label>
              <textarea
                className="form-control"
                name="expressCheckInText:"
                {...register("expressCheckInText", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.express_checkIn && "Express checkIn is required"}
              </span>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <ModalFooter>
              <Btn
                attrBtn={{
                  color: "primary",
                  // onClick: props.togglerSave,
                }}
              >
                SaveChanges
              </Btn>
            </ModalFooter>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default PolicyTC;
