import React, { useMemo, useState } from "react";
import { useForm } from "react-hook-form";

import { Col, Container, FormGroup, Label, Row } from "reactstrap";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";

import MySelectDropd from "../../../../mycomponents/MySelectDropd";
import { useDispatch } from "react-redux";
import { addNewStaffUser, editStaffUser } from "../Api/api";
import { toast } from "react-toastify";

const StaffConfigModal = ({
  isOpen,
  ModalTitle,
  toggler,
  editData,
  departmentsData,
}) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,

    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return editData;
    }, [editData]),
  });

  const [selectDepart, setSelectDepart] = useState(null);

  const functionCB = (data) => {
    if (data.success == true) {
      toggler();
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message || "Something went wrong. Please try again laetr."
      );
    }
  };

  const onSubmit = (data) => {
    if (editData) {
      const selectedDepartment = new Set(selectDepart);
      const department = departmentsData.filter((item) =>
        selectedDepartment.has(item.departmentName)
      );

      const Data = {
        _id: data._id,
        name: data.name,
        password: data.password,
        departments: selectDepart === null ? data.department : department,
      };
      if (Data.departments.length == 0) {
        return toast.warning(
          "Please select the departments fields before submitting."
        );
      }
      dispatch(editStaffUser({ ...Data, CB: functionCB }));
    } else {
      if (selectDepart?.length === 0 || !selectDepart) {
        return toast.warning(
          "Please select the departments fields before submitting."
        );
      }

      const selectedDepartment = new Set(selectDepart);
      const department = departmentsData.filter((item) =>
        selectedDepartment.has(item.departmentName)
      );
      const Data = { department, ...data };
      dispatch(addNewStaffUser({ ...Data, CB: functionCB }));
    }
  };

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={toggler}
        togglerSave={handleSubmit(onSubmit)}
      >
        <Container fluid={true}>
          <form onSubmit={(e) => e.preventDefault()}>
            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Email ID:"}</Label>
                  <input
                    className="form-control"
                    type="email"
                    placeholder="xyz@gmail.com"
                    {...register(`email`, {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: "Provide a valid Email ID",
                      },
                    })}
                    disabled={editData ? true : false}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.email && errors.email.message}
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Name:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="John Doe"
                    {...register(`name`, {
                      required: true,
                    })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.name && "Name is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>{editData ? "Set new password:" : "Password:"}</Label>

                  <input
                    className="form-control"
                    type="password"
                    placeholder="*******"
                    {...register(`password`, {
                      required: editData ? false : "Password is required",
                      minLength: {
                        value: 6,
                        message: "Password must be at least 6 characters long",
                      },
                      maxLength: {
                        value: 12,
                        message: "Password must not exceed 12 characters",
                      },
                    })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.password && errors.password.message}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row className="m-b-10">
              <Col>
                <Label className="f-16">Departments:</Label>

                <MySelectDropd
                  options={departmentsData?.map((item) => item.departmentName)}
                  name={"departData"}
                  selectedOpt={setSelectDepart}
                  selectedValues={editData?.selectedDepartment}
                />
              </Col>
            </Row>
          </form>
        </Container>
      </CommonModal>
    </>
  );
};

export default StaffConfigModal;
