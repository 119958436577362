import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn, H6, H5, H4 } from "../../../../AbstractElements";
import RoomTypesModal from "./RoomTypesModal";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import { useDispatch, useSelector } from "react-redux";
import { fetchRoomTypes, removeRoomTypes } from "./api";
import { roomTypesSelector } from "./roomTypesSlice";
import BarLoader from "../../../../Layout/Loader/BarLoader";
import { toast } from "react-toastify";

const RoomTypes = () => {
  const dispatch = useDispatch();
  const { roomTypesData, isLoading, status } = useSelector(roomTypesSelector);
  console.log("status:", status);
  console.log("roomTypesData:", roomTypesData);

  const [searchText, setSearchText] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(false);

  useEffect(() => {
    dispatch(fetchRoomTypes());

    //
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();

    console.log("searchText:", searchText);
  };

  const handleAdd = () => setIsAdd(!isAdd);
  const handleEdit = (data) => {
    setIsEdit(!isEdit);
    setEditData(data);
  };

  const functionCB = (data) => {
    console.log("🚀 ~ file: RoomTypes.jsx:43 ~ functionCB ~ data:", data);
    if (data.success == true) {
      return toast.success(data.result?.message || "Data successfully deleted");
    } else {
      return toast.error(
        data.result?.message ||
          "Oops.. something went wrong Please try again lates"
      );
    }
  };

  const handleDelete = (id) => {
    console.log("delete id", id);
    let conf = window.confirm(
      "Are you sure you want to delete this room type?"
    );

    if (conf) {
      dispatch(removeRoomTypes({ id, CB: functionCB }));
    }

    console.log(conf);
  };

  const RoomTypesRow = roomTypesData?.result?.sendData?.data[0].roomTypes
    ?.filter((val, i) => {
      return (
        val.name.includes(searchText) ||
        val.code.includes(searchText) ||
        val.name.toLowerCase().includes(searchText.toLowerCase()) ||
        val.code.toLowerCase().includes(searchText.toLowerCase()) ||
        val.roomDescription.toLowerCase().includes(searchText.toLowerCase()) // ||
        // val.technology.toLowerCase().includes(searchText.toLowerCase()) ||
        // val.inRoom.toLowerCase().includes(searchText.toLowerCase())
      );
    })
    .map((val, i) => ({
      index: i + 1,
      name: (
        <Container fluid={true}>
          <Row>
            <Col>{val.name}</Col>
          </Row>
          <Row>
            <Col>{val.isPrimary ? <H5>( Default )</H5> : null}</Col>
          </Row>
        </Container>
      ),
      code: val.code,
      description: val.roomDescription,
      electronics: val.technology,
      furnitures: val.inRoom,
      action: (
        <Container className="d-flex btn-showcase">
          <Btn
            attrBtn={{
              color: "primary",
              outline: true,
              onClick: () => handleEdit(val),
            }}
          >
            {"Edit"}
          </Btn>
          <Btn
            attrBtn={{
              color: "danger",
              outline: true,
              onClick: () => handleDelete(val._id),
            }}
          >
            {"Delete"}
          </Btn>
        </Container>
      ),
    }));

  const RoomTypesColumns = [
    {
      name: <H6>#</H6>,
      selector: (row) => row.index,
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Name</H6>,
      selector: (row) => row.name,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Code</H6>,
      selector: (row) => row.code,
      sortable: true,
      // center: true,
      wrap: true,
      width: "111px",
    },
    {
      name: <H6>Description</H6>,
      selector: (row) => row.description,
      sortable: true,
      center: true,
      wrap: true,
    },

    {
      name: <H6>Electronics</H6>,
      selector: (row) => row.electronics,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Furnitures</H6>,
      selector: (row) => row.furnitures,
      center: true,
      wrap: true,
    },

    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
      width: "266px",
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <Fragment>
      <RoomTypesModal
        isOpen={isAdd}
        ModalTitle={"Add Room Types"}
        toggler={handleAdd}
      />

      {isEdit && editData ? (
        <RoomTypesModal
          isOpen={isEdit}
          ModalTitle={"Edit Room Types"}
          toggler={handleEdit}
          editData={editData}
        />
      ) : null}

      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs parent="Room Types" title="Room Types" />

          <div className="social-tab">
            <MySearchInput
              searchText={searchText}
              setSearchText={setSearchText}
              handleSearch={handleSearch}
            />

            <Btn
              attrBtn={{
                className: "m-r-15 m-l-15 d-block",
                color: "success",
                // outline: true,
                onClick: handleAdd,
              }}
            >
              Add Room Types
            </Btn>
          </div>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab">
                  <H4>ROOM TYPES DATA</H4>
                </div>
              </Col>

              <CardBody className="p-t-0">
                <div className="order-history table-responsive">
                  <DataTable
                    data={RoomTypesRow}
                    columns={RoomTypesColumns}
                    responsive={true}
                    pagination
                    paginationComponentOptions={paginationOptions}
                    subHeaderAlign="center"
                    progressPending={isLoading}
                    progressComponent={<BarLoader />}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default RoomTypes;
