console.log(process.env.REACT_APP_SERVER, "REACT_APP_SERVER");
const config = {
  // RESERVATION_API: 'http://localhost:8006/api',
  // HOSPITALITY_API: 'http://localhost:8001/api', 

  RESERVATION_API: process.env.REACT_APP_SERVER == 'production' ? 'https://api.reservationapp.andmv.com/api' : 'https://staging-api.reservationapp.andmv.com/api',
  HOSPITALITY_API: process.env.REACT_APP_SERVER == 'production' ? 'https://api.hospitality.andmv.com/api' : 'https://staging-api.hospitality.andmv.com/api',
  HOTELID: process.env.REACT_APP_HOTELID,
  reservationBackend: process.env.REACT_APP_SERVER == 'production' ? 'https://api.reservationapp.andmv.com/' : 'https://staging-api.reservationapp.andmv.com/',
}

module.exports = { config };