const { createSlice } = require("@reduxjs/toolkit");
const { getadminuser, addnewadminuser, editadminuser, removeadminuser } = require("./api");

const initialState = {
    adminUserData: {},
    isLoading: false,
    status: {
        apiMessage: "",
        apiSuccess: true,
    },
};

const masterUsersSlice = createSlice({
    initialState,
    name: "getadminuser",
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getadminuser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getadminuser.fulfilled, (state, action) => {
                state.isLoading = false;
                state.adminUserData = action.payload;
            })
            .addCase(getadminuser.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.status = payload && payload.status;
            })

            // CASE FOR ADD ADMINUSER
            .addCase(addnewadminuser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addnewadminuser.fulfilled, (state, { payload }) => {
                state.isLoading = false;
            })
            .addCase(addnewadminuser.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.status = payload && payload.status;
            })

            // CASE FOR EDIT ADMIN USER
            .addCase(editadminuser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editadminuser.fulfilled, (state, { payload }) => {
                state.isLoading = false;
            })
            .addCase(editadminuser.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.status = payload.status;
            })

            // CASE FOR REMOVE ADMIN USER
            .addCase(removeadminuser.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(removeadminuser.fulfilled, (state, { payload }) => {
                state.isLoading = false;
            })
            .addCase(removeadminuser.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.status = payload.status;
            });
    },
});

export default masterUsersSlice.reducer;
export const { setStatus } = masterUsersSlice.actions;
export const adminUserSelector = (state) => state.getadminuser;
