import { createSlice } from "@reduxjs/toolkit";
import {
  addAmenities,
  editAmenities,
  fetchAmenities,
  removeAmenities,
} from "./api";

const initialState = {
  amenitiesData: {},
  isLoading: false,
  status: {
    apiMessage: "",
    apiSuccess: true,
  },
};

const amenitiesSlice = createSlice({
  initialState,
  name: "fetchAmenities",
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchAmenities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAmenities.fulfilled, (state, action) => {
        // console.log("amenities fulfilled:", action.payload);

        state.isLoading = false;
        state.amenitiesData = action.payload;
      })
      .addCase(fetchAmenities.rejected, (state, { payload }) => {
        // console.log("amenities rejected:", payload);

        state.isLoading = false;
        state.status = payload.status;
      })

      //   CASE FOR ADD AMENITIES --->>>
      .addCase(addAmenities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addAmenities.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(addAmenities.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      //   CASE FOR EDIT AMENITIES --->>>
      .addCase(editAmenities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editAmenities.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(editAmenities.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      //   CASE FOR REMOVE AMENITIES --->>>
      .addCase(removeAmenities.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeAmenities.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(removeAmenities.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      });
  },
});

export default amenitiesSlice.reducer;
export const { setStatus } = amenitiesSlice.actions;
export const amenitiesSelector = (state) => state.fetchAmenities;
