import React, { useEffect } from "react";
import CommonModal from "../../../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, FormGroup, Label, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { editCategoryComponent } from "../../categoriesApi";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

export const LinkEditModal = ({ isOpen, ModalTitle, toggler, editData }) => {
  const dispatch = useDispatch();

  const {
   register,
   handleSubmit,
   setValue,
   reset,
   formState: { errors }
  } = useForm();

  useEffect(() => {
   if(editData?.link) setValue("link", editData.link);
  }, [editData]);

  function CB(data) {
   if(data?.success) toast.success("Link Edited Successfully");
   else toast.error(data?.message || data?.response?.data?.message || "Something went wrong. Please try again later.");
  }

  const handleClose = () => {
   toggler();
   reset();
  }

  const onSubmit = (data) => {
   handleClose();
   dispatch(editCategoryComponent({ CB: CB, id: editData._id, link: data.link, isWebViewLinkEdit: true }));
  }

  return (
    <>
      <CommonModal
         isOpen={isOpen}
         title={ModalTitle}
         toggler={handleClose}
         togglerSave={handleSubmit(onSubmit)}
      >
        <Container>
          <form
            className="needs-validation"
            noValidate=""
          >
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Link"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="link"
                    {...register("link", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.link && "Link is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>
          </form>
        </Container>
      </CommonModal>
    </>
  );
};