import { createSlice } from "@reduxjs/toolkit";
import {
  addNewlightCategory,
  editlightCategory,
  fetchLightCategory,
  removelightCategory,
} from "./api";

const initialState = {
  lightCategoryData: {},
  isLoading: false,
  status: {
    apiMessage: "",
    apiSuccess: true,
  },
};

const lightCategorySlice = createSlice({
  initialState,
  name: "fetchLightCategory",
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchLightCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLightCategory.fulfilled, (state, action) => {
        // console.log("lightCategory fulfilled:", action.payload);

        state.isLoading = false;
        state.lightCategoryData = action.payload;
      })
      .addCase(fetchLightCategory.rejected, (state, { payload }) => {
        // console.log("lightCategory rejected:", payload);

        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR ADD liGHTCATEGORY --->>>
      .addCase(addNewlightCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewlightCategory.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(addNewlightCategory.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR EDIT LIGHTCATEGORY --->>>
      .addCase(editlightCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editlightCategory.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(editlightCategory.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      //  CASE FOR REMOVE LIGHTCATEGORY
      .addCase(removelightCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removelightCategory.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(removelightCategory.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      });
  },
});

export default lightCategorySlice.reducer;
export const { setStatus } = lightCategorySlice.actions;
export const lightCategorySelector = (state) => state.fetchLightCategory;
