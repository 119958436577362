import React, { useContext, useState } from "react";
import { Image } from "../../AbstractElements";
import CheckContext from "../../_helper/customizer/index";
import { useParams } from "react-router";
// import logo from "../../assets/images/logo/small-logo.png";
// import logo1 from "../../assets/images/logo/small-white-logo.png";

const SidebarIcon = () => {
  const params = useParams()
  const { toggleSidebar } = useContext(CheckContext);
  const [toggle, setToggle] = useState(false);
  const openCloseSidebar = () => {
    setToggle(!toggle);
    toggleSidebar(toggle);
  };
  return (
    <div className="logo-wrapper">
      <a to={`/${params.hotelcode}/hotel_config`} as={`Link`}>
        <Image
          attrImage={{
            className: "img-fluid for-light",
            src: `https://res.cloudinary.com/shashigroup/image/upload/v1665991163/static-page/unknown.png`,
            alt: "",
          }}
        />
        <Image
          attrImage={{
            className: "img-fluid for-dark",
            src: `https://uploads-ssl.webflow.com/63f61c6ce2de8c517720ed68/63fde3509fa9c02c547d7d11_Shashi%20Light.svg`,
            alt: "",
          }}
        />
      </a>
      <div className="back-btn" onClick={() => openCloseSidebar()}>
        <i className="fa fa-angle-left"></i>
      </div>
    </div>
  );
};
export default SidebarIcon;
