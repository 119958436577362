import React, { useEffect, useMemo, useState } from "react";
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";

import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import InputPrev from "../../../../mycomponents/InputPrev";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { saveDiningData, updateDiningData } from "./api";

const DiningModal = ({ isOpen, ModalTitle, toggler, editData }) => {
  console.log("editData:", editData);
  const dispatch = useDispatch();

  const [imageFileA, setImageFileA] = useState(null);
  const [imageFileB, setImageFileB] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const [isReserved, setIsReserved] = useState(false);
  const [type, setType] = useState([{ name: "", time1: "", time2: "" }]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return { type, ...editData };
    }, [editData]),
  });

  const { fields, insert, remove } = useFieldArray({
    control,
    name: "type",
  });

  useEffect(() => {
    setValue("isReserved", isReserved);
    setValue("images", [
      { image: [imageFileA], tag: "primary" },
      { image: [imageFileB], tag: "secondary" },
    ]);
  }, [isReserved, imageFileA, imageFileB]);

  useEffect(() => {
    let cldURL = `https://res.cloudinary.com/shashigroup/image/upload/`;

    if (editData) {
      setIsReserved(editData?.isReserved);
      setImageFileA(`${cldURL}${editData?.images[0]?.image[0]}`);
      setImageFileB(`${cldURL}${editData?.images[1]?.image[0]}`);
    }
  }, [editData]);

  const functionCB = (data) => {
    if (data.success == true) {
      toggler();
      reset();
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message ||
          "Oops.. Something went wrong Please try again later"
      );
    }
  };

  const onSubmit = (data) => {
    console.log("data", data);

    if (editData) {
      dispatch(updateDiningData({ ...data, CB: functionCB }));
    } else {
      dispatch(saveDiningData({ ...data, CB: functionCB }));
    }
  };

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={toggler}
        togglerSave={handleSubmit(onSubmit)}
        isUploading={isUploading}
        size="lg"
      >
        <Container fluid={true} className="bd-example-row">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Name:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Cafe"
                    {...register(`name`, {
                      required: true,
                    })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.name && "Name is required"}
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Description:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Coffee is the new way to get refreshed"
                    {...register(`description`, {
                      required: true,
                    })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.description && "Description is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Open Table URL:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="www.pp.com"
                    {...register(`openTableURL`, {
                      required: true,
                    })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.openTableURL && "Open Table URL is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Capacity:"}</Label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="www.pp.com"
                    {...register(`capacity`)}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Square Feet:"}</Label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="www.pp.com"
                    {...register(`area`)}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="m-b-10">
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="isReserved"
                    name="isReserved"
                    type="checkbox"
                    checked={isReserved}
                    onChange={(e) => setIsReserved(e.target.checked)}
                  />
                  <Label className="d-block" htmlFor="isReserved">
                    isReserved?
                  </Label>
                </div>
              </Col>
            </Row>

            <InputPrev
              inputLabel={"Change Image:"}
              imageLabel={"Primary Image:"}
              src={imageFileA}
              setImageFile={setImageFileA}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
            />
            <InputPrev
              inputLabel={"Change Image:"}
              imageLabel={"Secondary Image:"}
              src={imageFileB}
              setImageFile={setImageFileB}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
            />

            {fields.map((item, index) => {
              return (
                <div key={item.id}>
                  <Row className="d-flex align-items-center">
                    <Col>
                      <FormGroup className="m-b-10">
                        <Label>{"Meal Name:"}</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Breackfast"
                          {...register(`type.${index}.name`)}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="m-b-10">
                        <Label>{"Sun-Thu Timings:"}</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="6:00 pm - 10:00 pm"
                          {...register(`type.${index}.time1`)}
                        />
                      </FormGroup>
                    </Col>
                    <Col>
                      <FormGroup className="m-b-10">
                        <Label>{"Fri & Sat Timings:"}</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="5:30 pm - 10:00 pm"
                          {...register(`type.${index}.time2`)}
                        />
                      </FormGroup>
                    </Col>

                    <Col
                      md={1}
                      className="m-t-15 d-flex align-items-center justify-content-center gap-2"
                    >
                      <div
                        onClick={() => remove(index)}
                        className={
                          fields.length == 1
                            ? "d-none"
                            : "p-2 text-center border border-secondary rounded-1 cursor-pointer"
                        }
                      >
                        <i className="icon-minus"></i>
                      </div>
                      <div
                        className="p-2 text-center border border-secondary rounded-1 cursor-pointer"
                        onClick={() => {
                          insert(index + 1, {
                            name: "",
                            time1: "",
                            time2: "",
                          });
                        }}
                      >
                        <i className="icon-plus"></i>
                      </div>
                    </Col>
                  </Row>
                </div>
              );
            })}
            {/* <input type="submit" /> */}
          </form>
        </Container>
      </CommonModal>
    </>
  );
};

export default DiningModal;
