import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_RESERVATION } from "../../../../utils/axiosInterceptors";
import { config } from "../../../../config";

export const fetchMeetingCategories = createAsyncThunk(
  "meeting/getmeetingdata",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("amenities payload:", state);

    var headers = {
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.get(`getmeetingdata`, { headers });
      // console.log("data:", data);

      return data;
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.response?.data?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);


