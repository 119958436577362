import React, { useEffect, useState } from "react";
import { H6 } from "../../../../AbstractElements";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllThermostatOfHotel } from "./api";
import BarLoader from "../../../../Layout/Loader/BarLoader";
import { allDeviceSelector } from "./allDeviceslice";

const ThermostatData = ({ searchText }) => {
  const dispatch = useDispatch();
  const { allThermostatData, isLoading, status } =
    useSelector(allDeviceSelector);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    dispatch(fetchAllThermostatOfHotel());
  }, []);

  const ThermoRData = allThermostatData?.data
    ?.filter((val, i) => {
      return (
        val &&
        Object.values(val).some((field) => {
          if (typeof field === "string") {
            return field.toLowerCase().includes(searchText.toLowerCase());
          }
          return false;
        })
      );
    })
    .map((val, i) => {
      if (val) {
        return {
          name: val.installations[0].name,
          roomNo: val.roomNo,
          installation_id: val.installations[0].installation_id,
          location_id: val.installations[0].location_id,
        };
      } else {
        return null;
      }
    });

  const ThermoColumns = [
    {
      name: <H6>#</H6>,
      selector: (row, index) => (currentPage - 1) * perPage + (index + 1),
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Name</H6>,
      selector: (row) => row.name,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Room Number</H6>,
      selector: (row) => row.roomNo,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Installation Id</H6>,
      selector: (row) => row.installation_id,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Location Id</H6>,
      selector: (row) => row.location_id,
      sortable: true,
      center: true,
      wrap: true,
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  return (
    <>
      <DataTable
        data={ThermoRData}
        columns={ThermoColumns}
        responsive={true}
        pagination
        paginationComponentOptions={paginationOptions}
        subHeaderAlign="center"
        progressPending={isLoading}
        progressComponent={<BarLoader />}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
      />
    </>
  );
};

export default ThermostatData;
