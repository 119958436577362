import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn, H6, H4 } from "../../../../AbstractElements";
import RoomsModal from "./RoomsModal";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import { roomsSelector } from "./roomsSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchRooms, removeRoom } from "./api";
import BarLoader from "../../../../Layout/Loader/BarLoader";
import { identity } from "@fullcalendar/core";
import { toast } from "react-toastify";

const Rooms = () => {
  const dispatch = useDispatch();
  const { roomsData, isLoading, status } = useSelector(roomsSelector);
  console.log("status:", status);
  console.log("roomsData:", roomsData);

  const [searchText, setSearchText] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(false);

  useEffect(() => {
    dispatch(fetchRooms());

    //
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();

    console.log("searchText:", searchText);
  };

  const handleAdd = () => setIsAdd(!isAdd);
  const handleEdit = (data) => {
    setIsEdit(!isEdit);
    setEditData(data);
  };

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Data successfully deleted");
    } else {
      return toast.error(
        data.result?.message ||
          "Oops.. something went wrong Please try again later later"
      );
    }
  };

  const handleDelete = (id) => {
    console.log("delete id", id);

    let conf = window.confirm("Are you sure you want to delete this room?");

    if (conf) {
      dispatch(removeRoom({ id, CB: functionCB }));
    }
  };

  const handleManage = () => {
    console.log("handleManage");

    // console.log(conf);
  };

  const RoomsRow = roomsData?.result?.data
    ?.filter((val, i) => {
      return (
        val.roomNo.includes(searchText) ||
        // val.grubHubId.includes(searchText) ||
        // val.grubHubKey.includes(searchText) ||
        val.description.toLowerCase().includes(searchText.toLowerCase())
      );
    })
    .map((val, i) => ({
      index: i + 1,
      room_no: val.roomNo,
      description: val.description,
      grubhub_id: val.grubHubId,
      grubhub_key: val.grubHubKey,
      action: (
        <Container className="d-flex btn-showcase">
          <Btn
            attrBtn={{
              color: "primary",
              outline: true,
              onClick: () => handleEdit(val),
            }}
          >
            {"Edit"}
          </Btn>
          <Btn
            attrBtn={{
              color: "danger",
              outline: true,
              onClick: () => handleDelete(val._id),
            }}
          >
            {"Delete"}
          </Btn>
          <Btn
            attrBtn={{
              color: "info",
              outline: true,
              onClick: handleManage,
            }}
          >
            {"Manage Devices"}
          </Btn>
        </Container>
      ),
    }));

  const RoomTypesColumns = [
    {
      name: <H6>#</H6>,
      selector: (row) => row.index,
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Room No</H6>,
      selector: (row) => row.room_no,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Description</H6>,
      selector: (row) => row.description,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>GrubHub Id</H6>,
      selector: (row) => row.grubhub_id,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>GrubHub Key</H6>,
      selector: (row) => row.grubhub_key,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
      width: "555px",
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <Fragment>
      <RoomsModal isOpen={isAdd} ModalTitle={"Add Room"} toggler={handleAdd} />

      {isEdit && editData ? (
        <RoomsModal
          isOpen={isEdit}
          ModalTitle={"Edit Room"}
          toggler={handleEdit}
          editData={editData}
        />
      ) : null}

      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs parent="Room Configuration" title="Room Configuration" />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />

            <Btn
              attrBtn={{
                className: "m-r-15 m-l-15 d-block",
                color: "success",
                // outline: true,
                onClick: handleAdd,
              }}
            >
              Add Rooms
            </Btn>
          </div>
        </div>
        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab">
                  <H4>ROOM DATA</H4>
                </div>
              </Col>

              <CardBody className="p-t-0">
                <div className="order-history table-responsive">
                  <DataTable
                    data={RoomsRow}
                    columns={RoomTypesColumns}
                    responsive={true}
                    pagination
                    paginationComponentOptions={paginationOptions}
                    subHeaderAlign="center"
                    progressPending={isLoading}
                    progressComponent={<BarLoader />}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Rooms;
