import React, { Fragment, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import {
  Card,
  Col,
  Container,
  Row,
  Label,
  FormGroup,
  CardBody,
  ModalFooter,
} from "reactstrap";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import DatePicker from "react-datepicker";

const LoyaltyPromotion = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const dateFormat = (data) => {
    const date = new Date(data);
    const formattedDate = date.toLocaleDateString("en-GB");

    return formattedDate;
  };

  const handleSubmit = (data) => {
    console.log("startDate:", dateFormat(startDate));
    console.log("endDate:", dateFormat(endDate));
  };

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs
            parent="Loyalty Rewards Promotion"
            title="Loyalty Rewards Promotion"
          />
        </div>

        <div ref={componentRef}>
          <Row>
            <Col sm="12">
              <Card>
                <div className="p-4">
                  <div>
                    <h3 style={{ marginBottom: "0" }}>
                      Shashi Cash Default Percentage
                    </h3>
                  </div>

                  <Row>
                    <Col sm="4">
                      <FormGroup>
                        <Label>{"Shashi Cash Default Percentage (%):"}</Label>
                        <input
                          className="form-control"
                          type="number"
                          name="tax"
                        />
                        <span style={{ color: "#ff5370" }}></span>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <CardBody className="p-t-0">
                  <div>
                    <h3 style={{ marginBottom: "0" }}>
                      Shashi Cash Promotional
                    </h3>
                  </div>
                  <Row>
                    <Col sm="4">
                      <Label>{"Shashi Cash Promotion Start Date:"}</Label>

                      <DatePicker
                        className="form-control digits"
                        selected={startDate}
                        onChange={(update) => {
                          setStartDate(update);
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </Col>

                    <Col sm="4">
                      <Label>{"Shashi Cash Promotion End Date:"}</Label>

                      <DatePicker
                        className="form-control digits"
                        selected={endDate}
                        onChange={(update) => {
                          setEndDate(update);
                        }}
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </Col>

                    <Col sm="4">
                      <FormGroup>
                        <Label>{"Shashi Cash Promotion Percentage (%):"}</Label>
                        <input
                          className="form-control"
                          type="number"
                          name="tax"
                        />
                      </FormGroup>
                    </Col>

                    <Col sm="12">
                      <ModalFooter>
                        <Btn
                          attrBtn={{
                            color: "primary",
                            className: "m-t-10",
                          }}
                        >
                          Save Changes
                        </Btn>
                      </ModalFooter>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default LoyaltyPromotion;
