import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

const MainCategoryTabs = ({ mainCategories, activeTab, setActiveTab }) => {
  return (
    <Nav tabs>
      {mainCategories?.map((obj, i) => (
        <NavItem key={i} className="m-l-15 m-b-15 cursor-pointer">
          <NavLink
            className={activeTab === obj.categoryName ? "active" : ""}
            onClick={() => setActiveTab(obj.categoryName)}
          >
            {obj.displayName}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};
export default MainCategoryTabs;
