import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_RESERVATION } from "../../../../utils/axiosInterceptors";
import { config } from "../../../../config";

export const fetchRatePlans = createAsyncThunk(
  "ratePlans/getRatePlanesData",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("ratePlans payload:", state);

    try {
      let { data } = await AXIOS_RESERVATION.get(`getRatePlanesData`);
      // console.log("data:", data);

      return data;
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const saveRatePlanData = createAsyncThunk(
  "ratePlan/saverateplandata",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("ratePlan payload:", state);

    let { CB, ...others } = state;
    others.hotelId = config.HOTELID;

    console.log("others:", others);

    var headers = {
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.post(`saveRatePlanData`, others, {
        headers,
      });
      // console.log("data:", data);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchRatePlans());

        return data;
      }
    } catch (err) {
      // console.log("err:", err);

      CB && CB(err);
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMEssage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const updateRatePlanData = createAsyncThunk(
  "ratePlan/updateRatePlanData",
  async (state, { getState, rejectWithValue, dispatch }) => {
    let { CB, ...others } = state;
    console.log("others", others);

    try {
      let { data } = await AXIOS_RESERVATION.post("updateRatePlanData", others);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchRatePlans());

        return data;
      }
    } catch (err) {
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const removeRatePlanData = createAsyncThunk(
  "ratePlan/removeRatePlanData",
  async (state, { getState, rejectWithValue, dispatch }) => {
    let { id, CB } = state;
    console.log("_id:", id);

    var headers = {
      _id: id,
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.delete("removeRatePlanData", {
        headers,
      });
      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchRatePlans());

        return data;
      }
    } catch (err) {
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);
