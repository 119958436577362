import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_RESERVATION } from "../../../../utils/axiosInterceptors";
import { config } from "../../../../config";

export const fetchHotelConfig = createAsyncThunk(
  "hotel/gethotel",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("hotel payload:", state);
    var headers = {
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.get(`gethotel`, { headers });
      //   console.log("data:", data);

      return data;
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong Please try again later",
        },
      });
    }
  }
);

export const editHotel = createAsyncThunk(
  "hotel/edithotel",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("hotel payload:", state);

    let { CB, ...others } = state;
    // console.log("others:", others);

    var headers = {
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.post(`edithotel`, others, { headers });
      //   console.log("data:", data);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchHotelConfig());

        return data;
      }
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong Please try again later",
        },
      });
    }
  }
);
