import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { H5 } from '../AbstractElements';

export const TitleWithArrow = ({ heading, onClick }) => {
  return (
    <Card className="rounded-0 my-2 cursor-pointer" onClick={onClick}>
      <CardBody className="p-3 p-md-4">
        <div className="d-flex p-0 m-0 justify-content-between align-items-center">
          <H5 attrH5={{ className: 'mb-0' }}>{heading}</H5>
          <span><i className="icon-angle-right"></i></span>
        </div>
      </CardBody>
    </Card>
  );
};