import React from "react";
import ButtonComp from "./ButtonComp";
import CommonModal from "../../../../../_core/Ui-kits/Modals/common/modal";

const ButtonCompModal = ({ isOpen, ModalTitle, toggler, isEdit, editData }) => {
  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={toggler}
        // togglerSave={handleSubmit(onSubmit)}
        size="lg"
      >
        <ButtonComp isEdit={isEdit} />
      </CommonModal>
    </>
  );
};

export default ButtonCompModal;
