import React, { useEffect, useMemo, useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { fetchRatePlans, saveRatePlanData, updateRatePlanData } from "./api";
import { useDispatch } from "react-redux";

const RatePlanModal = ({ isOpen, ModalTitle, toggler, editData }) => {
  console.log("editData:", editData);

  const dispatch = useDispatch();

  const [isPrimary, setIsPrimary] = useState(false);
  const [isCorporate, setIsCorporate] = useState(false);
  const [isHide, setIsHide] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return editData;
    }, [editData]),
  });

  useEffect(() => {
    setValue("isPrimary", isPrimary);
    setValue("isCorporate", isCorporate);
    setValue("isHide", isHide);
  }, [isPrimary, isCorporate, isHide]);

  useEffect(() => {
    if (editData) {
      setIsPrimary(editData?.isPrimary);
      setIsCorporate(editData?.isCorporate);
      setIsHide(editData?.isHide);
    }
  }, [editData]);

  const functionCB = (data) => {
    if (data.success == true) {
      toggler();
      reset();
      dispatch(fetchRatePlans());
      return toast.success(data.result?.message || "Successfull");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message ||
          "Oops! Something went wrong Please try again later"
      );
    }
  };

  const onSubmit = (data) => {
    console.log(data);

    if (editData) {
      dispatch(updateRatePlanData({ ...data, CB: functionCB }));
    } else {
      dispatch(saveRatePlanData({ ...data, CB: functionCB }));
    }
  };

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={toggler}
        togglerSave={handleSubmit(onSubmit)}
      >
        <Container fluid={true} className="bd-example-row">
          <form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Name:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="AAA"
                    name="ratePlanName"
                    {...register("ratePlanName", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.ratePlanName && "Name is required"}
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Code:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="AAA"
                    name="ratePlanCode"
                    {...register("ratePlanCode", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.ratePlanCode && "Code is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <FormGroup>
                  <Label>{"Segment:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="gov"
                    name="segment"
                    {...register("segment", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.segment && "Segment is required"}
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Discount:"}</Label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="10"
                    name="discount"
                    {...register("discount", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.discount && "Discount is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="checkbox"
                    type="checkbox"
                    checked={isPrimary}
                    onChange={(e) => setIsPrimary(e.target.checked)}
                  />
                  <Label className="d-block" htmlFor="checkbox">
                    {"Default?"}
                  </Label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="checkbox2"
                    type="checkbox"
                    checked={isCorporate}
                    onChange={(e) => setIsCorporate(e.target.checked)}
                  />
                  <Label className="d-block" htmlFor="checkbox2">
                    {"Is Corporate plan ?"}
                  </Label>
                </div>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="checkbox3"
                    type="checkbox"
                    checked={isHide}
                    onChange={(e) => setIsHide(e.target.checked)}
                  />
                  <Label className="d-block" htmlFor="checkbox3">
                    {"Is Hidden plan ?"}
                  </Label>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </CommonModal>
    </>
  );
};

export default RatePlanModal;
