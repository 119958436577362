import React, { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { ServicesNavTab } from './ServicesNavTab';
import { Btn, H6 } from '../../../../../../AbstractElements';
import DataTable from 'react-data-table-component';
import { useSelector } from 'react-redux';
import { categoriesSelector } from '../../categoriesSlice';
import Increment from '../../DisplayComponents/Increment';
import { TextBoxComponent } from '../../DisplayComponents/TextBoxComponent';
import { TVandMusic } from './TVandMusic';
import { ServicesModal } from '../../ServicesModal';
import BarLoader from '../../../../../../Layout/Loader/BarLoader';
import { useDispatch } from 'react-redux';
import { deleteCategoryComponent } from '../../categoriesApi';
import { toast } from 'react-toastify';

export const ServicesDetails = ({ servicesData, searchText }) => {
    const { departmentsData, isLoading } = useSelector(categoriesSelector);
    const dispatch = useDispatch();
    const [currentNavItemLink, setCurrentNavItemLink] = useState(servicesData?.[0]?.name ?? "");
    const [isEdit, setIsEdit] = useState(false);
    const [editData, setEditData] = useState(null);

    useEffect(() => {
        setCurrentNavItemLink(servicesData?.[0]?.name);
    }, [searchText]);

    const handleEdit = (data) => {
        setIsEdit(!isEdit)
        setEditData(data);
    }

    function deleteCB(data) {
        if(data.success) {
          toast.success("Deleted successfully");
        } else {
          toast.error("Something went wrong. Please try again later");
        }
    }

    const handleDelete = (data) => {
      let conf = window.confirm("Are you sure that you want to delete this service ?");
      if(conf) dispatch(deleteCategoryComponent({ id: data._id, CB: deleteCB }));
    };

    const existingViewRanks = [];

    servicesData.forEach(obj => {
        existingViewRanks.push(obj.viewRank);
        obj.departmentName = departmentsData?.find(depObj => depObj._id == obj.department)?.departmentName;
        obj.action = (
            <Container className="d-flex btn-showcase">
              <Btn
                attrBtn={{
                  color: "secondary",
                  outline: true,
                  disabled: isLoading,
                  onClick: () => handleEdit(obj)
                }}
              >
                {"Edit"}
              </Btn>
              <Btn
                attrBtn={{
                  color: "danger",
                  outline: true,
                  disabled: isLoading,
                  onClick: () => handleDelete(obj)
                }}
              >
                {"Delete"}
              </Btn>
            </Container>
        );
    })

    let dataRows = servicesData?.filter(obj => obj.name == currentNavItemLink) ?? [];
    let musics = [], tvs = [];
    if(dataRows?.[0]?.viewType == "wakeupcall") {
      musics = dataRows[0].music;

      tvs = dataRows[0].tv;
      tvs = tvs?.map(val => {
        let obj = {};
        obj.name = val;
        return obj;
      });
    }

    const dataColumns = [
        {
            name: <H6>Name</H6>,
            selector: (row) => row.name,
            center: true,
            wrap: true,
            minWidth: "223px"
        },
        {
            name: <H6>Description</H6>,
            selector: (row) => row.description,
            center: true,
            wrap: true,
            minWidth: "223px"
        },
        {
            name: <H6>Department</H6>,
            selector: (row) => row.departmentName,
            center: true,
            wrap: true,
            minWidth: "223px"
        },
        {
            name: <H6>Action Type</H6>,
            selector: (row) => row.actionType,
            center: true,
            wrap: true,
            minWidth: "223px",
            omit: !dataRows?.some(obj => obj.hasOwnProperty("actionType") ? true : false)
        },
        {
            name: <H6>Amount</H6>,
            selector: (row) => row.amount,
            center: true,
            wrap: true,
            minWidth: "223px"
        },
        {
            name: <H6>Show Time</H6>,
            selector: (row) => row.showTime ? "Yes" : "No",
            center: true,
            wrap: true,
            minWidth: "223px",
            omit: !dataRows?.some(obj => obj.hasOwnProperty("showTime") ? true : false)
        },
        {
            name: <H6>Show Light</H6>,
            selector: (row) => row.showLight ? "Yes" : "No",
            center: true,
            wrap: true,
            minWidth: "223px",
            omit: !dataRows?.some(obj => obj.hasOwnProperty("showLight") ? true : false)
        },
        {
            name: <H6>Show Music</H6>,
            selector: (row) => row.showMusic ? "Yes" : "No",
            center: true,
            wrap: true,
            minWidth: "223px",
            omit: !dataRows?.some(obj => obj.hasOwnProperty("showMusic") ? true : false)
        },
        {
            name: <H6>Show TV</H6>,
            selector: (row) => row.showTv ? "Yes" : "No",
            center: true,
            wrap: true,
            minWidth: "223px",
            omit: !dataRows?.some(obj => obj.hasOwnProperty("showTv") ? true : false)
        },
        {
            name: <H6>Light</H6>,
            selector: (row) => row.light ? "Yes" : "No",
            center: true,
            wrap: true,
            minWidth: "223px",
            omit: !dataRows?.some(obj => obj.hasOwnProperty("light") ? true : false)
        },
        {
            name: <H6>Type</H6>,
            selector: (row) => row.type,
            center: true,
            wrap: true,
            minWidth: "223px"
        },
        {
            name: <H6>View Rank</H6>,
            selector: (row) => row.viewRank,
            center: true,
            wrap: true,
            minWidth: "223px"
        },
        {
            name: <H6>Quantity</H6>,
            selector: (row) => row.quantity,
            center: true,
            wrap: true,
            minWidth: "223px",
            omit: !dataRows?.some(obj => obj.hasOwnProperty("quantity") ? true : false)
        },
        {
            name: <H6>Max Quantity Per Room</H6>,
            selector: (row) => row.maxQuantityPerRoom,
            center: true,
            wrap: true,
            minWidth: "223px",
            omit: !dataRows?.some(obj => obj.hasOwnProperty("maxQuantityPerRoom") ? true : false)
        },
        {
            name: <H6>View Type</H6>,
            selector: (row) => row.viewType,
            center: true,
            wrap: true,
            minWidth: "223px"
        },
        {
            name: <H6>Component</H6>,
            selector: (row) =>
                row.viewType == "increment" ? (
                <Increment quantity={row.quantity} />
                ) : row.viewType == "textbox" ? (
                <TextBoxComponent />
                ) : null,
            center: true,
            wrap: true,
            minWidth: "223px",
            omit: !dataRows?.some(obj => obj.viewType == "increment" || obj.viewType == "textbox" ? true : false)
        },
        {
            name: <H6>Inactive</H6>,
            selector: (row) => row.inactive == true ? "Yes" : "No",
            center: true,
            wrap: true,
            minWidth: "223px",
            omit: !dataRows?.some(obj => obj.hasOwnProperty("inactive") ? true : false)
        },
        {
            name: <H6>Is Visible</H6>,
            selector: (row) => row.isVisible == true ? "Yes" : "No",
            center: true,
            wrap: true,
            minWidth: "223px"
        },
        {
          name: <H6>Action</H6>,
          selector: (row) => row.action,
          center: true,
          wrap: true,
          width: "333px"
        }
    ];

    return (
        <Fragment>
            <Container fluid={true}>
                {
                    isEdit ?
                    <ServicesModal
                        isOpen={isEdit}
                        ModalTitle={"Edit Service"}
                        toggler={handleEdit}
                        editData={editData}
                        setEditData={setEditData}
                        existingViewRanks={existingViewRanks}
                    /> : null
                }
                <div className="email-wrap bookmark-wrap">
                    <Row>
                        <Col xl="3" className="box-col-3 xl-30">
                            <div className="md-sidebar">
                                <Card>
                                    <CardBody>
                                        <div className="email-app-sidebar left-bookmark custom-scrollbar">
                                            <ServicesNavTab
                                                servicesTabs={servicesData}
                                                currentNavItemLink={currentNavItemLink}
                                                setCurrentNavItemLink={setCurrentNavItemLink}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                        <Col xl="9" md="12" className="box-col-9 xl-70">
                            <Row className='m-0'>
                                <Card className="email-body radius-left">
                                    <CardBody>
                                        <div className="order-history table-responsive">
                                            <DataTable
                                                data={dataRows}
                                                columns={dataColumns}
                                                responsive={true}
                                                progressPending={isLoading}
                                                progressComponent={<BarLoader />}
                                            />
                                        </div>
                                    </CardBody>
                                </Card>
                            </Row>
                            {dataRows?.[0]?.viewType == "wakeupcall" && (tvs?.length || musics?.length) ? (
                                <TVandMusic
                                    musics={musics}
                                    tvs={tvs}
                                    currentNavItemLink={currentNavItemLink}
                                />
                            ) : null}
                        </Col>
                    </Row>
                </div>
            </Container>
        </Fragment>
    );
}