import React, { useEffect, useMemo, useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import MyDropdown from "../../../../mycomponents/MyDropdown";
import { useForm } from "react-hook-form";
import { addAllDevices, editAllDevices } from "./api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

const AllDevicesModal = ({
  isOpen,
  ModalTitle,
  toggler,
  activeTab,
  isEdit,
  editData,
}) => {
  //console.log("editData Alldevices modal", setTestData(editData));
  const dispatch = useDispatch();
  const [title, setTitle] = useState("Select Room Number");

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  // };

  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return editData;
    }, [editData]),
  });

  useEffect(() => {
    reset();
    setTitle("Select Room Number");
  }, [activeTab]);

  const functionCB = (data) => {
    if (data.success == true) {
      toggler();
      reset();
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message || "Something went wrong. Please try again laetr."
      );
    }
  };

  const onSubmit = (data) => {
    const newData = { ...data, CB: functionCB, activeTab };

    if (editData) {
      dispatch(editAllDevices(newData));
    } else {
      dispatch(addAllDevices(newData));
    }
  };

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={toggler}
        togglerSave={handleSubmit(onSubmit)}
      >
        <form
          className="needs-validation"
          noValidate=""
          onSubmit={handleSubmit(onSubmit)}
        >
          <Container fluid={true} className="bd-example-row">
            {activeTab === 0 ? (
              <>
                <Row className="mb-2">
                  {!isEdit ? (
                    <Col>
                      <FormGroup>
                        <Label>{"Name:"}</Label>
                        <input
                          className="form-control"
                          type="text"
                          placeholder="Name"
                          {...register("name", { required: true })}
                        />

                        <span style={{ color: "#ff5370" }}>
                          {errors.name && "Name is required"}
                        </span>
                      </FormGroup>
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>

                <Row className="d-flex">
                  {!isEdit ? (
                    <Col>
                      <FormGroup>
                        <Label>{"Room Number:"}</Label>
                        <MyDropdown
                          title={title}
                          setTitle={setTitle}
                          data={[
                            { opt: "101" },
                            { opt: "102" },
                            { opt: "103" },
                          ]}
                          registerProps={register("roomNo", { required: true })}
                        />
                        <span style={{ color: "#ff5370" }}>
                          {errors.roomNo && "Room Number is required"}
                        </span>
                      </FormGroup>
                    </Col>
                  ) : (
                    ""
                  )}
                  <Col>
                    <FormGroup>
                      <Label>{"Light Type:"}</Label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Type"
                        {...register("lightType", { required: true })}
                      />
                      <span style={{ color: "#ff5370" }}>
                        {errors.lightType && "Light Type is required"}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col>
                    <FormGroup>
                      <Label>{"RuckusIp:"}</Label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="10.10.10.10"
                        {...register("ruckusIp", { required: true })}
                      />
                      <span style={{ color: "#ff5370" }}>
                        {errors.ruckusIp && "RuckusIp is required"}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>{"MAC:"}</Label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="12ab:12ab:abc:125v"
                        {...register("lightMAC", { required: true })}
                      />
                      <span style={{ color: "#ff5370" }}>
                        {errors.lightMAC && "MAC is required"}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col>
                    <FormGroup>
                      <Label>{"Version:"}</Label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="10.1"
                        {...register("version", { required: true })}
                      />
                      <span style={{ color: "#ff5370" }}>
                        {errors.version && "Version is required"}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>{"Light Category Id:"}</Label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Light Category Id"
                        {...register("lightCategoryId", { required: true })}
                      />
                      <span style={{ color: "#ff5370" }}>
                        {errors.lightCategoryId &&
                          "Light Category Id is required"}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            ) : activeTab === 1 ? (
              <>
                <Row className="d-flex">
                  {!isEdit ? (
                    <Col>
                      <FormGroup>
                        <Label>{"Room Number:"}</Label>
                        <MyDropdown
                          title={title}
                          setTitle={setTitle}
                          data={[
                            { opt: "101" },
                            { opt: "102" },
                            { opt: "103" },
                          ]}
                          registerProps={register("roomNo", { required: true })}
                        />
                        <span style={{ color: "#ff5370" }}>
                          {errors.roomNo && "Room Number is required"}
                        </span>
                      </FormGroup>
                    </Col>
                  ) : (
                    <></>
                  )}
                  <Col>
                    <FormGroup>
                      <Label>{"SSID"}</Label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="123"
                        {...register("ssid", { required: true })}
                      />
                      <span style={{ color: "#ff5370" }}>
                        {errors.ssid && "SSID is required"}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label>{"Password:"}</Label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="qa@#f78d545646y253A%jc873"
                        {...register("password", { required: true })}
                      />
                      <span style={{ color: "#ff5370" }}>
                        {errors.password && "Password is required"}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            ) : activeTab === 2 ? (
              <>
                <Row className="d-flex">
                  {!isEdit ? (
                    <Col>
                      <FormGroup>
                        <Label>{"Room Number:"}</Label>
                        <MyDropdown
                          title={title}
                          setTitle={setTitle}
                          data={[
                            { opt: "101" },
                            { opt: "102" },
                            { opt: "103" },
                          ]}
                          registerProps={register("roomNo", { required: true })}
                        />
                        <span style={{ color: "#ff5370" }}>
                          {errors.roomNo && "Room Number is required"}
                        </span>
                      </FormGroup>
                    </Col>
                  ) : (
                    <></>
                  )}

                  <Col>
                    <FormGroup>
                      <Label>{"Name:"}</Label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="name of thermostat"
                        {...register("name", { required: true })}
                      />
                      <span style={{ color: "#ff5370" }}>
                        {errors.name && "Name is required"}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>

                <Row className="mb-2">
                  <Col>
                    <FormGroup>
                      <Label>{"Object Instance:"}</Label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="12345"
                        {...register("objectInstance", { required: true })}
                      />
                      <span style={{ color: "#ff5370" }}>
                        {errors.objectInstance && "Object Instance is required"}
                      </span>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup>
                      <Label>{"Device Instance:"}</Label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="1"
                        {...register("deviceInstance", { required: true })}
                      />
                      <span style={{ color: "#ff5370" }}>
                        {errors.deviceInstance && "Device Instance is required"}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col>
                    <FormGroup>
                      <Label>{"Object Type:"}</Label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="3"
                        {...register("objectType", { required: true })}
                      />
                      <span style={{ color: "#ff5370" }}>
                        {errors.objectType && "Object Type is required"}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
          </Container>
        </form>
      </CommonModal>
    </>
  );
};

export default AllDevicesModal;
