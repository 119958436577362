import { createSlice } from "@reduxjs/toolkit";
import { fetchMeetingCategories } from "./api";
import meetingRoomSlice from "../MeetingRoom/meetingRoomSlice";

const initialState = {
   meetingCategoriesData:[],
    isLoading: false,
    status: {
      apiMessage: "",
      apiSuccess: true,
    },
  

};

const meetingCategoriesSlice = createSlice({
    initialState,
    name: "fetchMeetingCategoriesConfig",
    reducers: {
      setStatus: (state, action) => {
        state.status = action.payload;
      },
    },
  
    extraReducers: (builder) => {
      builder
        .addCase(fetchMeetingCategories.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(fetchMeetingCategories.fulfilled, (state, action) => {
          console.log("meetingCategories fulfilled:", action.payload);
  
          state.isLoading = false;
          state.meetingCategoriesData = action.payload.result?.data;
         
        })
        .addCase(fetchMeetingCategories.rejected, (state, { payload }) => {
          // console.log("meetingRoom rejected:", payload);
  
          state.isLoading = false;
          state.status = payload.status;
        })
  
        // // CASE FOR EDIT meetingCategories --->>>
        // .addCase(editMeetingCategories.pending, (state) => {
        //   state.isLoading = true;
        // })
        // .addCase(editMeetingCategories.fulfilled, (state, { payload }) => {
        //   state.isLoading = false;
        // })
        // .addCase(editMeetingCategories.rejected, (state, { payload }) => {
        //   state.isLoading = false;
        //   state.status = payload.status;
        // });
    },
  });


export default meetingCategoriesSlice.reducer;
export const { setStatus } = meetingCategoriesSlice.actions;
export const meetingCategoriesSelector = (state) => state.fetchMeetingCategories;
