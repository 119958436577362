import { createSlice } from "@reduxjs/toolkit";
import { getLoyaltyReports } from "./loyaltyReportsApi";

const initialState = {
    reportData: null,
    isLoading: false,
    status: {
        apiMessage: "",
        apiSuccess: true,
    },
};

const loyaltyReportSlice = createSlice({
    initialState,
    name: "loyaltyReports",
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getLoyaltyReports.pending, (state) => {
                state.isLoading = true;
            }).addCase(getLoyaltyReports.fulfilled, (state, action) => {
                state.isLoading = false;
                state.reportData = action.payload;
            }).addCase(getLoyaltyReports.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.status = payload.status;
            })
    }
});

export default loyaltyReportSlice.reducer;
export const { setStatus } = loyaltyReportSlice.actions;
export const loyaltyReportSelector = (state) => state.loyaltyReports;