import React, { useEffect, useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import MyDropdown from "../../../../mycomponents/MyDropdown";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { categoriesSelector } from "./categoriesSlice";
import { useDispatch } from "react-redux";
import {
  addNewCategoryComponent,
  editCategoryComponent,
} from "./categoriesApi";
import { config } from "../../../../config";
import { toast } from "react-toastify";

export const ServicesModal = ({ isOpen, ModalTitle, toggler, editData, setEditData, existingViewRanks }) => {
  const { departmentsData, error } = useSelector(categoriesSelector);
  const dispatch = useDispatch();
  const [componentName, setComponentName] = useState("increment");
  const [department, setDepartment] = useState("Select Department");
  const [showTime, setShowTime] = useState(false);
  const [showLight, setShowLight] = useState(false);
  const [showMusic, setShowMusic] = useState(false);
  const [showTv, setShowTv] = useState(false);
  const [light, setLight] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [inactive, setInactive] = useState(false);

  useEffect(() => {
    if(editData) {
      setComponentName(editData.viewType);
      if(editData.department) {
        setDepartment(departmentsData?.find((obj) => obj._id == editData.department)?.departmentName ?? "Select Department");
      }
      setIsVisible(editData.isVisible);
      setInactive(editData.inactive ?? false);

      if(editData?.viewType == "wakeupcall") {
        setShowTime(editData.showTime);
        setShowLight(editData.showLight);
        setShowMusic(editData.showMusic);
        setShowTv(editData.showTv);
        setLight(editData.light);
      }
    }
  }, [editData]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: editData
      ? { ...editData, categoryName: "Services" }
      : { categoryName: "Services", type: "request" }
  });

  useEffect(() => {
    setValue("componentName", componentName);
  }, [componentName]);

  useEffect(() => {
    if(department !== "Select Department") setValue("department", department);
  }, [department]);

  useEffect(() => {
    setValue("showTime", showTime);
  }, [showTime]);

  useEffect(() => {
    setValue("showLight", showLight);
  }, [showLight]);

  useEffect(() => {
    setValue("showMusic", showMusic);
  }, [showMusic]);

  useEffect(() => {
    setValue("showTv", showTv);
  }, [showTv]);

  useEffect(() => {
    setValue("light", light);
  }, [light]);

  useEffect(() => {
    setValue("isVisible", isVisible);
  }, [isVisible]);

  useEffect(() => {
    setValue("inactive", inactive);
  }, [inactive]);

  if(editData) existingViewRanks = existingViewRanks.filter(val => val != editData.viewRank);

  let departments = JSON.parse(JSON.stringify(departmentsData ?? []));
  departments = departments?.map((obj) => {
    obj.opt = obj.departmentName;
    return obj;
  });

  const addCB = (data) => {
    if(data.success) {
      return toast.success("Service Added Successfully");
    } else {
      return toast.error(error ?? "Something went wrong. Please try again later.");
    }
  }

  const editCB = (data) => {
    if(data?.success) {
      toast.success("Edited Successfully");
    } else {
      toast.error(data?.message || data?.response?.data?.message || "Something went wrong. Please try again later.");
    }
  };

  const handleClose = () => {
    toggler();
    reset();
    setComponentName("increment");
    setValue("componentName", "increment");
    setDepartment("Select Department");
    setShowTime(false);
    setValue("showTime", showTime);
    setShowLight(false);
    setValue("showLight", showLight);
    setShowMusic(false);
    setValue("showMusic", showMusic);
    setShowTv(false);
    setValue("showTv", showTv);
    setLight(false);
    setValue("light", light);
    setIsVisible(true);
    setValue("isVisible", true);
    setInactive(false);
    setValue("inactive", false);
    if(editData) setEditData(null);
  };

  const onSubmit = (formValues) => {
    handleClose();

    if(formValues.componentName != "wakeupcall") {
      delete formValues.showTime;
      delete formValues.showLight;
      delete formValues.showMusic;
      delete formValues.showTv;
      delete formValues.light;
    }

    formValues.amount = parseInt(formValues.amount);

    if([undefined, null, ""].includes(formValues.quantity)) delete formValues.quantity;
    else formValues.quantity = parseInt(formValues.quantity);

    if([undefined, null, ""].includes(formValues.maxQuantityPerRoom)) delete formValues.maxQuantityPerRoom;
    else formValues.maxQuantityPerRoom = parseInt(formValues.maxQuantityPerRoom);

    formValues.viewRank = parseInt(formValues.viewRank);

    delete formValues.departmentName;
    delete formValues.viewType;
    delete formValues.action;

    let { categoryName, componentName, isVisible, ...data } = formValues;
    data.hotelId = config.HOTELID;

    for(let key in data) {
      delete formValues[`${key}`];
    }

    data.department = departmentsData?.find((obj) => obj.departmentName == data.department)?._id;

    delete data._id;
    formValues.data = data;

    if(editData) {
      dispatch(
        editCategoryComponent({ ...formValues, id: editData._id, CB: editCB })
      );
    } else {
      dispatch(
        addNewCategoryComponent({ ...formValues, CB: addCB })
      );
    }
  };

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={handleClose}
        togglerSave={handleSubmit(onSubmit)}
      >
        <Container>
          <form className="needs-validation" noValidate="">
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Category Name"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="categoryName"
                    {...register("categoryName", { required: true })}
                    disabled={true}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.categoryName && "Category Name is required"}
                  </span>
                </FormGroup>
              </Col>
              <Col sm="6" col="9" className="btn-showcase">
                <FormGroup>
                  <Label>{"Component Name"}</Label>
                  <MyDropdown
                    title={componentName}
                    setTitle={setComponentName}
                    data={[
                      { opt: "increment" },
                      { opt: "button" },
                      { opt: "text" },
                      { opt: "textbox" },
                      { opt: "laundry" },
                      { opt: "wakeupcall" }
                    ]}
                    registerProps={register("componentName", {
                      required: true
                    })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.componentName && "Component Name is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Item Name"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    {...register("name", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.name && "Item Name is required"}
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Amount"}</Label>
                  <input
                    className="form-control"
                    type="number"
                    name="amount"
                    {...register("amount", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.amount && "Amount is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Description"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="description"
                    {...register("description")}
                  />
                </FormGroup>
              </Col>
            </Row>

            {
              !editData || editData.hasOwnProperty("actionType") ? 
               <Row>
                  <Col>
                  <FormGroup>
                     <Label>{"Action Type"}</Label>
                     <input
                        className="form-control"
                        type="text"
                        name="actionType"
                        {...register("actionType")}
                     />
                  </FormGroup>
                  </Col>
               </Row> : null
            }

            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Quantity"}</Label>
                  <input
                    className="form-control"
                    type="number"
                    name="quantity"
                    {...register("quantity")}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Max Quantity Per Room"}</Label>
                  <input
                    className="form-control"
                    type="number"
                    name="maxQuantityPerRoom"
                    {...register("maxQuantityPerRoom")}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm="6">
                <FormGroup>
                  <Label>{"Type"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="type"
                    {...register("type", { required: true })}
                    disabled={true}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.type && "Type is required"}
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"View Rank"}</Label>
                  <input
                    className="form-control"
                    type="number"
                    name="viewRank"
                    {...register("viewRank", {
                        required: true,
                        min: { value: 1 },
                        validate: {
                          existing: (value) => !existingViewRanks.includes(parseInt(value)),
                        }
                    })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.viewRank?.type == "min" && "View rank should be at least 1"}
                    {errors.viewRank?.type == "existing" && "This view rank already exists"}
                    {errors.viewRank?.type == "required" && "View rank is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm="6" col="9" className="btn-showcase">
                <FormGroup>
                  <Label>{"Department"}</Label>
                  <MyDropdown
                    title={department}
                    setTitle={setDepartment}
                    data={departments}
                    registerProps={register("department", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.department && "Department is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            {
              componentName == "wakeupcall" ?
              <Row className="mb-2">
                <Col className="col-6 mb-2">
                  <div className="d-flex">
                    <Input
                      className="checkbox_animated"
                      id="showTime"
                      name="showTime"
                      type="checkbox"
                      checked={showTime}
                      onChange={(e) => setShowTime(e.target.checked)}
                    />
                    <Label className="d-block" htmlFor="showTime">
                      Show Time
                    </Label>
                  </div>
                </Col>
                <Col className="col-6 mb-2">
                  <div className="d-flex">
                    <Input
                      className="checkbox_animated"
                      id="showLight"
                      name="showLight"
                      type="checkbox"
                      checked={showLight}
                      onChange={(e) => setShowLight(e.target.checked)}
                    />
                    <Label className="d-block" htmlFor="showLight">
                      Show Light
                    </Label>
                  </div>
                </Col>
                <Col className="col-6 mb-2">
                  <div className="d-flex">
                    <Input
                      className="checkbox_animated"
                      id="showMusic"
                      name="showMusic"
                      type="checkbox"
                      checked={showMusic}
                      onChange={(e) => setShowMusic(e.target.checked)}
                    />
                    <Label className="d-block" htmlFor="showMusic">
                      Show Music
                    </Label>
                  </div>
                </Col>
                <Col className="col-6 mb-2">
                  <div className="d-flex">
                    <Input
                      className="checkbox_animated"
                      id="showTv"
                      name="showTv"
                      type="checkbox"
                      checked={showTv}
                      onChange={(e) => setShowTv(e.target.checked)}
                    />
                    <Label className="d-block" htmlFor="showTv">
                      Show TV
                    </Label>
                  </div>
                </Col>
                <Col className="col-6">
                  <div className="d-flex">
                    <Input
                      className="checkbox_animated"
                      id="light"
                      name="light"
                      type="checkbox"
                      checked={light}
                      onChange={(e) => setLight(e.target.checked)}
                    />
                    <Label className="d-block" htmlFor="light">
                      Light
                    </Label>
                  </div>
                </Col>
              </Row> : null
            }

            <Row className="mb-2">
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="inactive"
                    name="inactive"
                    type="checkbox"
                    checked={inactive}
                    onChange={(e) => setInactive(e.target.checked)}
                  />
                  <Label className="d-block" htmlFor="inactive">
                    Inactive ?
                  </Label>
                </div>
              </Col>
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="isVisible"
                    name="isVisible"
                    type="checkbox"
                    checked={isVisible}
                    onChange={(e) => setIsVisible(e.target.checked)}
                  />
                  <Label className="d-block" htmlFor="isVisible">
                    Is Visible ?
                  </Label>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </CommonModal>
    </>
  );
};
