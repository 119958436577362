import React, { Fragment, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import MySearchInput from "../../../mycomponents/MySearchInput";
import TableHeaderComp from "../../../mycomponents/TableHeaderComp";
import { Breadcrumbs, Btn, H6 } from "../../../AbstractElements";
import NotificationModal from "./NotificationModal";

const Notification = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isSend, setIsSend] = useState(false);

  const handleSend = () => {
    setIsSend(!isSend);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    console.log("searchText:", searchText);
  };

  const RoomServiceRData = [
    {
      index: "1",
      users: "xyz",
      title: "new Message",
      data: "Notification data",
    },
    {
      index: "2",
      users: "abc",
      title: "new Message",
      data: "Notification data",
    },
  ];

  const RoomServiceRColumns = [
    {
      name: <H6>#</H6>,
      selector: (row) => row.index,
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Users</H6>,
      selector: (row) => row.users,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Title</H6>,
      selector: (row) => row.title,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Notification Data</H6>,
      selector: (row) => row.data,
      sortable: true,
      center: true,
      wrap: true,
    },
  ];

  const RoomServiceRPending = [
    {
      index: "1",
      users: "xyz",
    },
    {
      index: "2",
      users: "abc",
    },
  ];

  const RoomServiceRColumnsPending = [
    {
      name: <H6>#</H6>,
      selector: (row) => row.index,
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Users</H6>,
      selector: (row) => row.users,
      sortable: true,
      center: true,
      wrap: true,
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <Fragment>
      <NotificationModal
        isOpen={isSend}
        ModalTitle={"Send Notifications"}
        toggler={handleSend}
      />

      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs parent="Push Notification" title="Push Notification" />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />

            <Btn
              attrBtn={{
                className: "m-r-15 m-l-15 d-block",
                color: "success",
                // outline: true,
                onClick: handleSend,
              }}
            >
              Send Notifications
            </Btn>
          </div>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab">
                  <TableHeaderComp
                    headingData={[
                      { title: "All" },
                      { title: "Android" },
                      { title: "iOS" },
                    ]}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </div>
              </Col>

              <CardBody className="p-t-0">
                <Row>
                  <Col sm="6">
                    <div className="order-history table-responsive">
                      <DataTable
                        data={RoomServiceRData}
                        columns={RoomServiceRColumns}
                        responsive={true}
                        pagination
                        paginationComponentOptions={paginationOptions}
                        // paginationTotalRows={RoomServiceRData.length}

                        // progressPending={loading}
                        // progressComponent={<Loader />}
                        // paginationDefaultPage={currentPage}
                        // onChangeRowsPerPage={handlePerRowsChange}
                        // onChangePage={handlePageChange}
                        // searching={true}
                      />
                    </div>
                  </Col>

                  <Col sm="6">
                    <div className="order-history table-responsive">
                      <DataTable
                        data={RoomServiceRPending}
                        columns={RoomServiceRColumnsPending}
                        responsive={true}
                        pagination
                        paginationComponentOptions={paginationOptions}
                        selectableRows={true}

                        // responsive={true}
                        // progressPending={loading}
                        // progressComponent={<Loader />}
                        // paginationTotalRows={totalRows}
                        // paginationDefaultPage={currentPage}
                        // onChangeRowsPerPage={handlePerRowsChange}
                        // onChangePage={handlePageChange}
                        // searching={true}
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
              {/* </Fragment> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Notification;
