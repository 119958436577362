import React from "react";
import { Dropdown } from "react-bootstrap";

// import { Dropdown, DropdownItem, DropdownMenu } from "reactstrap";
// import { Btn } from "../AbstractElements";

const MyDropdown = ({ title, setTitle, data, registerProps = {}, disable = false }) => {
  return (
    <>
      <Dropdown>
        <Dropdown.Toggle
          className="w-100 overflow-x-hidden"
          variant="outline-secondary"
          id="dropdown-basic"
          disabled={disable}
        >
          {title}
        </Dropdown.Toggle>

        <Dropdown.Menu className="w-100">
          {data?.map((name, i) => (
            <Dropdown.Item
              className="text-black"
              key={i}
              onClick={() => {
                setTitle(name.opt);
                if (registerProps.onChange) {
                  registerProps.onChange({
                    target: {
                      value: name.opt,
                      name: registerProps.name,
                    },
                  });
                }
              }}
            >
              {name.opt}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      {/* <div className="dropdown-basic">
        <Dropdown className="dropdown">
          <Btn attrBtn={{ color: "danger", className: "dropbtn" }}>
            {Title}
            <span>
              <i className="icofont icofont-arrow-down"></i>
            </span>
          </Btn>
          <DropdownMenu className="dropdown-content">
            {data?.map((name, i) => (
              <DropdownItem key={i}>{name.opt}</DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      </div> */}
    </>
  );
};

export default MyDropdown;
