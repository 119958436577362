import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn, H4, H6 } from "../../../../AbstractElements";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import { useDispatch } from "react-redux";
import { fetchLoyaltyUnsettled, settleLoyalty } from "./api";
import { useSelector } from "react-redux";
import { loyaltyUnsettledSelector } from "./loyaltyUnsettledSlice";
import BarLoader from "../../../../Layout/Loader/BarLoader";
import { toast } from "react-toastify";
import moment from "moment";
import { CloseIcon } from "./Icons";
import { SettlementInfoModal } from "./SettlementInfoModal";

const LoyaltyUnsettled = () => {
  const dispatch = useDispatch();

  const { loyaltyUnsettledData, isLoading } = useSelector(
    loyaltyUnsettledSelector
  );

  const [searchText, setSearchText] = useState("");
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [settleModalOpen, setSettleModalOpen] = useState(false);
  const [settleModalInfo, setSettleModalInfo] = useState("");

  function fetchError(err) {
    toast.error(err?.response?.data?.message ?? "Something went wrong. Please try again later.");
  }

  useEffect(() => {
    dispatch(
      fetchLoyaltyUnsettled({
        page: 1,
        size: perPage,
        setTotalRows,
        CB: fetchError
      })
    );
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();

    dispatch(
      fetchLoyaltyUnsettled({
        page: 1,
        size: perPage,
        searchText,
        setTotalRows,
        CB: fetchError
      })
    );
  };

  const functionCB = (data) => {
    if (data.status == true) {
      toast.success(data?.message || "Successful");

      dispatch(fetchLoyaltyUnsettled({
        page: currentPage,
        size: perPage,
        searchText,
        setTotalRows,
        CB: fetchError
      }));
    } else {
      return toast.error(
        data.result?.message || "Something went wrong. Please try again laetr."
      );
    }
  };

  const LoyaltyUnsettledRow = loyaltyUnsettledData?.result ?? [];

  const handleSettle = (rowData) => {
    if(rowData.shashiCashEnrollment && rowData.isEmailVerified) {
      let conf = window.confirm("Do you want to settle this loyalty ?");

      if (conf) {
        dispatch(settleLoyalty({ reservationId: rowData.reservationId, CB: functionCB }));
      }
    } else {
      if(!rowData.shashiCashEnrollment && !rowData.isEmailVerified) {
        setSettleModalInfo("Shashi cash enrollment and Email verification needs to be completed for settling loyalty.");
      } else if(!rowData.shashiCashEnrollment) {
        setSettleModalInfo("Shashi cash enrollment needs to be completed for settling loyalty");
      } else if(!rowData.isEmailVerified) {
        setSettleModalInfo("Email verification needs to be completed for settling loyalty");
      }

      setSettleModalOpen(true);
    }
  }

  const LoyaltyUnsettledColumn = [
    {
      name: <H6>#</H6>,
      selector: (row, index) => (currentPage - 1) * perPage + (index + 1),
      center: true,
      wrap: true,
      width: "60px",
    },
    {
      name: <H6>Reservation ID</H6>,
      selector: (row) =>
        row.reservationId
          ? row.reservationId
          : "-",
      center: true,
      wrap: true,
      width: "146px",
    },
    {
      name: <H6>Shashi ID</H6>,
      selector: (row) => row.emailId ?? "-",
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "206px"
    },
    {
      name: <H6>Shashi Cash Enrollment</H6>,
      selector: (row) => (row.isLoyaltyApply ? "Yes" : "No"),
      center: true,
      wrap: true,
      width: "216px",
    },
    {
      name: <H6>Arrival Date</H6>,
      selector: (row) =>
        row.arrivalDate
          ? moment.unix(row.arrivalDate).format("MMM DD, YYYY")
          : "-",
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "155px"
    },
    {
      name: <H6>Checkin Time</H6>,
      selector: (row) =>
        row.checkInTime ? row.checkInTime : "-",
      center: true,
      wrap: true,
      minWidth: "160px"
    },
    {
      name: <H6>Departure Date</H6>,
      selector: (row) =>
        row.departureDate
          ? moment.unix(row.departureDate).format("MMM DD, YYYY")
          : "-",
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "165px"
    },
    {
      name: <H6>Checkout Time</H6>,
      selector: (row) =>
        row.checkOutTime ? row.checkOutTime : "-",
      center: true,
      wrap: true,
      minWidth: "160px"
    },
    {
      name: <H6>Loyalty Settlement</H6>,
      cell: (row) => (
        <div style={{ cursor: "default" }} onClick={(e) => e.preventDefault()}>
          {
            row.loyaltyRedeemStatus == "cross" ? (
              <CloseIcon />
            ) : row.loyaltyRedeemStatus == "disabled" ? (
              <Btn
                attrBtn={{
                  color: "secondary",
                  outline: true,
                  disabled: true,
                  className: "disabled-settle"
                }}
              >
                Settle Loyalty
              </Btn>
            ) : row.loyaltyRedeemStatus == "button" ? (
              <Btn
                attrBtn={{
                  color: "secondary",
                  outline: true,
                  className: "bg-white",
                  onClick: () => handleSettle(row)
                }}
              >
                Settle Loyalty
              </Btn>
            ) : (
              ""
            )
          }
        </div>
      ),
      center: true,
      minWidth: "200px"
    }
  ];

  const handlePageChange = (page) => {
    dispatch(
      fetchLoyaltyUnsettled({
        page: page,
        size: perPage,
        searchText,
        setTotalRows,
        CB: fetchError
      })
    );

    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    dispatch(
      fetchLoyaltyUnsettled({
        page: page,
        size: newPerPage,
        searchText,
        setTotalRows,
        CB: fetchError
      })
    );
    setPerPage(newPerPage);
  };

  const openSettleInfoModal = () => {
    setSettleModalOpen(!settleModalOpen);
  }

  return (
    <Fragment>
      <Container fluid={true}>
        {
          settleModalOpen
            ? <SettlementInfoModal
              isOpen={settleModalOpen}
              toggle={openSettleInfoModal}
              data={settleModalInfo}
            />
            : null
        }

        <div className="d-flex">
          <Breadcrumbs parent="Loyalty Unsettled" title="Loyalty Unsettled" />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </div>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab">
                  <H4>LOYALTY UNSETTLED DATA</H4>
                </div>
              </Col>

              {!isLoading ? (
                <CardBody className="p-t-0">
                  <div className="order-history table-responsive">
                    <DataTable
                      responsive={true}
                      columns={LoyaltyUnsettledColumn}
                      data={LoyaltyUnsettledRow}
                      progressPending={isLoading}
                      progressComponent={<BarLoader />}
                      pagination
                      paginationServer
                      paginationTotalRows={totalRows}
                      paginationDefaultPage={currentPage}
                      onChangeRowsPerPage={handlePerRowsChange}
                      onChangePage={handlePageChange}
                      searching={true}
                      paginationPerPage={perPage}
                    />
                  </div>
                </CardBody>
              ) : (
                <BarLoader />
              )}
            </Card>
          </Col>
        </Row>
      </Container>
      <style jsx>
        {`
          .disabled-settle {
            background-color: #f2f2f2 !important;
          }
        `}
      </style>
    </Fragment>
  );
};

export default LoyaltyUnsettled;
