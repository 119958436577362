import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_RESERVATION } from "../../../../utils/axiosInterceptors";
import { config } from "../../../../config";

export const fetchPackages = createAsyncThunk(
  "packages/getAddonPackages",
  async (state, { rejectWithValue }) => {
    let CB = state?.CB;
    try {
      let { data } = await AXIOS_RESERVATION.get(`getAddonPackages`);
      return data;
    } catch (err) {
      err.success = false;
      CB && CB(err);
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage: err?.response?.data?.message || "Something went wrong. Please try again later."
        }
      });
    }
  }
);

export const addOrEditAddonPackage = createAsyncThunk(
  "packages/addOrEditAddonPackage",
  async (state, { rejectWithValue }) => {
    let { CB, ...others } = state;
    others.hotelId = config.HOTELID;

    var headers = {
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.post(`addOrEditAddonPackage`, others, {
        headers,
      });

      CB && CB(data);
      if (data?.success == true) {
        return data;
      }
    } catch (err) {
      CB && CB(err);
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage: err?.response?.data?.message || "Something went wrong please try again later."
        },
      });
    }
  }
);

export const addEditOrDeleteOptionsInAddonPackage = createAsyncThunk(
  "packages/addEditOrDeleteOptionsInAddonPackage",
  async (state, { rejectWithValue, dispatch }) => {
    let { CB, ...others } = state;

    try {
      let { data } = await AXIOS_RESERVATION.patch(`addEditOrDeleteOptionsInAddonPackage`, others);
      CB && CB(data);
      if (data?.success == true) {
        return data;
      }
    } catch (err) {
      CB && CB(err);
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage: err?.response?.data?.message || "Something went wrong please try again later."
        }
      });
    }
  }
)