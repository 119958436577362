import React, { useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import { useFieldArray, useForm } from "react-hook-form";

const MeetingTableLayoutModal = ({ isOpen, ModalTitle, toggler, editData }) => {
  const { register, control, handleSubmit, reset, watch, errors } = useForm({
    defaultValues: {
      test: [],
    },
  });
  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "test",
    });

  const onSubmit = (data) => {
    console.log("data", data);
  };
  console.log("errors:", errors);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  return (
    <>
      <CommonModal isOpen={isOpen} title={ModalTitle} toggler={toggler}>
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Name:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="U-shape"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Primary Image:"}</Label>
                  <img
                    style={{ width: "151px", border: "1px solid red" }}
                    src="https://res.cloudinary.com/shashigroup/image/upload/cce5dbc5-a787-4f19-9cc7-bea103ef9110.png"
                    alt=""
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <FormGroup>
                  <Label>{"Meeting Room Capacity:"}</Label>
                  <Input
                    className="form-control"
                    type="number"
                    placeholder="1"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Large Meeting Rom Capacity:"}</Label>
                  <Input
                    className="form-control"
                    type="number"
                    placeholder="1"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="checkbox3"
                    type="checkbox"
                    defaultChecked
                  />
                  <Label className="d-block" htmlFor="checkbox3">
                    Enable Category?
                  </Label>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </CommonModal>
    </>
  );
};

export default MeetingTableLayoutModal;
