import React, { Fragment, memo } from "react";
import { Input, InputGroup } from "reactstrap";

const MySearchInput = ({ handleSearch, searchText, setSearchText }) => {
  return (
    <Fragment>
      <form onSubmit={handleSearch}>
        <InputGroup>
          <Input
            className="form-control"
            type="text"
            onChange={(e) => setSearchText(e.target.value)}
            value={searchText}
            placeholder="Search......."
          />
          <span className="input-group-text">
            <i
              className="fa fa-search cursor-pointer"
              onClick={handleSearch}
            ></i>
          </span>
        </InputGroup>
      </form>
    </Fragment>
  );
};

export default memo(MySearchInput);
