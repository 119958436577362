import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_HOSPITALITY } from "../../../utils/axiosInterceptors";

export const fetchAdmin = createAsyncThunk(
  "admin/adminlogin",
  async (state, { getState, rejectWithValue, dispatch }) => {
    console.log("admin payload:", state);

    let { email, password, CB } = state;

    try {
      let { data } = await AXIOS_HOSPITALITY.post(`adminlogin`, {
        email,
        password,
      });
      console.log("fetchAdmin >> data:", data);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        return data;
      }
    } catch (err) {
      console.log("fetchAdmin >> err:", err);

      CB && CB(err);
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message || err?.response?.data?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);
