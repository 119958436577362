import React from "react";
import DropdownMultiselect from "react-multiselect-dropdown-bootstrap";

const MySelectDropd = (props) => {
  const changeMarket = (selected) => {
    props.selectedOpt?.(selected);
    props.getNameAndValue?.({ name: props.name, value: selected });
  };

  return (
    <DropdownMultiselect
      options={props.options}
      name={props.name}
      handleOnChange={(selected) => changeMarket(selected)}
      selected={props.selectedValues}
      // selectDeselectLabel={"All"}
    />
  );
};

export default MySelectDropd;
