import React, { Fragment, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import {
  RoomServiceRColumns,
  RoomServiceRData,
  RoomServiceRColumnsComp,
  RoomServiceRDataComp,
} from "./RoomSRData";
import MySearchInput from "../../../mycomponents/MySearchInput";
import TableHeaderComp from "../../../mycomponents/TableHeaderComp";
import { Breadcrumbs } from "../../../AbstractElements";

const RoomServicesRequest = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();

    console.log("searchText:", searchText);
  };

  const headingData = [
    { title: "Pending/Assignment Requests" },
    { title: "Completed Requests" },
  ];

  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs
          parent="Room Services Request"
          title="Room Services Request"
        />
        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab">
                  <TableHeaderComp
                    headingData={headingData}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />

                  <MySearchInput
                    handleSearch={handleSearch}
                    searchText={searchText}
                    setSearchText={setSearchText}
                  />
                </div>
              </Col>

              <CardBody>
                <div className="order-history table-responsive">
                  {activeTab == 0 ? (
                    <DataTable
                      pagination
                      paginationServer
                      data={RoomServiceRData}
                      columns={RoomServiceRColumns}

                      // responsive={true}
                      // progressPending={loading}
                      // progressComponent={<Loader />}
                      // paginationTotalRows={totalRows}
                      // paginationDefaultPage={currentPage}
                      // onChangeRowsPerPage={handlePerRowsChange}
                      // onChangePage={handlePageChange}
                      // searching={true}
                    />
                  ) : (
                    <DataTable
                      pagination
                      paginationServer
                      data={RoomServiceRDataComp}
                      columns={RoomServiceRColumnsComp}

                      // responsive={true}
                      // progressPending={loading}
                      // progressComponent={<Loader />}
                      // paginationTotalRows={totalRows}
                      // paginationDefaultPage={currentPage}
                      // onChangeRowsPerPage={handlePerRowsChange}
                      // onChangePage={handlePageChange}
                      // searching={true}
                    />
                  )}
                </div>
              </CardBody>
              {/* </Fragment> */}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default RoomServicesRequest;
