import React, { Fragment, useState, useEffect, useMemo } from "react";
import {
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
} from "reactstrap";
import { Btn, H5, UL } from "../../../AbstractElements";
import {
  EmailAddress,
  LoginWithJWT,
  Password,
  SignIn,
} from "../../../Constant";
import man from "../../../assets/images/dashboard/1.png";
import FormPassword from "./FormPassword";

import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { useDispatch } from "react-redux";
import { fetchAdmin } from "./api";
import { useNavigate, useParams } from "react-router";
import {
  checkAuthTokenAvailability,
  setAuthToken,
} from "../../../utils/cookieReducer";
import { useSelector } from "react-redux";
import { adminSelector } from "./loginSlice";
import { toast } from "react-toastify";

const LoginTab = ({ selected }) => {
  const params = useParams()
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { adminData, isLoading, status } = useSelector(adminSelector);
  console.log('adminData, isLoading, status:', adminData, isLoading, status)

  const [savedData] = useState(
    JSON.parse(localStorage.getItem("shashiLoginData"))
  );
  const [isRemember, setIsRemember] = useState(false);
  const [togglePassword, setTogglePassword] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return savedData;
    }, [savedData]),
  });

  useEffect(() => {
    setIsLoggedIn(checkAuthTokenAvailability());

    if (isLoggedIn) {
      navigate(`/${params.hotelcode}/hotel_config`);
    }
  }, [isLoggedIn]);

  const functionCB = (data) => {
    console.log('LoginTab >>>>>>>> data:', data)
    if (data?.result?.token) {
      console.log('success')
      setAuthToken(data.result.token);

      navigate(`/${params.hotelcode}/hotel_config`);
    } else if (!data?.success) {
      console.log('failed')

      return toast.error(
        data?.result?.message || data?.response?.data?.result?.message ||
        "Oppss.. The password is invalid or the user does not exist."
      );
    }
  };

  const onSubmit = (data) => {
    // console.log(data);
    // console.log(isRemember);

    if (isRemember) {
      localStorage.setItem("shashiLoginData", JSON.stringify(data));
    }

    dispatch(fetchAdmin({ ...data, CB: functionCB }));
  };

  return (
    <Fragment>
      <form
        className="theme-form login-form needs-validation"
        noValidate=""
        onSubmit={handleSubmit(onSubmit)}
      >
        <FormGroup>
          <Label>{EmailAddress}</Label>
          <InputGroup>
            <InputGroupText>
              <i className="icon-email"></i>
            </InputGroupText>
            <input
              className="form-control"
              type="email"
              name="email"
              placeholder="test@gmail.com"
              {...register("email", { required: true })}
            />
          </InputGroup>
          <span style={{ color: "#ff5370" }}>
            {errors.email && "Email is required"}
          </span>
        </FormGroup>
        <FormGroup>
          <Label>{Password}</Label>
          <InputGroup>
            <InputGroupText>
              <i className="icon-eye"></i>
            </InputGroupText>

            <input
              className="form-control"
              type={togglePassword ? "text" : "password"}
              name="password"
              placeholder="test@123"
              {...register("password", { required: true })}
            />

            <div
              className="show-hide"
              onClick={() => setTogglePassword(!togglePassword)}
            >
              <span className={togglePassword ? "" : "show"}></span>
            </div>
          </InputGroup>
          <span style={{ color: "#ff5370" }}>
            {errors.password && "Password is required"}
          </span>
        </FormGroup>
        <FormPassword isRemember={isRemember} setIsRemember={setIsRemember} />
        <FormGroup className="m-0">
          <Btn
            attrBtn={{
              color: "primary",
              className: "btn-block",
              disabled: isLoading ? isLoading : isLoading,
              onClick: handleSubmit(onSubmit),
            }}
          >
            {isLoading ? "LOADING..." : "Login"}
          </Btn>
        </FormGroup>
      </form>
    </Fragment>
  );
};

export default LoginTab;
