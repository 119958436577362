import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_RESERVATION } from "../../../../utils/axiosInterceptors";
import { config } from "../../../../config";

export const fetchDining = createAsyncThunk(
  "dining/getdiningdata",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("amenities payload:", state);

    try {
      let { data } = await AXIOS_RESERVATION.get(`getdiningdata`);
    // console.log("data:", data);

      return data;
    } catch (err) {
    // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const saveDiningData = createAsyncThunk(
  "dining/savediningdata",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("dining payload:", state);

    let { CB, ...others } = state;
    others.hotelId = config.HOTELID;

    console.log("others:", others);

    var headers = {
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.post(`savediningdata`, others, {
        headers,
      });
      // console.log("data:", data);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchDining());

        return data;
      }
    } catch (err) {
      CB && CB(err);
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong Please try again later",
        },
      });
    }
  }
);

export const updateDiningData = createAsyncThunk(
  "dining/updatediningdata",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("dining payload:", state);

    let { CB, ...others } = state;

    try {
      let { data } = await AXIOS_RESERVATION.post(`updatediningdata`, others);
      // console.log("data:", data);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchDining());

        return data;
      }
    } catch (err) {
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong Please try again later",
        },
      });
    }
  }
);

export const removeDiningData = createAsyncThunk(
  "dining/removediningdata",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("amenities payload:", state);

    let { id, CB } = state;
    console.log("_id:", id);

    var headers = {
      _id: id,
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.delete(`removediningdata`, {
        headers,
      });
      // console.log("data:", data);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchDining());

        return data;
      }
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);
