import React from "react";
import { Nav, NavItem } from "reactstrap";

const LeftSelectCategory = ({ title, activeTab, setActiveTab, listData }) => {
  return (
    <>
      <Nav className="main-menu" role="tablist">
        <NavItem>
          <span className="main-title">{title}</span>
        </NavItem>

        {listData?.map((val, i) => (
          <NavItem key={i}>
            <a
              // className={activeTab === i ? "text-primary" : ""}
              style={{
                color: activeTab === i ? "rgb(255, 0, 148)" : "",
                backgroundColor: activeTab === i ? "#d762e71a" : ""
              }}
              onClick={() => setActiveTab(i)}
            >
              <span className="title"> {val.name}</span>
            </a>
          </NavItem>
        ))}
      </Nav>
    </>
  );
};

export default LeftSelectCategory;
