export const Data =[
    {
        heading:'Loader 2',
        spinnerClass:'loader-2'
    },
    {
        heading:'Loader 3',
        spinnerClass:'loader-3'
    },
    {
        heading:'Loader 4',
        spinnerClass:'loader-4'
    },

    {
        heading:'Loader 5',
        spinnerClass:'loader-5'
    },
    {
        heading:'Loader 6',
        spinnerClass:'loader-6'
    },
    {
        heading:'Loader 7',
        spinnerClass:'loader-7'
    },
    {
        heading:'Loader 8',
        spinnerClass:'loader-8'
    },

    {
        heading:'Loader 9',
        spinnerClass:'loader-9'
    },
    {
        heading:'Loader 10',
        spinnerClass:'loader-10'
    },
    {
        heading:'Loader 11',
        spinnerClass:'loader-11'
    },
    {
        heading:'Loader 12',
        spinnerClass:'loader-12'
    },

    {
        heading:'Loader 13',
        spinnerClass:'loader-13'
    },

    {
        heading:'Loader 14',
        spinnerClass:'loader-14'
    },
    {
        heading:'Loader 15',
        spinnerClass:'loader-15'
    },
    {
        heading:'Loader 16',
        spinnerClass:'loader-17'
    },
    {
        heading:'Loader 17',
        spinnerClass:'loader-18'
    },

    {
        heading:'Loader 19',
        spinnerClass:'loader-20'
    },
    {
        heading:'Loader 20',
        spinnerClass:'loader-21'
    },
    {
        heading:'Loader 21',
        spinnerClass:'loader-22'
    },
    {
        heading:'Loader 22',
        spinnerClass:'loader-23'
    },
    {
        heading:'Loader 23',
        spinnerClass:'loader-24'
    },
    {
        heading:'Loader 24',
        spinnerClass:'loader-25'
    },
    {
        heading:'Loader 25',
        spinnerClass:'loader-26'
    },
    {
        heading:'Loader 26',
        spinnerClass:'loader-29'
    },
    {
        heading:'Loader 27',
        spinnerClass:'loader-30'
    },
    {
        heading:'Loader 28',
        spinnerClass:'loader-31'
    },
    {
        heading:'Loader 29',
        spinnerClass:'loader-32'
    },
    {
        heading:'Loader 30',
        spinnerClass:'loader-34'
    },

    {
        heading:'Loader 31',
        spinnerClass:'loader-35'
    },
    {
        heading:'Loader 32',
        spinnerClass:'loader-37'
    },
    {
        heading:'Loader 33',
        spinnerClass:'loader-38'
    },
    {
        heading:'Loader 34',
        spinnerClass:'loader-39'
    },
    {
        heading:'Loader 35',
        spinnerClass:'loader-40'
    }
];