import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Col, Form, FormGroup, Label, ModalFooter, Row } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { hotelConfigSelector } from "./hotelConfigSlice";
import { editHotel, fetchHotelConfig } from "./api";
import { toast } from "react-toastify";

const InRoomDining = () => {
  const dispatch = useDispatch();

  const { hotelConfigData, isLoading, status } =
    useSelector(hotelConfigSelector);
  console.log("isLoading:", isLoading);
  console.log("status:", status);
  console.log("hotelConfigData:", hotelConfigData);

  useEffect(() => {
    dispatch(fetchHotelConfig());
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: useMemo(() => {
      return hotelConfigData;
    }, [hotelConfigData]),
  });

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message ||
          "Oops.. Something went wrong Please try again later"
      );
    }
  };

  const onSubmit = (data) => {
    console.log(data);

    if (hotelConfigData) {
      dispatch(editHotel({ ...data, CB: functionCB }));
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
        <Row className="align-items-center">
          <Col>
            <FormGroup>
              <Label>{"In Room Dining Tax(%):"}</Label>
              <input
                className="form-control"
                type="number"
                name="inRoomDiningTax"
                {...register("inRoomDiningTax", {
                  required: true,
                })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.in_room_dining_tax && "In room dining tax is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"In Room Dining Gratuity(%):"}</Label>
              <input
                className="form-control"
                type="number"
                name="inRoomDiningGratuity"
                {...register("inRoomDiningGratuity", {
                  required: true,
                })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.in_room_dining_gratuity &&
                  "In room dining gratuity is required"}
              </span>
            </FormGroup>
          </Col>

          <Col>
            <ModalFooter>
              <Btn
                attrBtn={{
                  color: "primary",
                  // onClick: props.togglerSave,
                }}
              >
                SaveChanges
              </Btn>
            </ModalFooter>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default InRoomDining;
