import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import {
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  ModalFooter,
  Row,
} from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { hotelConfigSelector } from "./hotelConfigSlice";
import { editHotel, fetchHotelConfig } from "./api";
import { toast } from "react-toastify";

const PaymentText = () => {
  const dispatch = useDispatch();

  const { hotelConfigData, isLoading, status } =
    useSelector(hotelConfigSelector);

  console.log("isLoading:", isLoading);
  console.log("status:", status);
  console.log("hotelConfigData:", hotelConfigData);

  useEffect(() => {
    dispatch(fetchHotelConfig());
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: useMemo(() => {
      return hotelConfigData;
    }, [hotelConfigData]),
  });

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message ||
          "Oops.. Something went wrong Please try again later"
      );
    }
  };

  const onSubmit = (data) => {
    console.log(data);

    if (hotelConfigData) {
      dispatch(editHotel({ ...data, CB: functionCB }));
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
        <Row>
          <Col>
            <FormGroup>
              <Label>{"Payment Gateway:"}</Label>
              <select name="payment_gateway" className="form-control">
                {["fuesbox", "shift4", "elavon"].map((items, i) => (
                  <option key={i}>{items}</option>
                ))}
              </select>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Loyalty Rewards:"}</Label>
              <input
                className="form-control"
                type="number"
                name="loyaltyRewards"
                {...register("loyaltyRewards", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.loyalty_reward && "Loyalty Rewards is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Pet Charge:"}</Label>
              <input
                className="form-control"
                type="number"
                name="petCharge"
                {...register("petCharge", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.pet_charge && "Pet charge is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Advance Purchase Days:"}</Label>
              <input
                className="form-control"
                type="number"
                name="advancePurchase:"
                {...register("advancePurchase", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.advance_purchase_days &&
                  "Advance purchase days is required"}
              </span>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Label>{"Tax(%):"}</Label>
              <input
                className="form-control"
                type="number"
                name="tax"
                {...register("tax", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.tax && "Tax(%) is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Advance Purchase Code:"}</Label>
              <input
                className="form-control"
                type="text"
                name="advancePurchaseCode"
                {...register("advancePurchaseCode", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.advance_purchase_code &&
                  "Advance purchase days is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Corporate Code:"}</Label>
              <input
                className="form-control"
                type="text"
                name="corporateCode"
                {...register("corporateCode", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.corporate_code && "Corporate code is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Is Google Pay Enable:"}</Label>
              <select name="is_googlePay" className="form-control">
                <option>Yes</option>
                <option>No</option>
              </select>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>{"Is Apple Pay Enable:"}</Label>
              <select name="is_applePay" className="form-control">
                <option>Yes</option>
                <option>No</option>
              </select>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Transient Occupancy Tax:"}</Label>
              <input
                className="form-control"
                type="text"
                name="OCCTAX"
                {...register("OCCTAX", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.transient_occupancy_tax &&
                  "Transient occupancy tax is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"CA Tourism Tax:"}</Label>
              <input
                className="form-control"
                type="text"
                name="CATAX"
                {...register("CATAX", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.CA_tourism_tax && "CA tourism tax is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Living Wage Surcharge:"}</Label>
              <input
                className="form-control"
                type="text"
                name="LIVING"
                {...register("LIVING", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.living_wage_surcharge &&
                  "Living wage surcharge is required"}
              </span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>{"Sales Tax:"}</Label>
              <input
                className="form-control"
                type="text"
                name="SALETX"
                {...register("SALETX", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.sales_tax && "Sales tax is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Breakfast Package Tax:"}</Label>
              <input
                className="form-control"
                type="text"
                name="BREAKFASTPTAX"
                {...register("BREAKFASTPTAX", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.breakfast_package_tax &&
                  "breakfast package tax is required"}
              </span>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <ModalFooter>
              <Btn
                attrBtn={{
                  color: "primary",
                  // onClick: props.togglerSave,
                }}
              >
                SaveChanges
              </Btn>
            </ModalFooter>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default PaymentText;
