import React, { useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import MyDropdown from "../../../../mycomponents/MyDropdown";

const CategoryModal = ({ isOpen, ModalTitle, toggler, editData }) => {
  const [title, setTitle] = useState("Select Parent");

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <CommonModal isOpen={isOpen} title={ModalTitle} toggler={toggler}>
        <Container>
          <Form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit}
          >
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Category Name:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="inroomdining"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Display Name:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="In-Room Dining"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <FormGroup>
                  <Label>{"View Rank:"}</Label>
                  <Input
                    className="form-control"
                    type="number"
                    placeholder="1"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"View Type:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="submenu"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <FormGroup>
                  <Label>{"Parent:"}</Label>
                  <MyDropdown
                    title={title}
                    setTitle={setTitle}
                    data={[{ opt: "sxdg" }, { opt: "xdh" }, { opt: "jh" }]}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>
        </Container>
      </CommonModal>
    </>
  );
};

export default CategoryModal;
