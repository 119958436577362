import React, { Fragment, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn, H6, H4 } from "../../../../AbstractElements";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import MeetingCategoryModal from "./MeetingCategoryModal";
import { useNavigate, useParams } from "react-router";
import { useDispatch,useSelector } from "react-redux";
import { meetingCategoriesSelector } from "./meetingCategorySlice";
import { useEffect } from "react";
import { fetchMeetingCategories } from "./api";

const MeetingCategory = () => {
  const dispatch = useDispatch();
  const { meetingCategoriesData, isLoading, status} = useSelector(meetingCategoriesSelector);
  console.log( meetingCategoriesData, isLoading, status);
  
  console.log("isLoading:", isLoading);
  console.log("status:", status);
  console.log(meetingCategoriesData, 'meetingCategoriesData');


  const navigate = useNavigate();
  const params = useParams();
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    dispatch(fetchMeetingCategories());
  },[]) 


  const handleSearch = (e) => {
    e.preventDefault();

    console.log("searchText:", searchText);
  };

  const [isAdd, setIsAdd] = useState(false);
  const handleAdd = () => setIsAdd(!isAdd);

  const [isEdit, setIsEdit] = useState(false);
  const handleEdit = () => setIsEdit(!isEdit);

  const handleDelete = () => {
    console.log("click");
    let conf = window.confirm("Are you sure you want to delete this amenity?");

    console.log(conf);
  };

  const handleRoom = () => {
    navigate(`${process.env.PUBLIC_URL}/${params.hotelcode}/meeting/category/HandleRoom`);
  };

  console.log(meetingCategoriesData, 'meetingCategoriesData');


  const MeetingCategoriesRData = meetingCategoriesData?.map((v, i) => ({
    index: i + 1,
    name: v.categoryName,
    description: v.description,
    max_Capacity: v.maxCapacity,
    rate: v.rate,
    future_Day_count: v.futureDayCount,
    timeSlot: v.timeSlot,
    maintenance_date: "05/09/2023 - 05/26/2023",
    action: (
      <Container className="d-flex btn-showcase">
        <Btn
          attrBtn={{
            color: "primary",
            outline: true,
            onClick: handleEdit,
          }}
        >
          {"Edit"}
        </Btn>
        <Btn
          attrBtn={{
            color: "danger",
            outline: true,
            onClick: handleDelete,
          }}
        >
          {"Delete"}
        </Btn>
      </Container>
    ),
  }));

  const MeetingCategoriesColumns = [
    {
      name: <H6>#</H6>,
      selector: (row) => row.index,
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Name</H6>,
      selector: (row) => row.name,
      sortable: true,
      center: true,
      wrap: true,
      width: "100px",
    },
    {
      name: <H6>Description</H6>,
      selector:  (row) => row.description,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Max Capacity</H6>,
      selector: (row) => row.max_Capacity,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Rate</H6>,
      selector: (row) => row.rate,
      sortable: true,
      center: true,
      wrap: true,
      width: "100px",
    },
    {
      name: <H6>Future Day Count</H6>,
      selector: (row) => row.future_Day_count,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Action</H6>,
      selector:(row)=> row.action,
      center: true,
      wrap: true,
      width: "424px",
    },
  ];

  return (
    <Fragment>
      <MeetingCategoryModal
        isOpen={isAdd}
        ModalTitle={"Add Meeting Data"}
        toggler={handleAdd}
      />
      <MeetingCategoryModal
        isOpen={isEdit}
        ModalTitle={"Edit Meeting Data"}
        toggler={handleEdit}
      />

      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs
            parent="Meeting Configuration"
            title="Meeting Configuration"
          />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />

            <Btn
              attrBtn={{
                className: "m-r-15 m-l-15 d-block",
                color: "success",
                // outline: true,
                onClick: handleAdd,
              }}
            >
              Add Meeting Categories
            </Btn>
          </div>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab">
                  <H4>MEETING DATA</H4>
                </div>
              </Col>

              <CardBody className="p-t-0">
                <div className="order-history table-responsive">
                  <DataTable
                    pagination
                    paginationServer
                    data={MeetingCategoriesRData}
                    columns={MeetingCategoriesColumns}
                    responsive={true}

                    // progressPending={loading}
                    // progressComponent={<Loader />}
                    // paginationTotalRows={totalRows}
                    // paginationDefaultPage={currentPage}
                    // onChangeRowsPerPage={handlePerRowsChange}
                    // onChangePage={handlePageChange}
                    // searching={true}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MeetingCategory;
