import { createSlice } from "@reduxjs/toolkit";
import { fetchExploreDetails, addExploreCategory, deleteCategory, addOrEditItemInsideCategory, deleteExploreItem } from "./api";

const initialState = {
  exploreDetailsData: {},
  exploreDetailsAfterAdding: {},
  exploreDetailsAfterDeletion: {},
  isLoading: false,
  status: {
    apiMessage: "",
    apiSuccess: true,
  },
};

const exploreDetailsSlice = createSlice({
  initialState,
  name: "fetchExploreDetails",
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchExploreDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchExploreDetails.fulfilled, (state, action) => {
        // console.log("fetchExploreDetails fulfilled:", action.payload);

        state.isLoading = false;
        state.exploreDetailsData = action.payload;
      })
      .addCase(fetchExploreDetails.rejected, (state, { payload }) => {
        // console.log("fetchExploreDetails rejected:", payload);

        state.isLoading = false;
        state.status = payload.status;
      })
      .addCase(addExploreCategory.pending, (state) => {
        // state.isLoading = true;
      })
      .addCase(addExploreCategory.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.exploreDetailsAfterAdding = action.payload;
      })
      .addCase(addExploreCategory.rejected, (state, { payload }) => {
        // state.isLoading = false;
        state.status = payload.status;
      })
      .addCase(deleteCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.exploreDetailsAfterDeletion = action.payload;
      })
      .addCase(deleteCategory.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })
      .addCase(addOrEditItemInsideCategory.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrEditItemInsideCategory.fulfilled, (state, action) => {
        state.isLoading = false;
        state.exploreDetailsAfterAdding = action.payload;
      })
      .addCase(addOrEditItemInsideCategory.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })
      .addCase(deleteExploreItem.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteExploreItem.fulfilled, (state, action) => {
        state.isLoading = false;
        state.exploreDetailsAfterDeletion = action.payload;
      })
      .addCase(deleteExploreItem.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })
  },
});

export default exploreDetailsSlice.reducer;
export const { setStatus } = exploreDetailsSlice.actions;
export const exploreDetailsSelector = (state) => state.fetchExploreDetails;
