import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn, H6, H5, H3, H4 } from "../../../../AbstractElements";
import ExploreDetailsModalCat from "./ExploreDetailsModalCat";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import TableHeaderComp from "../../../../mycomponents/TableHeaderComp";
import MExploreModal from "./MExploreModal";
import { useDispatch, useSelector } from "react-redux";
import { deleteExploreItem, fetchExploreDetails } from "./api";
import { exploreDetailsSelector } from "./exploreDetailsSlice";
import BarLoader from "../../../../Layout/Loader/BarLoader";
import { toast } from "react-toastify";

const ExploreDetails = () => {
  const dispatch = useDispatch();
  const { exploreDetailsData, isLoading, status } = useSelector(
    exploreDetailsSelector
  );

  const [headingData, setHeadingData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(false);
  const [isAddItem, setIsAddItem] = useState(false);
  const [isEditItem, setIsEditItem] = useState(false);
  const [editItemData, setEditItemData] = useState(false);
  const [apiResponseData, setApiResponseData] = useState({});
  const [exploreDetailsRow, setExploreDetailsRow] = useState([]);

  useEffect(() => {
    dispatch(fetchExploreDetails());
  }, []);

  useEffect(() => {
    if(exploreDetailsData.success) {
      setApiResponseData(exploreDetailsData);
    }
  }, [exploreDetailsData]);

  useEffect(() => {
    setHeadingData(apiResponseData.result?.data?.map(obj => ({
      title: obj.name.toUpperCase()
    })) ?? []);

    let exploreDetails = apiResponseData?.result?.data[activeTab]?.items
      ?.filter((val, i) => {
        return (
          val.title.includes(searchText) ||
          val.title.toLowerCase().includes(searchText.toLowerCase()) ||
          val.description.toLowerCase().includes(searchText.toLowerCase())
        );
      })
      .map((val, i) => ({
        index: i + 1,
        title: val.title,
        order: val.index,
        description: val.description,
        action: (
          <Container className="d-flex btn-showcase">
            <Btn
              attrBtn={{
                color: "primary",
                outline: true,
                onClick: () => handleEditItem(val),
              }}
            >
              {"Edit"}
            </Btn>
            <Btn
              attrBtn={{
                color: "danger",
                outline: true,
                onClick: () => handleDeleteItem(val._id),
              }}
            >
              {"Delete"}
            </Btn>
          </Container>
        ),
      }));

    setExploreDetailsRow(exploreDetails);
  }, [apiResponseData, activeTab]);

  const handleSearch = (e) => {
    e.preventDefault();
  };

  const handleAdd = () => setIsAdd(!isAdd);
  const handleEdit = (data) => {
    setIsEdit(!isEdit);
    setEditData(data);
  };

  const handleAddItem = () => setIsAddItem(!isAddItem);
  const handleEditItem = (data) => {
    setIsEditItem(!isEditItem);
    setEditItemData(data);
  };

  const callbackAfterDeleteExploreItem = (data) => {
    if(data?.success) {
      setApiResponseData(data);
      return toast.success("Item deleted successfully.");
    } else {
      return toast.error("Something went wrong, please try again later");
    }
  }

  const handleDeleteItem = (itemId) => {
    let confirm = window.confirm("Are you sure that you want to delete this explore item ?");
    if(confirm && itemId) dispatch(deleteExploreItem({ id: itemId, CB: callbackAfterDeleteExploreItem }))
  };

  const ExploreDetailsColumns = [
    {
      name: <H6>#</H6>,
      selector: (row) => row.index,
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Title</H6>,
      selector: (row) => row.title,
      sortable: true,
      center: true,
      wrap: true,
      width: "277px",
    },
    {
      name: <H6>Order</H6>,
      selector: (row) => row.order,
      sortable: true,
      center: true,
      wrap: true,
      width: "177px",
    },
    {
      name: <H6>Description</H6>,
      selector: (row) => row.description,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
      width: "333px",
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <Fragment>
      {isAdd ? (
        <ExploreDetailsModalCat
          isOpen={isAdd}
          ModalTitle={"Add Category"}
          toggler={handleAdd}
          setApiResponseData={setApiResponseData}
          setActiveTab={setActiveTab}
        />
      ) : null}

      {isEdit && editData ? (
        <ExploreDetailsModalCat
          isOpen={isEdit}
          ModalTitle={"Edit Category"}
          toggler={handleEdit}
          editCategory={editData}
          setApiResponseData={setApiResponseData}
          setActiveTab={setActiveTab}
        />
      ) : null}

      {isAddItem ? (
        <MExploreModal
          isOpen={isAddItem}
          ModalTitle={"Add Item"}
          toggler={handleAddItem}
          activeTab={activeTab}
          apiResponseData={apiResponseData}
          setApiResponseData={setApiResponseData}
        />
      ) : null}

      {isEditItem && editItemData ? (
        <MExploreModal
          isOpen={isEditItem}
          ModalTitle={"Edit Item"}
          toggler={handleEditItem}
          editItemData={editItemData}
          activeTab={activeTab}
          apiResponseData={apiResponseData}
          setApiResponseData={setApiResponseData}
        />
      ) : null}

      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs parent="Explore Categories" title="Explore Categories" />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />

            <Btn
              attrBtn={{
                className: "m-r-15 m-l-15 d-block",
                color: "success",
                // outline: true,
                onClick: handleAdd,
              }}
            >
              Add Category
            </Btn>

            <Btn
              attrBtn={{
                className: "m-r-15",
                color: "primary",
                // outline: true,
                onClick: () =>
                  handleEdit(apiResponseData?.result?.data[activeTab]),
              }}
            >
              Edit Categories
            </Btn>
          </div>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab align-items-baseline">
                  <TableHeaderComp
                    headingData={headingData}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />

                  <Row className="align-items-center">
                    <Col>
                      <Btn
                        attrBtn={{
                          className: "m-r-15",
                          color: "success",
                          // outline: true,
                          onClick: handleAddItem,
                        }}
                      >
                        Add Item
                      </Btn>
                    </Col>
                  </Row>
                </div>
              </Col>

              <div className="social-tab p-t-0">
                <H3>{headingData[activeTab]?.title ?? ""}</H3>
              </div>

              <CardBody className="p-t-5">
                <div className="order-history table-responsive">
                  <DataTable
                    data={exploreDetailsRow}
                    columns={ExploreDetailsColumns}
                    responsive={true}
                    pagination
                    paginationComponentOptions={paginationOptions}
                    subHeaderAlign="center"
                    progressPending={isLoading}
                    progressComponent={<BarLoader />}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ExploreDetails;
