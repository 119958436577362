import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { categoriesSelector } from '../categoriesSlice';
import { ServicesDetails } from './DetailsComponents/ServicesDetails';

const ServicesCategory = ({ searchText }) => {
  const { categoryComponentsData } = useSelector(categoriesSelector);
  let servicesData = JSON.parse(JSON.stringify(categoryComponentsData?.component?.Services ?? []));
  servicesData = servicesData.filter(obj => obj.name.toLowerCase().includes(searchText.toLowerCase()));

  return (
    <Fragment>
      <ServicesDetails servicesData={servicesData} searchText={searchText} />
    </Fragment>
  )
}

export default ServicesCategory;