import axios from "axios";
import React, { useEffect, useState } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { config } from "../../../../config";
import { Loader } from "react-feather";

const PackageImagesInput = ({
  allImages,
  setImageFiles,
  isUploading,
  setIsUploading,
  inputLabel,
  imagesLabel
}) => {
  const [numberOfImages, setNumberOfImages] = useState([]);
  const [previewArray, setPreviewArray] = useState([]);

  useEffect(() => {
    if (allImages?.length) {
      setPreviewArray(allImages);
    } else setPreviewArray([]);
  }, [allImages]);

  const handleImageChange = async (e) => {
    setIsUploading(true);
    let img = Array.from(e.target.files);

    if (img?.length) {
      setNumberOfImages(img.map((obj, index) => index + 1));

      let formData = new FormData();

      for (let k = 0; k < img.length; k++) {
        formData.append("images", img[k]);
      }

      await axios.post(config.reservationBackend, formData)
        .then((result) => {
          if (result.data?.result?.urls && Array.isArray(result.data.result.urls)) {
            let selectedImgages = [...allImages, ...result.data.result.urls];
            setImageFiles(selectedImgages);
          }
        }).catch((err) => {
          console.error("Error in image upload: ", err.message);
        });
    }
    setIsUploading(false);
    setNumberOfImages([]);
  };

  const handleRemove = (removedImageUrl) => {
    let prevUrls = [...allImages];
    prevUrls = prevUrls.filter((url) => url !== removedImageUrl);
    setImageFiles(prevUrls);
  };

  return (
    <>
      <Row className="d-flex justify-content-between">
        <Col className="col-sm-6">
          <FormGroup>
            <Label>{inputLabel}</Label>
            <div className="d-flex align-items-center">
              <input
                className="form-control"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
                multiple
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        {previewArray?.length ? (
          <Label className="f-16 d-block">{imagesLabel}</Label>
        ) : null}
        {previewArray?.map((url) => (
          <Col key={url} className="col-sm-6">
            <FormGroup>
              <>
                <img
                  className={allImages ? "img-prev" : "d-none"}
                  src={url}
                  alt=""
                />
                <i
                  className={
                    allImages && !isUploading
                      ? "icon-close cursor-pointer m-l-5 f-20"
                      : "d-none"
                  }
                  onClick={() => handleRemove(url)}
                ></i>
              </>
            </FormGroup>
          </Col>
        ))}
        {isUploading && numberOfImages?.length
          ? numberOfImages?.map((val) => (
              <Col key={val} className="col-sm-6">
                <div className="d-flex justify-content-center m-t-35">
                  <Loader />
                </div>
              </Col>
            ))
          : null}
      </Row>
    </>
  );
};

export default PackageImagesInput;
