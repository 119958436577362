import { createAsyncThunk } from "@reduxjs/toolkit";
import { config } from "../../../../config";
import { AXIOS_RESERVATION } from "../../../../utils/axiosInterceptors";

export const fetchAllLightOfHotel = createAsyncThunk(
  "allDeviceData/getalllightofhotel",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("allDeviceData apyload:", state);

    try {
      let { data } = await AXIOS_RESERVATION.get(`getalllightofhotel`);
      // console.log("data:", data)

      return data;
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong Please try again later",
        },
      });
    }
  }
);

export const fetchAllWifiOfHotel = createAsyncThunk(
  "allwifiofhotel/getallwifiofhotel",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("allwifiofhotel apyload:", state);

    try {
      let { data } = await AXIOS_RESERVATION.get(`getallwifiofhotel`);
      console.log("data:", data);

      return data?.result;
    } catch (err) {
      console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong Please try again later",
        },
      });
    }
  }
);

export const fetchAllThermostatOfHotel = createAsyncThunk(
  "allThermostatOfHotel/getAllThermostatOfHotel",
  async (state, { getState, rejectWithValue, dispath }) => {
    try {
      let { data } = await AXIOS_RESERVATION.get(`getHVACdata`);
      console.log("data:", data);

      return data?.result;
    } catch (err) {
      console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong Please try again later",
        },
      });
    }
  }
);

export const addAllDevices = createAsyncThunk(
  "allDevices/addAllDevices",
  async (state, { rejectWithValue, dispatch }) => {
    let { CB, activeTab, ...others } = state;
    let apiEndPoint = "";
    let fetchAddedData = () => {};

    if (activeTab === 0) {
      apiEndPoint = "addnewlight";
      fetchAddedData = fetchAllLightOfHotel;
    } else if (activeTab === 1) {
      apiEndPoint = "addnewwifi";
      fetchAddedData = fetchAllWifiOfHotel;
    }else {
      console.error("Unexpected value for activeTab:", activeTab);
    }

    others.hotelId = config.HOTELID;

    var headers = {
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.post(`${apiEndPoint}`, others, {
        headers,
      });

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchAddedData());

        return data;
      }
    } catch (err) {
      CB && CB(err);
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const editAllDevices = createAsyncThunk(
  "allDevices/editAllDevices",
  async (state, { getState, rejectWithValue, dispatch }) => {
    let { CB, activeTab, ...others } = state;
    let apiEndPoint = "";
    let fetchEditedData = () => {};

    if (activeTab === 0) {
      apiEndPoint = "editlight";
      fetchEditedData = fetchAllLightOfHotel;
    } else if (activeTab === 1) {
      apiEndPoint = "editWifi";
      fetchEditedData = fetchAllWifiOfHotel;
    }else {
      console.error("Unexpected value for activeTab:", activeTab);
    }

    try {
      let { data } = await AXIOS_RESERVATION.post(`${apiEndPoint}`, others);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchEditedData());

        return data;
      }
    } catch (err) {
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const removeAllDevices = createAsyncThunk(
  "allDevices/removeAllDevices",
  async (state, { getState, rejectWithValue, dispatch }) => {
    let { CB, activeTab, id } = state;
    let apiEndPoint = "";
    let fetchExistingData = () => {};

    if (activeTab === 0) {
      apiEndPoint = "removelight";
      fetchExistingData = fetchAllLightOfHotel;
    } else if (activeTab === 1) {
      apiEndPoint = "removewifi";
      fetchExistingData = fetchAllWifiOfHotel;
    }else {
      console.error("Unexpected value for activeTab:", activeTab);
    }

    var headers = {
      _id: id,
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.delete(`${apiEndPoint}`, {
        headers,
      });

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchExistingData());

        return data;
      }
    } catch (err) {
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong Please try again later",
        },
      });
    }
  }
);



export const updateThermostate = createAsyncThunk(
  "allDevices/updateThermostate",
  async (state, { getState, rejectWithValue,dispatch }) => {
    let { CB } = state;
    try {
      let { data } = await AXIOS_RESERVATION.post(`updateHVACdata`);       

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
       dispatch(fetchAllThermostatOfHotel());

        return data?.result;
      }
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);