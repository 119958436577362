import React, { useEffect, useState } from "react";
import { Btn, H6 } from "../../../../AbstractElements";
import { Container } from "reactstrap";
import DataTable from "react-data-table-component";
import AllDevicesModal from "./AllDevicesModal";
import { useDispatch } from "react-redux";
import { allDeviceSelector } from "./allDeviceslice";
import { useSelector } from "react-redux";
import { fetchAllLightOfHotel, removeAllDevices } from "./api";
import BarLoader from "../../../../Layout/Loader/BarLoader";
import { toast } from "react-toastify";

const LightData = ({ activeTab, searchText }) => {
  const dispatch = useDispatch();
  const { allLightDeviceData, isLoading, status } =
    useSelector(allDeviceSelector);

  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    dispatch(fetchAllLightOfHotel());
  }, []);

  const handleEdit = (data) => {
    setIsEdit(!isEdit);
    setEditData(data);
  };

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Data successfully deleted");
    } else {
      return toast.error(
        data.result?.message || "Oops.. Something went wrong Please try again"
      );
    }
  };

  const handleDelete = (id) => {
    let conf = window.confirm(
      "Are you sure you want to delete this light data?"
    );

    if (conf) {
      dispatch(removeAllDevices({ id, CB: functionCB, activeTab }));
    }
  };

  const LightRData = allLightDeviceData?.result?.data
    ?.filter((val, i) => {
      return (
        val &&
        Object.values(val).some((field) => {
          if (typeof field === "string") {
            return field.toLowerCase().includes(searchText.toLowerCase());
          }
          return false;
        })
      );
    })
    .map((val, i) => {
      if (val) {
        return {
          name: val.name,
          roomNo: val.roomNo,
          lightType: val.lightType,
          ruckusIp: val.ruckusIp,
          lightMAC: val.lightMAC,
          version: val.version,
          lightCategoryId: val.lightCategoryId,
          action: (
            <Container className="d-flex btn-showcase">
              <Btn
                attrBtn={{
                  color: "primary",
                  outline: true,
                  onClick: () => handleEdit(val),
                }}
              >
                {"Edit"}
              </Btn>
              <Btn
                attrBtn={{
                  color: "danger",
                  outline: true,
                  onClick: () => handleDelete(val._id),
                }}
              >
                {"Delete"}
              </Btn>
            </Container>
          ),
        };
      } else {
        return null;
      }
    });

  const LightColumns = [
    {
      name: <H6>#</H6>,
      selector: (row, index) => (currentPage - 1) * perPage + (index + 1),
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Name</H6>,
      selector: (row) => row.name,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Room Number</H6>,
      selector: (row) => row.roomNo,
      sortable: true,
      center: true,
      wrap: true,
      width: "120px",
    },
    {
      name: <H6>Light Type</H6>,
      selector: (row) => row.lightType,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>RuckusIp</H6>,
      selector: (row) => row.ruckusIp,
      sortable: true,
      center: true,
      wrap: true,
      width: "120px",
    },
    {
      name: <H6>MAC</H6>,
      selector: (row) => row.lightMAC,
      sortable: true,
      center: true,
      wrap: true,
      width: "120px",
    },
    {
      name: <H6>Version</H6>,
      selector: (row) => row.version,
      sortable: true,
      center: true,
      wrap: true,
      width: "120px",
    },
    {
      name: <H6>CategoryId</H6>,
      selector: (row) => row.lightCategoryId,
      sortable: true,
      center: true,
      wrap: true,
      width: "150px",
    },
    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
      width: "555px",
    },
  ];
  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  return (
    <>
      {isEdit && editData ? (
        <AllDevicesModal
          isOpen={isEdit}
          ModalTitle={"Light | 7c:BO:3E"}
          toggler={handleEdit}
          activeTab={activeTab}
          isEdit={isEdit}
          editData={editData}
        />
      ) : null}

      <DataTable
        data={LightRData}
        columns={LightColumns}
        responsive={true}
        pagination
        paginationComponentOptions={paginationOptions}
        subHeaderAlign="center"
        progressPending={isLoading}
        progressComponent={<BarLoader />}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerRowsChange}
      />
    </>
  );
};

export default LightData;
