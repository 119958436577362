import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_RESERVATION } from "../../../utils/axiosInterceptors";
import { config } from "../../../config";

export const fetchAppVersions = createAsyncThunk(
    "appversions/getappversions",
    async (state, { getState, rejectWithValue, dispatch }) => {
        try {
            let { data } = await AXIOS_RESERVATION.get(`getappversions`);
            return data;
        } catch (err) {
            throw rejectWithValue({
                status: {
                    apiSuccess: false,
                    apiMessage:
                        err?.result?.message ||
                        "Something went wrong Please try again try again later",
                },
            });
        }
    }
);

export const addappversions = createAsyncThunk(
    "appversions/addappversions",
    async (state, { getState, rejectWithValue, dispatch }) => {

        let { CB, ...others } = state;
        others.hotelId = config.HOTELID;

        var headers = {
            "Access-Control-Allow-Origin": "*",
        };

        try {
            let { data } = await AXIOS_RESERVATION.post(`addappversions`, others, {
                headers,
            });

            if (data?.success == false) {
                CB && CB(data);
            } else {
                CB && CB(data);
                dispatch(fetchAppVersions());

                return data;
            }
        } catch (err) {

            CB && CB(err);
            throw rejectWithValue({
                status: {
                    apiSuccess: false,
                    apiMessage:
                        err?.result?.message ||
                        "Something went wrong Please try again later",
                },
            });
        }
    }
);

export const editappversions = createAsyncThunk(
    "appversions/editappversions",
    async (state, { getState, rejectWithValue, dispatch }) => {

        let { CB, ...others } = state;

        try {
            let { data } = await AXIOS_RESERVATION.post(`editappversions`, others);

            if (data?.success == false) {
                CB && CB(data);
            } else {
                CB && CB(data);
                dispatch(fetchAppVersions());

                return data;
            }
        } catch (err) {
            throw rejectWithValue({
                status: {
                    apiSuccess: false,
                    apiMessage:
                        err?.result?.message ||
                        "Something went wrong Please try again later",
                },
            });
        }
    }
);

export const deleteappversions = createAsyncThunk(
    "appversions/deleteappversions",
    async (state, { getState, rejectWithValue, dispatch }) => {

        let { id, CB } = state;

        var headers = {
            _id: id,
            "Access-Control_Allow-Origin": "*",
        };

        try {
            let { data } = await AXIOS_RESERVATION.delete(`deleteappversions`, {
                headers,
            });

            if (data?.success == false) {
                CB && CB(data);
            } else {
                CB && CB(data);
                dispatch(fetchAppVersions());

                return data;
            }
        } catch (err) {

            throw rejectWithValue({
                status: {
                    apiSuccess: false,
                    apiMessage:
                        err?.result?.message ||
                        "Something went wrong please try again later",
                },
            });
        }
    }
);
