import React, { memo } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { Close, SaveChanges } from "../../../../Constant/index";

const CommonModal = (props) => {
  return (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggler}
      size={props.size}
      centered
    >
      <ModalHeader toggle={props.toggler}>{props.title}</ModalHeader>
      <ModalBody className={props.bodyClass}>{props.children}</ModalBody>
      <ModalFooter>
        <Btn
          attrBtn={{ color: "primary", outline: true, onClick: props.toggler }}
        >
          {Close}
        </Btn>

        {/* Only for Explore Category Section */}
        {props.explrCat ? (
          <Btn
            attrBtn={{
              color: "danger",
              outline: true,
              onClick: props.togglerDelete,
            }}
          >
            Delete Category
          </Btn>
        ) : null}
        {/*  */}

        <Btn
          attrBtn={{
            color: "primary",
            onClick: props.togglerSave,
            disabled: props.isUploading,
          }}
        >
          {SaveChanges}
        </Btn>
      </ModalFooter>
    </Modal>
  );
};

export default memo(CommonModal);
