import { Nav, NavItem } from "reactstrap";
import { Link } from "react-router-dom";
import React, { Fragment } from "react";

export const ServicesNavTab = ({ servicesTabs, currentNavItemLink, setCurrentNavItemLink }) => {
  return (
    <Fragment>
      <Nav className="email-wrap main-menu mt-3" role="tablist">
        {servicesTabs.map((obj, index) =>
          (<NavItem key={index}>
              <Link
                  to={""}
                  onClick={(e) => {
                      e.preventDefault();
                      setCurrentNavItemLink(obj.name);
                  }}
                  style={currentNavItemLink === obj.name ? { backgroundColor: "#d762e71a" } : {}}
              >
                  <span className="title">{obj.name}</span>
              </Link>
          </NavItem>)
        )}
      </Nav>
    </Fragment>
  );
};