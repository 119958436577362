import React, { Fragment, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Breadcrumbs, Btn, H6, H4, H5 } from "../../../../AbstractElements";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import TableHeaderComp from "../../../../mycomponents/TableHeaderComp";

import AllDevicesModal from "./AllDevicesModal";

import LightData from "./LightData";
import WifiData from "./WifiData";
import ThermostatData from "./ThermostatData";
import { useDispatch } from "react-redux";
import { updateThermostate } from "./api";
import { toast } from "react-toastify";

const AllDevices = () => {

  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(0);

  const [searchText, setSearchText] = useState("");

  const [isAdd, setIsAdd] = useState(false);
  const handleAdd = () => setIsAdd(!isAdd);

  const handleSearch = (e) => {
    e.preventDefault();

    console.log("searchText:", searchText);
  };

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message || "Something went wrong. Please try again laetr."
      );
    }
  };

  const handleUpdateThermo = () => {
    dispatch(updateThermostate({CB: functionCB}));
  };

  const headingData = [
    { title: "LIGHT DATA" },
    { title: "WIFI DATA" },
    { title: "THERMOSTAT DATA" },
  ];

  return (
    <Fragment>
      <AllDevicesModal
        isOpen={isAdd}
        ModalTitle={
          activeTab === 0
            ? "Add Light"
            : activeTab === 1
            ? "Add Wi-Fi"
            : activeTab === 2
            ? "Add Thermostat"
            : null
        }
        toggler={handleAdd}
        activeTab={activeTab}
      />

      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs
            parent="Device Configuration"
            title="Device Configuration"
          />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />

            {activeTab !== 2 ? (
              <Btn
                attrBtn={{
                  className: "m-r-15 m-l-15 d-block text-capitalize",
                  color: "success",
                  // outline: true,
                  onClick: handleAdd,
                }}
              >
                Add {headingData[activeTab].title.toLocaleLowerCase()}
              </Btn>
            ) : (
              <></>
            )}
          </div>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab align-items-baseline">
                  <TableHeaderComp
                    headingData={headingData}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                  {activeTab === 2 ? (
                    <Btn
                      attrBtn={{
                        color: "primary",
                        onClick: () => handleUpdateThermo(),
                      }}
                    >
                      {"Update"}
                    </Btn>
                  ) : (
                    <></>
                  )}
                </div>
              </Col>

              <div className="social-tab p-t-0">
                {/* <H3>{headingData[activeTab].title}</H3> */}
              </div>

              <CardBody className="p-t-15">
                <div className="order-history table-responsive">
                  {activeTab === 0 ? (
                    <LightData activeTab={activeTab} searchText={searchText} />
                  ) : activeTab === 1 ? (
                    <WifiData activeTab={activeTab} searchText={searchText} />
                  ) : activeTab === 2 ? (
                    <ThermostatData
                      activeTab={activeTab}
                      searchText={searchText}
                    />
                  ) : (
                    ""
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AllDevices;
