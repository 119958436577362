import React, { useState } from "react";
import { Card, CardBody, Col, Container, Nav, NavLink, Row } from "reactstrap";
import { H2, H4 } from "../../../../AbstractElements";
import { useNavigate, useParams } from "react-router";
import SubMenuComp from "./SelectComponets/SubMenuComp";
import TextboxComp from "./SelectComponets/TextboxComp";
import DropdownComp from "./SelectComponets/DropdownComp";
import IncPriceComp from "./SelectComponets/IncPriceComp";
import TextComp from "./SelectComponets/TextComp";
import LaundryComp from "./SelectComponets/LaundryComp";
import WakeupcallComp from "./SelectComponets/WakeupcallComp";
import IncImageComp from "./SelectComponets/IncImageComp";
import SubMenuCartComp from "./SelectComponets/SubMenuCartComp";
import WebViewComp from "./SelectComponets/WebViewComp";
import Fixedbtn from "./SelectComponets/Fixedbtn";
import IncComp from "./SelectComponets/IncComp";
import ButtonComp from "./SelectComponets/ButtonComp";
import LeftSelectCategory from "./LeftSelectCategory";

const CategoryCompAdd = () => {
  const navigate = useNavigate();
  const params = useParams()

  const [activeTab, setActiveTab] = useState(0);
  console.log("activeTab:", activeTab);

  const listData = [
    { name: "Submenu" },
    { name: "Textbox" },
    { name: "Dropdown" },
    { name: "Increment with price" },
    { name: "Text" },
    { name: "Laundry" },
    { name: "Wake up call" },
    { name: "Increment image without request" },
    { name: "Button" },
    { name: "Increment" },
    { name: "Submenu with cart" },
    { name: "Webview" },
    { name: "Fixedbutton" },
  ];

  return (
    <>
      <Container fluid={true}>
        <div className="social-tab" style={{ paddingLeft: "0" }}>
          <div className="d-flex align-items-center justify-content-start">
            <H2 className="m-l-15">Category-Component Configuration</H2>

            <Nav
              className="breadcrumb m-b-5"
              style={{ color: "rgb(255, 0, 148)" }}
            >
              <NavLink
                className="breadcrumb-item p-r-0"
                style={{ color: "rgb(255, 0, 148)", cursor: "pointer" }}
                onClick={() =>
                  navigate(
                    `${process.env.PUBLIC_URL}/${params.hotelcode}/in_room_services/category_component`
                  )
                }
              >
                In Room Services
              </NavLink>
              <NavLink className="breadcrumb-item active">
                Add Category Component
              </NavLink>
            </Nav>
          </div>
        </div>

        <>
          <div className="email-wrap bookmark-wrap">
            <Row>
              <Col xl="3" className="box-col-3 xl-30">
                <div className="md-sidebar">
                  <div className={"md-sidebar-aside open"}>
                    <Card>
                      <CardBody>
                        <div className="email-app-sidebar left-bookmark custom-scrollbar">
                          <LeftSelectCategory
                            title={"Components"}
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            listData={listData}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </Col>
              <Col xl="9" md="12" className="box-col-9 xl-70">
                <div className="email-right-aside bookmark-tabcontent">
                  <Card className="email-body radius-left">
                    <div className="social-tab p-b-0">
                      <H4> {listData[activeTab].name} </H4>
                    </div>
                    <hr />

                    <CardBody className="p-t-0">
                      <div className="ps-0">
                        {activeTab == 0 ? (
                          <SubMenuComp />
                        ) : activeTab == 1 ? (
                          <TextboxComp />
                        ) : activeTab == 2 ? (
                          <DropdownComp />
                        ) : activeTab == 3 ? (
                          <IncPriceComp />
                        ) : activeTab == 4 ? (
                          <TextComp />
                        ) : activeTab == 5 ? (
                          <LaundryComp />
                        ) : activeTab == 6 ? (
                          <WakeupcallComp />
                        ) : activeTab == 7 ? (
                          <IncImageComp />
                        ) : activeTab == 8 ? (
                          <ButtonComp />
                        ) : activeTab == 9 ? (
                          <IncComp />
                        ) : activeTab == 10 ? (
                          <SubMenuCartComp />
                        ) : activeTab == 11 ? (
                          <WebViewComp />
                        ) : activeTab == 12 ? (
                          <Fixedbtn />
                        ) : (
                          ""
                        )}
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </Col>
            </Row>
          </div>
        </>
      </Container>
    </>
  );
};

export default CategoryCompAdd;
