import { createSlice } from '@reduxjs/toolkit';
import {
  edithotel,
  fetchLoyaltyConfig,
  fetchRatePlansSourceCodesAndFloors,
} from './api';

const initialState = {
  loyaltyConfigData: {},
  ratePlansSourceCodesAndFloorsData: {},
  isLoading: false,
  status: {
    apiMessage: '',
    apiSuccess: true,
  },
};

const loyaltyConfigSlice = createSlice({
  initialState,
  name: 'fetchLoyaltyConfig',
  name2: 'fetchRatePlansSourceCodesAndFloors',
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },

  // CASE for fetch GetHotel

  extraReducers: (builder) => {
    builder
      .addCase(fetchLoyaltyConfig.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLoyaltyConfig.fulfilled, (state, action) => {
        state.isLoading = false;
        state.loyaltyConfigData = action.payload;
      })
      .addCase(fetchLoyaltyConfig.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR EDIT HOTELS
      .addCase(edithotel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(edithotel.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(edithotel.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE for fetch ratePlansSouceCodesAndFloors

      .addCase(fetchRatePlansSourceCodesAndFloors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(
        fetchRatePlansSourceCodesAndFloors.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.ratePlansSourceCodesAndFloorsData = action.payload;
        }
      )
      .addCase(
        fetchRatePlansSourceCodesAndFloors.rejected,
        (state, { payload }) => {
          state.isLoading = false;
          state.status = payload.status;
        }
      );
  },
});

export default loyaltyConfigSlice.reducer;
export const { setStatus } = loyaltyConfigSlice.actions;
export const loyaltyConfigSelector = (state) => state.fetchLoyaltyConfig;
export const ratePlansSourceCodesAndFloorsSelector = (state) =>
  state.fetchRatePlansSourceCodesAndFloors;
