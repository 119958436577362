import { createAsyncThunk } from "@reduxjs/toolkit";
import { config } from "../../../../config";
import { AXIOS_RESERVATION } from "../../../../utils/axiosInterceptors";

export const fetchDepartments = createAsyncThunk(
  "hotelStaff/getDepartments",
  async (state, { getState, rejectWithValue, dispatch }) => {
    try {
      let { data } = await AXIOS_RESERVATION.get(`getDepartments`);

      return data;
    } catch (err) {
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong Please try again later",
        },
      });
    }
  }
);

export const addDepartment = createAsyncThunk(
  "hotelStaff/saveDepartment",
  async (state, { getState, rejectWithValue, dispatch }) => {
    let { CB, ...others } = state;
    others.hotelId = config.HOTELID;

    console.log("others:", others);

    var headers = {
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.post(`saveDepartment`, others, {
        headers,
      });
      // console.log("data:", data);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchDepartments());

        return data;
      }
    } catch (err) {
      // console.log("err:", err);

      CB && CB(err);
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const editDepartment = createAsyncThunk(
  "hotelStaff/editDepartment",
  async (state, { getState, rejectWithValue, dispatch }) => {
    let { CB, ...others } = state;

    try {
      let { data } = await AXIOS_RESERVATION.post(`editDepartment`, others);
      // console.log("data:", data);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchDepartments());

        return data;
      }
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const removeDepartment = createAsyncThunk(
  "hotelStaff/removeDepartment",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("amenities payload:", state);

    let { id, CB } = state;
    console.log("_id:", id);

    var headers = {
      _id: id,
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.delete(`removeDepartment`, {
        headers,
      });
      // console.log("data:", data);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchDepartments());

        return data;
      }
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const fetchStaffUser = createAsyncThunk(
  "hotelStaff/getstaffuser",
  async (state, { getState, rejectWithValue, dispatch }) => {
    try {
      let { data } = await AXIOS_RESERVATION.get(`getstaffuser`);

      return data;
    } catch (err) {
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong Please try again later",
        },
      });
    }
  }
);

export const addNewStaffUser = createAsyncThunk(
  "hotelStaff/addnewstaffuser",
  async (state, { getState, rejectWithValue, dispatch }) => {
    let { CB, ...others } = state;
    others.hotelId = config.HOTELID;

    var headers = {
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.post(`addnewstaffuser`, others, {
        headers,
      });
      // console.log("data:", data);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchStaffUser());

        return data;
      }
    } catch (err) {
      // console.log("err:", err);

      CB && CB(err);
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const editStaffUser = createAsyncThunk(
  "hotelStaff/editStaff",
  async (state, { getState, rejectWithValue, dispatch }) => {
    let { CB, ...others } = state;

    try {
      let { data } = await AXIOS_RESERVATION.post(`editStaff`, others);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchStaffUser());

        return data;
      }
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const removeStaff = createAsyncThunk(
  "hotelStaff/removestaff",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("amenities payload:", state);

    let { id, CB } = state;

    var headers = {
      _id: id,
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.delete(`removestaff`, {
        headers,
      });
      // console.log("data:", data);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchStaffUser());

        return data;
      }
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);
