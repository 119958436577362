import React, { useEffect, useState } from "react";
import Loader from "../Layout/Loader";
import { checkAuthTokenAvailability } from "../utils/cookieReducer";

const Callback = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    setIsLoggedIn(checkAuthTokenAvailability());

    if (isLoggedIn) {
      // navigate("/dashboard");

      window.location.href = `${process.env.PUBLIC_URL}/hotel_config`;
    }
  });

  // useEffect(() => {
  //   if (user) {
  //     localStorage.setItem("auth0_profile", JSON.stringify(user));
  //     localStorage.setItem("authenticated", true);
  //     window.location.href = `${process.env.PUBLIC_URL}/dashboard/default`;
  //   }
  // });

  return (
    <div>
      <Loader />
    </div>
  );
};

export default Callback;
