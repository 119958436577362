import React from 'react';

const Increment = ({ quantity }) => {
  return (
    <div>
      <a
        style={{
          textDecoration: "none",
          color: "inherit",
          marginRight: "10px",
          fontWeight: "bold",
        }}
      >
        -
      </a>
      <span>{quantity}</span>
      <a
        style={{
          textDecoration: "none",
          color: "inherit",
          marginLeft: "10px",
          fontWeight: "bold",
        }}
      >
        +
      </a>
    </div>
  );
}

export default Increment;