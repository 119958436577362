export const amenitiesRoomType = [
  { opt: "PK" },
  { opt: "PKA" },
  { opt: "DKK" },
  { opt: "DKKA" },
];

export const amenitiesCategory = [
  { opt: "Bedroom" },
  { opt: "Bathroom" },
  { opt: "Environment" },
  { opt: "In-Room Technology" },
  { opt: "Other Amenities" },
  { opt: "Meeting" },
];
