import React, { useMemo } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, FormGroup, Label, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { addNewRoom, editRoom } from "./api";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { fetchRatePlans } from "../RatePlans/api";

const RoomsModal = ({ isOpen, ModalTitle, toggler, editData }) => {
  console.log("editData:", editData);
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return editData;
    }, [editData]),
  });

  const functionCB = (data) => {
    if (data.success == true) {
      toggler();
      reset();
      dispatch(fetchRatePlans());
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message ||
          "Oops.. Something went wrong Please try again later"
      );
    }
  };

  const onSubmit = (data) => {
    console.log(data);

    if (editData) {
      dispatch(editRoom({ ...data, CB: functionCB }));
    } else {
      dispatch(addNewRoom({ ...data, CB: functionCB }));
    }
  };

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={toggler}
        togglerSave={handleSubmit(onSubmit)}
      >
        <Container fluid={true} className="bd-example-row">
          <form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Room number:"}</Label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="101"
                    name="roomNo"
                    {...register("roomNo", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.roomNo && "Room number is required"}
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Description:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Beach View"
                    name="description"
                    {...register("description", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.description && "Description is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <FormGroup>
                  <Label>{"GrubHub Id:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="grubhub id"
                    name="grubHubId"
                    {...register("grubHubId")}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"GrubHub Key:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="grubhub key"
                    name="grubHubKey"
                    {...register("grubHubKey")}
                  />
                </FormGroup>
              </Col>
            </Row>
          </form>
        </Container>
      </CommonModal>
    </>
  );
};

export default RoomsModal;
