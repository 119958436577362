import React, { Fragment, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Label,
  ModalFooter,
  Row,
} from "reactstrap";
import { Breadcrumbs, Btn, H4 } from "../../../../AbstractElements";
import MyMultiSelect from "../../../../mycomponents/MyMultiSelect";
import MySelectDrop from "../../../../mycomponents/MySelectDrop";
import MySelectDropd from "../../../../mycomponents/MySelectDropd";

const CouponConfig = () => {
  const [couponEligibleSC, setCouponEligibleSC] = useState([]);
  const [couponEligibleRP, setCouponEligibleRP] = useState([]);
  console.log("couponEligibleSC:", couponEligibleSC);
  console.log("couponEligibleRP:", couponEligibleRP);

  const couponEligibleSourceCode = ["OTA", "GDS", "WEB", "MOBILE"];
  const couponEligibleRatePlan = [
    "MSOFT",
    "APPLE",
    "GOOGLE",
    "FBOOK",
    "AMAZON",
  ];

  const handleSaveChanges = () => {
    console.log("couponEligibleSC:", couponEligibleSC);
    console.log("couponEligibleRP:", couponEligibleRP);
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs
            parent="Coupon Configuration"
            title="Coupon Configuration"
          />
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab">
                  <H4>COUPON CONFIGURATION</H4>
                </div>
              </Col>

              <CardBody className="p-t-0">
                <Row>
                  <Col>
                    <Label className="f-16">
                      Coupon Eligible Source Code Options:
                    </Label>

                    <MySelectDropd
                      options={couponEligibleSourceCode}
                      name={"SCode"}
                      selectedOpt={setCouponEligibleSC}
                    />

                    {/* <div className="form-control border border-secondary rounded-1 userRoles">
                      {couponEligibleSourceCode?.map((val, i) => (
                        <>
                          <MySelectDrop
                            id={i + 1 * 3}
                            htmlFor={i + 1 * 3}
                            Label={val}
                          />
                        </>
                      ))}
                    </div> */}
                  </Col>

                  <Col>
                    <Label className="f-16">
                      Coupon Eligible Rate Plan Code Options:
                    </Label>

                    <MySelectDropd
                      options={couponEligibleRatePlan}
                      name={"RPlan"}
                      selectedOpt={setCouponEligibleRP}
                    />
                    {/* <div className="form-control border border-secondary rounded-1 userRoles">
                      {couponEligibleRatePlan?.map((val, i) => (
                        <>
                          <MySelectDrop
                            id={i * 7}
                            htmlFor={i * 7}
                            Label={val}
                          />
                        </>
                      ))}
                    </div> */}
                  </Col>
                </Row>

                <Row className="m-r-5 m-t-25">
                  <Col className="p-r-0">
                    <ModalFooter>
                      <Btn
                        attrBtn={{
                          color: "danger",
                          onClick: handleSaveChanges,
                        }}
                      >
                        Save Changes
                      </Btn>
                    </ModalFooter>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CouponConfig;
