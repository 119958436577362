import Cookies from "js-cookie";

// EXPRESION FOR USER SIGN-IN TOKEN
export const setAuthToken = (token) => {
  return Cookies.set("authToken", JSON.stringify(token));
};

export const getAuthToken = () => {
  const authToken = Cookies.get("authToken");
  if (authToken) {
    return JSON.parse(authToken);
  }
};

export const checkAuthTokenAvailability = () => {
  const token = Cookies.get("authToken");
  if (!token) return false;
  else return true;
};

// EXPRESION FOR USER PROFILE DATA
export const setAuthData = (authData) => {
  return Cookies.set("authData", JSON.stringify(authData));
};

export const getAuthData = () => {
  const authData = Cookies.get("authData");
  if (authData) {
    return JSON.parse(authData);
  }
};

// REMOVE BOTH TOKEN AND DATA
export const removeAuth = () => {
  Cookies.remove("authData");
  Cookies.remove("authToken");
};
