import React from 'react';
import { Card, CardBody } from 'reactstrap';
import { Btn, H5 } from '../../../../../src/AbstractElements';

export const IncrementComponent = ({ data }) => {
   return (
      <Card className="rounded-0 my-2">
         <CardBody className="p-3">
            <div className="d-flex p-0 m-0 justify-content-between align-items-center">
               <H5 attrH5={{ className: 'mb-0' }}>{data.name}</H5>
               <div>
                  <i className="icon-minus fw-900 mx-2"></i>
                     <span>{data.quantity}</span>
                  <i className="icon-plus mx-2"></i>
                  <Btn
                     attrBtn={{
                        color: "secondary",
                        outline: true,
                        className: "rounded-0 my-0 m-l-10"
                     }}
                  >
                     Request
                  </Btn>
               </div>
            </div>
         </CardBody>
      </Card>
   );
};