import React, { useState } from "react";
import { Col, Container, FormGroup, Input, Label, ModalFooter, Row } from "reactstrap";
import MyDropdown from "../../../../../mycomponents/MyDropdown";
import { useFieldArray, useForm } from "react-hook-form";
import { Btn } from "../../../../../AbstractElements";

const IncComp = () => {
  const [title, setTitle] = useState("Select Department");

  const { register, control, handleSubmit, reset, watch, errors } = useForm({
    defaultValues: {
      test: [],
    },
  });
  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "test",
    });

  const onSubmit = (data) => {
    console.log("data", data);
  };
  console.log("errors:", errors);

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="d-flex align-items-center">
            <Col>
              <FormGroup>
                <Label>{"Department:"}</Label>
                <MyDropdown
                  title={title}
                  setTitle={setTitle}
                  data={[
                    { opt: "Maintanance" },
                    { opt: "Front Dest" },
                    { opt: "Valet" },
                    { opt: "F&B" },
                    { opt: "Housekeeping" },
                    { opt: "Deployer" },
                    { opt: "Engineering" },
                  ]}
                />
              </FormGroup>
            </Col>
          </Row>
          <hr />

          <Row>
            <Col>
              <FormGroup>
                <Label>{"Hotel Id:"}</Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  {...register(`hotelid`, {
                    required: true,
                  })}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{"Name:"}</Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  {...register(`goto`, {
                    required: true,
                  })}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{"Amount:"}</Label>
                <input
                  className="form-control"
                  type="number"
                  placeholder=""
                  {...register(`type`, {
                    required: true,
                  })}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label>{"Max quantity per room:"}</Label>
                <input
                  className="form-control"
                  type="number"
                  placeholder=""
                  {...register(`type`, {
                    required: true,
                  })}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{"Type:"}</Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  {...register(`type`, {
                    required: true,
                  })}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{"View rank:"}</Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  {...register(`type`, {
                    required: true,
                  })}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <FormGroup>
                <Label>{"Description:"}</Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder=""
                  {...register(`type`, {
                    required: true,
                  })}
                />
                {/* <span style={{ color: "#ff5370" }}>
                  {errors.description && "Description is required"}
                </span> */}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label>{"Qualtity:"}</Label>
                <input
                  className="form-control"
                  type="number"
                  placeholder=""
                  {...register(`type`, {
                    required: true,
                  })}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <Col>
              <div className="d-flex">
                <Input
                  className="checkbox_animated"
                  id="checkbox6"
                  type="checkbox"
                  defaultChecked
                  {...register(`isInactive`, {
                    required: true,
                  })}
                />
                <Label className="d-block" htmlFor="checkbox6">
                  is-inactive?
                </Label>
              </div>
            </Col>
          </Row>

          <ModalFooter className="m-t-5">
            <Btn
              attrBtn={{
                color: "primary",
                onClick: () => {
                  handleSubmit(onSubmit);
                },
              }}
            >
              Save Changes
            </Btn>
          </ModalFooter>
        </form>
      </Container>
    </>
  );
};

export default IncComp;
