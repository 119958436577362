import React from "react";
import { Bars } from "react-loader-spinner";

export default function BarLoader() {
  return (
    <div className="d-flex justify-content-center">
      <Bars
        height="50"
        width="50"
        color="#ff0094"
        ariaLabel="bars-loading"
        wrapperStyle={{}}
        wrapperClass=""
        visible={true}
      />
    </div>
  );
}
