import { createSlice } from "@reduxjs/toolkit";
import { getCategoryComponents, getAllDepartments, addNewCategoryComponent, deleteCategoryComponent, editCategoryComponent, getAllCategories } from "./categoriesApi";

const initialState = {
    categoryComponentsData: null,
    departmentsData: null,
    isLoading: false,
    categoryComponentsDataLoading: false,
    allCategories: [],
    error: null
};

const categoriesSlice = createSlice({
    initialState,
    name: "categories",
    extraReducers: (builder) => {
        builder.addCase(getCategoryComponents.pending, (state) => {
                state.isLoading = true;
                state.categoryComponentsDataLoading = true;
                state.error = null;
            }).addCase(getCategoryComponents.fulfilled, (state, action) => {
                state.isLoading = false;
                state.categoryComponentsDataLoading = false;
                state.categoryComponentsData = action.payload?.result?.data ?? [];
                state.error = null;
            }).addCase(getCategoryComponents.rejected, (state, action) => {
                state.isLoading = false;
                state.categoryComponentsDataLoading = false;
                state.error = action.payload;
            })

            .addCase(getAllDepartments.pending, (state) => {
                state.isLoading = true;
            }).addCase(getAllDepartments.fulfilled, (state, action) => {
                state.isLoading = false;
                state.departmentsData = action.payload?.result?.data?.departmentsData ?? [];
            }).addCase(getAllDepartments.rejected, (state) => {
                state.isLoading = false;
            })

            .addCase(getAllCategories.fulfilled, (state, action) => {
                state.allCategories = action.payload?.result?.data?.allCategoriesDetails ?? [];
            })

            .addCase(addNewCategoryComponent.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            }).addCase(addNewCategoryComponent.fulfilled, (state, action) => {
                state.isLoading = false;
                state.error = null;
            }).addCase(addNewCategoryComponent.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload;
            })

            .addCase(editCategoryComponent.pending, (state) => {
                state.isLoading = true;
            }).addCase(editCategoryComponent.fulfilled, (state, action) => {
                state.isLoading = false;
            }).addCase(editCategoryComponent.rejected, (state) => {
                state.isLoading = false;
            })

            .addCase(deleteCategoryComponent.pending, (state) => {
                state.isLoading = true;
            }).addCase(deleteCategoryComponent.fulfilled, (state, action) => {
                state.isLoading = false;
            }).addCase(deleteCategoryComponent.rejected, (state) => {
                state.isLoading = false;
            })
    }
});

export default categoriesSlice.reducer;
export const categoriesSelector = (state) => state.categories;