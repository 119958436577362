import React, { useState } from "react";
import { Container, Row, Col, TabContent, TabPane } from "reactstrap";
import LoginTab from "./Tabs/LoginTab";
import { Image } from "react-bootstrap";
import LoginSample from "../Component/Pages/Auth/Login";

const Logins = () => {
  const [selected, setSelected] = useState("firebase");

  const callbackNav = (select) => {
    setSelected(select);
  };

  return (
    <Container fluid={true} className="p-0">
      {/* <LoginSample /> */}
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <div className="login-main1">
                <TabContent
                  activeTab={selected}
                  className="content-login h-100"
                >
                  <TabPane className="fade show d-flex d-flex flex-column align-items-center mb-3">
                    <Image
                      className="w-50 h-50"
                      src="https://res.cloudinary.com/shashigroup/image/upload/v1665991163/static-page/unknown.png"
                      alt="Shashi-Logo"
                    />
                  </TabPane>

                  <TabPane
                    className="fade show"
                    tabId={selected === "firebase" ? "firebase" : "jwt"}
                  >
                    <LoginTab selected={selected} />
                  </TabPane>

                  {/* <TabPane className="fade show" tabId="auth0">
                    <AuthTab />
                  </TabPane> */}
                </TabContent>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Logins;
