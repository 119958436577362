import { createSlice } from "@reduxjs/toolkit";
import {
  fetchRatePlans,
  removeRatePlanData,
  saveRatePlanData,
  updateRatePlanData,
} from "./api";

const initialState = {
  ratePlansData: {},
  isLoading: false,
  status: {
    apiMessage: "",
    apiSuccess: true,
  },
};

const ratePlansSlice = createSlice({
  initialState,
  name: "fetchRatePlans",
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchRatePlans.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRatePlans.fulfilled, (state, action) => {
        // console.log("fetchRatePlans fulfilled:", action.payload);

        state.isLoading = false;
        state.ratePlansData = action.payload;
      })
      .addCase(fetchRatePlans.rejected, (state, { payload }) => {
        // console.log("fetchRatePlans rejected:", payload);

        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR saveRatePlanData --->>>
      .addCase(saveRatePlanData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveRatePlanData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(saveRatePlanData.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR updateRatePlanData --->>>
      .addCase(updateRatePlanData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateRatePlanData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(updateRatePlanData.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR deleteRatePlanData --->>>
      .addCase(removeRatePlanData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeRatePlanData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(removeRatePlanData.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      });
  },
});

export default ratePlansSlice.reducer;
export const { setStatus } = ratePlansSlice.actions;
export const ratePlansSelector = (state) => state.fetchRatePlans;
