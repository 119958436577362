import React, { useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import { useFieldArray, useForm } from "react-hook-form";
import MyDropdown from "../../../../mycomponents/MyDropdown";
import MyMultiSelect from "../../../../mycomponents/MyMultiSelect";
import InputPrev from "../../../../mycomponents/InputPrev";
import MySelectDrop from "../../../../mycomponents/MySelectDrop";
import MySelectDropd from "../../../../mycomponents/MySelectDropd";

const MeetingCategoryModal = ({ isOpen, ModalTitle, toggler, editData }) => {
  const { register, control, handleSubmit, reset, watch, errors } = useForm({
    defaultValues: {
      test: [],
    },
  });
  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "test",
    });

  const [title, setTitle] = useState("Select Currency");
  const [selAmenities, setSelAmenities] = useState([]);
  const [selRooms, setSelRooms] = useState([]);
  console.log("selAmenities:", selAmenities);
  console.log("selRooms:", selRooms);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const onSubmit = (data) => {
    console.log("data", data);
    // toggler();
  };

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={toggler}
        size={"lg"}
      >
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Category Name:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Meeting Pods"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Category Type:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Meeting Pods"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Max Capacity:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="2-4 person"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Area:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="100 sq.ft."
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Description:"}</Label>
                  <textarea className="form-control" rows={3} placeholder="" />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="checkboxA"
                    type="checkbox"
                    defaultChecked
                  />
                  <Label className="d-block" htmlFor="checkboxA">
                    Is full Day Booking?
                  </Label>
                </div>
              </Col>
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="checkboxB"
                    type="checkbox"
                    defaultChecked
                  />
                  <Label className="d-block" htmlFor="checkboxB">
                    Is Table Layout Option?
                  </Label>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Rate:"}</Label>
                  <Input
                    className="form-control"
                    type="number"
                    placeholder="111"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Currency:"}</Label>

                  <MyDropdown
                    title={title}
                    setTitle={setTitle}
                    data={[{ opt: "USD" }, { opt: "INR" }]}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Future Day Count:"}</Label>
                  <Input
                    className="form-control"
                    type="number"
                    placeholder="111"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Time Slot:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="55"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Min Start Time Difference"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="65"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Min Maintanance Time"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="56"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Holidays:"}</Label>
                  <DatePicker
                    className="form-control digits myBorder"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      setDateRange(update);
                    }}
                    isClearable={true}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Monday Times:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="11:00 - 12:56"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Tuesday Times:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="11:00 - 12:56"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Wednesday Times:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="11:00 - 12:56"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Thursday Times:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="11:00 - 12:56"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Friday Times:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="11:00 - 12:56"
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Saturday Times:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="11:00 - 12:56"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Saturday Times:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="11:00 - 12:56"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="m-b-15">
              <Col>
                <Label className="f-16">Amenities:</Label>

                <MySelectDropd
                  options={["a", "b", "c"]}
                  name={"Amenities"}
                  selectedOpt={setSelAmenities}
                />
              </Col>

              <Col>
                <Label className="f-16">Rooms:</Label>

                <MySelectDropd
                  options={[
                    "POD-1",
                    "POD-2",
                    "POD-3",
                    "POD-4",
                    "Small Room-1",
                    "Small Room-2",
                    "Large Room",
                  ]}
                  name={"Rooms"}
                  selectedOpt={setSelRooms}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputPrev
                  inputLabel={"Change Image:"}
                  // inputLabelClass={"col-sm-6"}
                  imageLabel={"Primary Image:"}
                  // imageLabelClass={"col-sm-5"}
                  src={
                    "https://res.cloudinary.com/shashigroup/image/upload/cce5dbc5-a787-4f19-9cc7-bea103ef9110.png"
                  }
                />
              </Col>
            </Row>
          </form>
        </Container>
      </CommonModal>
    </>
  );
};

export default MeetingCategoryModal;
