import React, { useEffect, useMemo, useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { addEditOrDeleteOptionsInAddonPackage } from "./api";
import { useDispatch } from "react-redux";
import MyDropdown from "../../../../mycomponents/MyDropdown";

const PackagesOptionsModal = ({
  isOpen,
  ModalTitle,
  toggler,
  editOptionData,
  id
}) => {
  const dispatch = useDispatch();

  const [isCostPerDay, setIsCostPerDay] = useState(false);
  const [isSelected, setIsSelected] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [voucherRequired, setVoucherRequired] = useState(false);
  const [canUpgrade, setCanUpgrade] = useState("Select Option");
  const [containECI, setContainECI] = useState("Select Option");

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return editOptionData;
    }, [editOptionData]),
  });

  useEffect(() => {
    setValue("isCostPerDay", isCostPerDay);
    setValue("isSelected", isSelected);
    setValue("voucherRequired", voucherRequired);
    setValue("canUpgrade", canUpgrade);
    setValue("containECI", containECI);
  }, [isCostPerDay, isSelected, voucherRequired, canUpgrade, containECI]);

  useEffect(() => {
    if (editOptionData) {
      setIsCostPerDay(editOptionData.isCostPerDay);
      setIsSelected(editOptionData.isSelected);
      setVoucherRequired(editOptionData.voucherRequired);
      setCanUpgrade(editOptionData.canUpgrade == true ? "Yes" : editOptionData.canUpgrade == false ? "No" : "Select Option");
      setContainECI(editOptionData.containECI == true ? "Yes" : editOptionData.containECI == false ? "No" : "Select Option");
      if(editOptionData.ECILCO?.earlyCheckIn) setValue("earlyCheckIn", editOptionData.ECILCO?.earlyCheckIn?.slice(0, 5));
      if(editOptionData.ECILCO?.lateCheckOut) setValue("lateCheckOut", editOptionData.ECILCO?.lateCheckOut?.slice(0, 5));
    }
  }, [editOptionData]);

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data?.message ||
        "Something went wrong. Please try again later."
      );
    } else {
      return toast.error(data?.response?.data?.message || "Something went wrong. Please try again later");
    }
  };

  const onSubmit = (data) => {
    if(data.canUpgrade == "Yes") data.canUpgrade = true;
    else if(data.canUpgrade == "No") data.canUpgrade = false;
    else delete data.canUpgrade;

    if(data.containECI == "Yes") data.containECI = true;
    else if(data.containECI == "No") data.containECI = false;
    else delete data.containECI;

    if(data.earlyCheckIn || data.lateCheckOut) {
      data.ECILCO = {};
      if(data.earlyCheckIn) data.ECILCO.earlyCheckIn = data.earlyCheckIn + ":00";
      if(data.lateCheckOut) data.ECILCO.lateCheckOut = data.lateCheckOut + ":00";
    }
    delete data.earlyCheckIn;
    delete data.lateCheckOut;

    if (data.description)
      data.description = data.description
        ?.split("\n")
        ?.map((line) => {
          if (line?.[0] == "+") return line;
          else return `+ ${line}`;
        })
        ?.join("\n");

    toggler();
    setIsUploading(true);
    let payload = {
      ...data,
      amount: parseInt(data.amount),
      id
    };

    if (editOptionData) {
      dispatch(
        addEditOrDeleteOptionsInAddonPackage({
          ...payload,
          isEditRequest: true, // to differentiate edit or not from inside the API
          CB: functionCB,
        })
      );
    } else {
      dispatch(addEditOrDeleteOptionsInAddonPackage({ ...payload, CB: functionCB }));
    }

    reset();
    setIsCostPerDay(false);
    setIsSelected(false);
    setVoucherRequired(false);
  };

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={toggler}
        togglerSave={handleSubmit(onSubmit)}
        isUploading={isUploading}
      >
        <Container fluid={true} className="bd-example-row">
          <form className="needs-validation" noValidate="">
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Package Name"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="packageName"
                    {...register("packageName", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.packageName && "Package name is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Title"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="title"
                    {...register("title", { required: true, maxLength: 5 })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.title?.type == "required" && "Title is required"}
                    {errors.title?.type == "maxLength" && "Only upto 5 characters allowed"}
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Subtitle"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="subtitle"
                    {...register("subtitle", { maxLength: 6 })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.title?.type == "maxLength" && "Only upto 6 characters allowed"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Item ID"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="itemId"
                    {...register("itemId", { required: true })}
                    disabled={editOptionData ? true : false}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.itemId && "Item ID is required"}
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Amount"}</Label>
                  <input
                    className="form-control"
                    type="number"
                    name="amount"
                    placeholder="100"
                    {...register("amount", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.amount && "Amount is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Tag"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="tag"
                    placeholder="$100"
                    {...register("tag", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.tag && "Tag is required"}
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Value"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="value"
                    {...register("value", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.value && "Value is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Type"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="type"
                    {...register("type", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.type && "Type is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Description ( Add in multiple lines )"}</Label>
                  <textarea
                    className="form-control"
                    name="description"
                    {...register("description")}
                    rows={4} // Specify the number of rows to display
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm="6" col="9" className="btn-showcase">
                <FormGroup>
                  <Label>{"Can Upgrade ?"}</Label>
                  <MyDropdown
                    title={canUpgrade}
                    setTitle={setCanUpgrade}
                    data={[{ opt: "Yes" }, { opt: "No" }]}
                  />
                </FormGroup>
              </Col>
              <Col sm="6" col="9" className="btn-showcase">
                <FormGroup>
                  <Label>{"Contain ECI ?"}</Label>
                  <MyDropdown
                    title={containECI}
                    setTitle={setContainECI}
                    data={[{ opt: "Yes" }, { opt: "No" }]}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col sm="6" col="9" className="btn-showcase">
                <FormGroup>
                  <Label>{"Early Checkin Time"}</Label>
                  <input
                    className="form-control"
                    type="time"
                    name="earlyCheckIn"
                    {...register("earlyCheckIn")}
                  />
                </FormGroup>
              </Col>
              <Col sm="6" col="9" className="btn-showcase">
                <FormGroup>
                  <Label>{"Late Checkout Time"}</Label>
                  <input
                    className="form-control"
                    type="time"
                    name="lateCheckOut"
                    {...register("lateCheckOut")}
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="isCostPerDay"
                    name="isCostPerDay"
                    type="checkbox"
                    checked={isCostPerDay}
                    onChange={(e) => setIsCostPerDay(e.target.checked)}
                  />
                  <Label className="d-block" htmlFor="isCostPerDay">
                    Is cost per day ?
                  </Label>
                </div>
              </Col>

              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="isSelected"
                    name="isSelected"
                    type="checkbox"
                    checked={isSelected}
                    onChange={(e) => setIsSelected(e.target.checked)}
                  />
                  <Label className="d-block" htmlFor="isSelected">
                    Is selected?
                  </Label>
                </div>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="voucherRequired"
                    name="voucherRequired"
                    type="checkbox"
                    checked={voucherRequired}
                    onChange={(e) => setVoucherRequired(e.target.checked)}
                  />
                  <Label className="d-block" htmlFor="voucherRequired">
                    Voucher required ?
                  </Label>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </CommonModal>
    </>
  );
};

export default PackagesOptionsModal;
