import { createSlice } from "@reduxjs/toolkit";
import { fetchDining, saveDiningData } from "./api";

const initialState = {
  diningData: {},
  isLoading: false,
  status: {
    apiMessage: "",
    apiSuccess: true,
  },
};

const diningSlice = createSlice({
  initialState,
  name: "fetchDining",
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchDining.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDining.fulfilled, (state, action) => {
        // console.log("dining fulfilled:", action.payload);

        state.isLoading = false;
        state.diningData = action.payload;
      })
      .addCase(fetchDining.rejected, (state, { payload }) => {
        // console.log("dining rejected:", payload);

        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR ADD DINING DATA --->>>
      .addCase(saveDiningData.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveDiningData.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(saveDiningData.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      });
  },
});

export default diningSlice.reducer;
export const { setStatus } = diningSlice.actions;
export const diningSelector = (state) => state.fetchDining;
