import React, { memo, useEffect, useMemo, useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import MyDropdown from "../../../../mycomponents/MyDropdown";
import InputPrev from "../../../../mycomponents/InputPrev";
import { useForm } from "react-hook-form";
import { amenitiesCategory, amenitiesRoomType } from "./AmenitiesData";
import { useDispatch } from "react-redux";
import { addAmenities, editAmenities } from "./api";
import { toast } from "react-toastify";

const AmenitiesModal = ({ isOpen, ModalTitle, toggler, editData }) => {
  console.log("editData:", editData);
  const dispatch = useDispatch();

  const [title, setTitle] = useState("Select Room Type");
  const [titleB, setTitleB] = useState("Other Amenities");
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return editData;
    }, [editData]),
  });
  
  // console.log(
  //   "🚀 ~ file: AmenitiesModal.jsx:32 ~ AmenitiesModal ~ editData:",
  //   editData
  // );

  useEffect(() => {
    setValue("code", title);
    setValue("category", titleB);
    setValue("isHighlighted", isHighlighted);
    setValue("image", imageFile);
  }, [title, titleB, isHighlighted, imageFile]);

  useEffect(() => {
    if (editData) {
      setTitle(editData?.code);
      setTitleB(editData?.category);
      setIsHighlighted(editData?.isHighlighted);
      setImageFile(editData?.image);
    }
  }, [editData]);

  const functionCB = (data) => {
    if (data.success == true) {
      toggler();
      reset();
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message || "Oops.. Something went wrong Please try again"
      );
    }
  };

  const onSubmit = (data) => {
    console.log(data);

    if (editData) {
      // console.log("edit");
      dispatch(editAmenities({ ...data, CB: functionCB }));
    } else {
      // console.log("add");
      dispatch(addAmenities({ ...data, CB: functionCB }));
    }
  };

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={toggler}
        togglerSave={handleSubmit(onSubmit)}
        isUploading={isUploading}
      >
        <Container fluid={true} className="bd-example-row">
          <form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Name:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Cafe"
                    name="name"
                    {...register("name", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.name && "Name is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col className="btn-showcase">
                <FormGroup>
                  <Label>{"Select Room Type:"}</Label>
                  <MyDropdown
                    title={title}
                    setTitle={setTitle}
                    data={amenitiesRoomType}
                  />
                </FormGroup>
              </Col>
              <Col className="btn-showcase">
                <FormGroup>
                  <Label>{"Select Category:"}</Label>
                  <MyDropdown
                    title={titleB}
                    setTitle={setTitleB}
                    data={amenitiesCategory}
                  />
                </FormGroup>
              </Col>
            </Row>

            <InputPrev
              inputLabel={"Change Image:"}
              inputLabelClass={"col-sm-6"}
              imageLabel={"Primary Image:"}
              // imageLabelClass={"col-sm-5"}
              src={imageFile}
              setImageFile={setImageFile}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
            />

            <Row className="mb-2">
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="isHighlighted"
                    name="isHighlighted"
                    type="checkbox"
                    checked={isHighlighted}
                    onChange={(e) => setIsHighlighted(e.target.checked)}
                  />
                  <Label className="d-block" htmlFor="isHighlighted">
                    isHighlighted?
                  </Label>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </CommonModal>
    </>
  );
};

export default memo(AmenitiesModal);
