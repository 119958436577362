import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_HOSPITALITY } from "../../../../utils/axiosInterceptors";
import moment from "moment-timezone";

export const getLoyaltyReports = createAsyncThunk(
  "loyaltyReports/getLoyaltyReports",
  async (state, { rejectWithValue }) => {
    try {
      let startDate, endDate;
      if(state.firstLoad) {
        startDate = state.startDate;
        endDate = state.endDate;
      } else {
        startDate = moment(state.startDate).tz("US/Pacific").startOf("day");
        endDate = moment(state.endDate).tz("US/Pacific").endOf("day");
      }
      let { data } = await AXIOS_HOSPITALITY.get(`getLoyaltyReport?startDate=${startDate}&endDate=${endDate}`);
      return data;
    } catch (err) {
      let { CB } = state;
      CB && CB(false);
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage: err?.result?.message || "Something went wrong, Please try again later."
        }
      });
    }
  }
);