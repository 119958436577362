import React from "react";
import { Link, useParams } from "react-router-dom";
import { Image } from "../../AbstractElements";
import logo from "../../assets/images/logo/logo-icon.png";

const SidebarLogo = () => {
  const params = useParams()
  return (
    <div className="logo-icon-wrapper">
      <Link to={`${process.env.PUBLIC_URL}/${params.hotelcode}/hotel_config`}>
        <Image
          attrImage={{
            className: "img-fluid for-dark",
            src: `${logo}`,
            alt: "",
          }}
        />
      </Link>
    </div>
  );
};

export default SidebarLogo;
