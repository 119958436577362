import React, { Fragment, useState } from "react";
import { CardBody, Container } from "reactstrap";
import DataTable from "react-data-table-component";
import { Btn, H6, H4 } from "../../../../AbstractElements";
import { useDispatch } from "react-redux";
import { addEditOrDeleteOptionsInAddonPackage } from "./api";
import BarLoader from "../../../../Layout/Loader/BarLoader";
import { toast } from "react-toastify";
import PackagesOptionsModal from "./PackagesOptionsModal";

const Packages = ({ listData, isLoading, packageTitle }) => {
  const dispatch = useDispatch();

  const [isEditOption, setIsEditOption] = useState(false);
  const [isAddOption, setIsAddOption] = useState(false);
  const [editOptionData, setIsEditOptionData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  const handleAddOption = () => {
    setIsAddOption(!isAddOption)
  };

  const handleEditOption = (data) => {
    setIsEditOption(!isEditOption);
    setIsEditOptionData({ ...data });
  };

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.message || "Successful");
    } else {
      return toast.error(
        data.message ||
        "Something went wrong Please try again later"
      );
    }
  };

  const handleDelete = (id, itemId) => {
    let conf = window.confirm("Are you sure that you want to delete this option ?");

    if (conf) {
      dispatch(addEditOrDeleteOptionsInAddonPackage({ id, itemId, isDeleteRequest: true, CB: functionCB }));
    }
  };

  let rowDatas = [];
  if (listData)
    rowDatas = listData.options?.map((obj) => ({
      ...obj,
      action: (
        <Container className="d-flex btn-showcase">
          <Btn
            attrBtn={{
              color: "primary",
              outline: true,
              onClick: () => handleEditOption(obj)
            }}
          >
            {"Edit"}
          </Btn>
          <Btn
            attrBtn={{
              color: "danger",
              outline: true,
              onClick: () => handleDelete(listData._id, obj.itemId)
            }}
          >
            {"Delete"}
          </Btn>
        </Container>
      ),
      ECILCO: obj.ECILCO ? (
        <Container>
          <p>ECI: {obj.ECILCO ? obj.ECILCO.earlyCheckIn : ""}</p>
          <p>LCO: {obj.ECILCO ? obj.ECILCO.lateCheckOut : ""}</p>
        </Container>
      ) : null,
    }));

  const PackagesColumns = [
    {
      name: <H6>#</H6>,
      selector: (row, index) => ((currentPage - 1) * perPage) + (index + 1),
      center: true,
      wrap: true,
      width: "50px"
    },
    {
      name: <H6>Package Name</H6>,
      selector: (row) => row.packageName,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "200px"
    },
    {
      name: <H6>Amount</H6>,
      selector: (row) => row.amount,
      center: true,
      wrap: true,
      width: "110px"
    },
    {
      name: <H6>Tag</H6>,
      selector: (row) => row.tag,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Title</H6>,
      selector: (row) => row.title,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Subtitle</H6>,
      selector: (row) => row.subtitle,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "130px",
      omit: !listData.options?.some(option => option.subtitle ? true : false)
    },
    {
      name: <H6>Description</H6>,
      selector: (row) => row.description,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "220px",
      omit: !listData.options?.some(option => option.description ? true : false)
    },
    {
      name: <H6>Item ID</H6>,
      selector: (row) => row.itemId,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "120px"
    },
    {
      name: <H6>Selected Status</H6>,
      selector: (row) => `${row.isSelected ?? ""}`,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "172px"
    },
    {
      name: <H6>Is Cost Per Day</H6>,
      selector: (row) => `${row.isCostPerDay ?? ""}`,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "170px"
    },
    {
      name: <H6>Value</H6>,
      selector: (row) => row.value,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "125px"
    },
    {
      name: <H6>Type</H6>,
      selector: (row) => row.type,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "190px"
    },
    {
      name: <H6>Voucher Required</H6>,
      selector: (row) => `${row.voucherRequired ?? ""}`,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "186px"
    },
    {
      name: <H6>Can Upgrade</H6>,
      selector: (row) => `${row.canUpgrade ?? ""}`,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "153px",
      omit: !listData.options?.some(option => option.hasOwnProperty("canUpgrade"))
    },
    {
      name: <H6>Contain ECI</H6>,
      selector: (row) => `${row.containECI ?? ""}`,
      sortable: true,
      center: true,
      wrap: true,
      minWidth: "153px",
      omit: !listData.options?.some(option => option.hasOwnProperty("containECI"))
    },
    {
      name: <H6>ECI and LCO</H6>,
      selector: (row) => row.ECILCO,
      center: true,
      wrap: true,
      width: "150px",
      omit: !listData.options?.some(option => option.hasOwnProperty("ECILCO"))
    },
    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
      width: "267px",
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <Fragment>
      {isAddOption ? (
        <PackagesOptionsModal
          isOpen={isAddOption}
          ModalTitle={"Add Options"}
          toggler={handleAddOption}
          id={listData._id}
        />
      ) : null}

      {isEditOption && editOptionData ? (
        <PackagesOptionsModal
          isOpen={isEditOption}
          ModalTitle={`Edit ${editOptionData.packageName}`}
          toggler={handleEditOption}
          editOptionData={editOptionData}
          id={listData._id}
        />
      ) : null}

      <Container fluid={true}>
        <div className="social-tab d-flex align-items-center justify-content-between">
          <H4>{packageTitle}</H4>
          <Btn
            attrBtn={{
              className: "m-r-14",
              color: "primary",
              onClick: handleAddOption,
              disabled: isLoading
            }}
          >
            Add Options
          </Btn>
        </div>

        <CardBody className="p-t-0 p-b-1">
          <div className="order-history table-responsive">
            <DataTable
              data={rowDatas}
              columns={PackagesColumns}
              responsive={true}
              pagination
              paginationComponentOptions={paginationOptions}
              subHeaderAlign="center"
              progressPending={isLoading}
              progressComponent={<BarLoader />}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handlePerPageRowsChange}
            />
          </div>
        </CardBody>
      </Container>
    </Fragment>
  );
};

export default Packages;
