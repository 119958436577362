import React, { Fragment, useState } from "react";
import { Card, CardBody, Col, Container, Media, Row } from "reactstrap";

import { Breadcrumbs, Btn, P } from "../../../../AbstractElements";
import LeftSelectCategory from "./LeftSelectCategory";
import CategoryCompData from "./CategoryCompData";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import { useNavigate, useParams } from "react-router";

const CategoryComponent = () => {
  const navigate = useNavigate();
  const params = useParams()
  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    e.preventDefault();

    console.log("searchText:", searchText);
  };

  const [isAdd, setIsAdd] = useState(false);
  const handleAdd = () => setIsAdd(!isAdd);

  const [activeTab, setActiveTab] = useState(0);
  console.log("activeTab:", activeTab);

  const listData = [
    { name: "Indian" },
    { name: "Essentials" },
    { name: "Japanese" },
    { name: "Services" },
    { name: "Italian" },
  ];

  return (
    <>
      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs
            parent="Category-Component Configuration"
            title="Category-Component Configuration"
          />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />

            <Btn
              attrBtn={{
                className: "m-r-15 m-l-15 d-block",
                color: "success",
                // outline: true,
                onClick: () =>
                  navigate(
                    `${process.env.PUBLIC_URL}/${params.hotelcode}/in_room_services/category_component/add`
                  ),
              }}
            >
              Add
            </Btn>
          </div>
        </div>

        <div className="email-wrap bookmark-wrap">
          <Row>
            <Col xl="3" className="box-col-3 xl-30">
              <div className="md-sidebar">
                <div className={"md-sidebar-aside open"}>
                  <Card>
                    <CardBody>
                      <div className="email-app-sidebar left-bookmark custom-scrollbar">
                        <LeftSelectCategory
                          title={"Categories"}
                          activeTab={activeTab}
                          setActiveTab={setActiveTab}
                          listData={listData}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </Col>
            <Col xl="9" md="12" className="box-col-9 xl-70">
              <div className="email-right-aside bookmark-tabcontent">
                <Card className="email-body radius-left">
                  <div className="ps-0">
                    <CategoryCompData
                      activeTab={activeTab}
                      listData={listData}
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default CategoryComponent;
