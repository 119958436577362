import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn, H6, H4 } from "../../../AbstractElements";
import MasterUsersModal from "./MasterUsersModal";
import MySearchInput from "../../../mycomponents/MySearchInput";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { adminUserSelector } from "./masterUsersSlice";
import { getadminuser, removeadminuser } from "./api";
import { toast } from "react-toastify";

const MasterUsers = () => {
  const dispatch = useDispatch();
  const { adminUserData, isLoading, status } = useSelector(adminUserSelector);

  const [searchText, setSearchText] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(false);

  useEffect(() => {
    dispatch(getadminuser());
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
  };

  const handleAdd = () => setIsAdd(!isAdd);
  const handleEdit = (data) => {
    setIsEdit(!isEdit);
    setEditData(data);
  };

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Data successfully deleted");
    } else {
      return toast.error(
        data.result?.message ||
          "Oops.. Something went wrong Please try again later"
      );
    }
  };

  const handleDelete = (id) => {

    let conf = window.confirm("Are you sure you want to delete this amenity?");

    if (conf) {
      dispatch(removeadminuser({ id, CB: functionCB }));
    }
  };

  const MasterUserRow = adminUserData?.result?.data
    ?.filter((val, i) => {
      return (
        val?.name.includes(searchText) ||
        val?.email.includes(searchText) ||
        val?.name.toLowerCase().includes(searchText.toLowerCase()) ||
        val?.email.toLowerCase().includes(searchText.toLowerCase()) ||
        val?.roles.some((role) =>
          role.displayRoleName && role.displayRoleName.toLowerCase().includes(searchText.toLowerCase())
        ) ||
        (val?.userTypeLabel ? val?.userTypeLabel.toLowerCase().includes(searchText.toLowerCase()) : false) ||
        (val?.accessPagesTitles.length ? 
          val?.accessPagesTitles.some(pageName => pageName.toLowerCase().includes(searchText.toLowerCase())) : false) ||
        val.accessPages.some((page) =>
          page.name.toLowerCase().includes(searchText.toLowerCase())
        )
      );
    })
    .map((val, i) => {
      return {
        index: i + 1,
        email: val.email,
        name: val.name,
        roles: val.userTypeLabel ?? (val.roles?.map(role => role?.displayRoleName)?.join(", ") ?? ""),
        accessPages: val.accessPages.length ? val.accessPages.map((page) => page.name).join(", ")
                      : (val.accessPagesTitles?.length ? val.accessPagesTitles.map((pageName) => pageName).join(", ") : ""),
        action: (
          <Container className="d-flex btn-showcase">
            <Btn
              attrBtn={{
                color: "primary",
                outline: true,
                onClick: () => handleEdit(val),
              }}
            >
              {"Edit"}
            </Btn>
            <Btn
              attrBtn={{
                color: "danger",
                outline: true,
                onClick: () => handleDelete(val._id),
              }}
            >
              {"Delete"}
            </Btn>
          </Container>
        ),
      };
    });

  const MasterUserColumn = [
    {
      name: <H6>#</H6>,
      selector: (row) => row.index,
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Email</H6>,
      selector: (row) => row.email,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Name</H6>,
      selector: (row) => row.name,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Roles</H6>,
      selector: (row) => row.roles,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Front Desk Access Pages</H6>,
      selector: (row) => row.accessPages,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <Fragment>
      <MasterUsersModal
        isOpen={isAdd}
        ModalTitle={"Add Users"}
        toggler={handleAdd}
      />
      {isEdit && editData ? (
        <MasterUsersModal
          isOpen={isEdit}
          ModalTitle={"Edit Staff"}
          toggler={handleEdit}
          editData={editData}
        />
      ) : null}

      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs parent="Master Users" title="Master Users" />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />

            <Btn
              attrBtn={{
                className: "m-r-15 m-l-15 d-block",
                color: "success",
                // outline: true,
                disabled: isLoading,
                onClick: handleAdd,
              }}
            >
              Add Master Users
            </Btn>
          </div>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab">
                  <H4>MASTER USERS DATA</H4>
                </div>
              </Col>

              <CardBody className="p-t-0">
                <div className="order-history table-responsive">
                  <DataTable
                    data={MasterUserRow}
                    columns={MasterUserColumn}
                    responsive={true}
                    pagination
                    paginationComponentOptions={paginationOptions}
                    subHeaderAlign="center"
                    progressPending={isLoading}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MasterUsers;
