import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_RESERVATION } from "../../../../utils/axiosInterceptors";
import { config } from "../../../../config";

export const fetchLightCategory = createAsyncThunk(
  "lightCategory/getAlllightCategory",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("lightCategory payload:", state);

    try {
      let { data } = await AXIOS_RESERVATION.get(`getAlllightCategory`);
      // console.log("data:", data);

      return data;
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const addNewlightCategory = createAsyncThunk(
  "lightCategory/addNewlightCategory",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("lightCategory payload:", state);

    let { CB, ...others } = state;
    others.hotelId = config.HOTELID;

    console.log("others:", others);

    var headers = {
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.post(
        `addNewlightCategory`,
        others,
        {
          headers,
        }
      );
      // console.log("data:", data);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchLightCategory());

        return data;
      }
    } catch (err) {
      // console.log("error:", error);

      CB && CB(err);
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const editlightCategory = createAsyncThunk(
  "lightCategory/editlightCategory",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("lightCategory payload:", state)

    let { CB, ...others } = state;
    // console.log("others:", others)

    try {
      let { data } = await AXIOS_RESERVATION.post(`editlightCategory`, others);
      // console.log("data:",  data);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchLightCategory());

        return data;
      }
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const removelightCategory = createAsyncThunk(
  "lightCategory/removelightCategory",
  async (state, { getState, rejectWithValue, dispatch }) => {
    let { id, CB } = state;
    console.log("_id:", id);

    var headers = {
      _id: id,
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.delete(`removelightCategory`, {
        headers,
      });
      // console.log("data:", data);
      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(fetchLightCategory());

        return data;
      }
    } catch (err) {
      // console.log("error:", error);

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong Please try again later",
        },
      });
    }
  }
);
