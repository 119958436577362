import Default from "../Pages/DashBoard/Default/Default";
import Ecommerce from "../Pages/DashBoard/Ecommerce/index";
import Crypto from "../Pages/DashBoard/Crypto";
import OrderHistory from "../Pages/Ecommerce/OrderHistory";
import PaymentDetails from "../Pages/Ecommerce/PaymentDetails";
import ProductList from "../Pages/Ecommerce/ProductList";
import ProductPage from "../Pages/Ecommerce/ProductPage";
import Products from "../Pages/Ecommerce/Products";
import FileManager from "../Pages/FileManager";
import KanbanBoard from "../Pages/KanbanBoard";
import CreateList from "../Pages/Project/Create List";
import ProjectList from "../Pages/Project/Project List";
import Charts from "../Pages/Widgets/Charts";
import General from "../Pages/Widgets/General";
import Invoice from "../Pages/Ecommerce/Invoice/index";
import ProductCart from "../Pages/Ecommerce/ProductCart";
import WishList from "../Pages/Ecommerce/Wishlist";
import CheckOut from "../Pages/Ecommerce/CheckOut";
import PricingMembership from "../Pages/Ecommerce/PricingMemberShip";
import MailInbox from "../Pages/Email/Mail Inbox";
import ReadMail from "../Pages/Email/Read Mail";
import Compose from "../Pages/Email/Compose";
import ChatApp from "../Pages/Chat/ChatApp";
import VideoChat from "../Pages/Chat/VideoChat";
import UsersProfile from "../Pages/Users/UsersProfile";
import UsersEdit from "../Pages/Users/UsersEdit";
import UsersCardss from "../Pages/Users/UsersCards";
import SocialApp from "../Pages/SocialApp";
import Typography from "../Pages/Ui-kit/Typography";
import Avatars from "../Pages/Ui-kit/Avtars";
import HelperClasses from "../Pages/Ui-kit/HelperClass";
import Grid from "../Pages/Ui-kit/Grid";
import TagAndPills from "../Pages/Ui-kit/TagAndPills";
import Progressbars from "../Pages/Ui-kit/Progress";
import Modals from "../Pages/Ui-kit/Modals";
import Alerts from "../Pages/Ui-kit/Alert";
import PopOvers from "../Pages/Ui-kit/Popover";
import Tooltips from "../Pages/Ui-kit/Tooltip";
import Spinners from "../Pages/Ui-kit/Spinners";
import Dropdowns from "../Pages/Ui-kit/Dropdown";
import TabBootstrap from "../Pages/Ui-kit/Tabs/BootstrapTab";
import TabLine from "../Pages/Ui-kit/Tabs/LineTab";
import Accordian from "../Pages/Ui-kit/Accordian";
import Shadow from "../Pages/Ui-kit/Shadows";
import List from "../Pages/Ui-kit/Lists";
import Calender from "../Pages/Calender/Index";
import SearchResult from "../Pages/Search Result";
import Draggable from "../Pages/Calender/Draggable Calender";
import FormValidation from "../Pages/Forms/Form Control/FormValidation";
import BaseInput from "../Pages/Forms/Form Control/BaseInput";
import InputGroups from "../Pages/Forms/Form Control/InputGroup";
import MegaOptions from "../Pages/Forms/Form Control/MegaOptions";
import RadioCheckbox from "../Pages/Forms/Form Control/RadioCheckbox";
import Todo from "../Pages/Todo";
import TodoFirebase from "../Pages/Todo Firebase";
import Bookmarks from "../Pages/BookMarks";
import Tasks from "../Pages/Tasks";
import Contact from "../Pages/Contact";
import ContactFirebase from "../Pages/Contact/contactFirebase";
import FormDatapicker from "../Pages/Forms/Form Widgets/FormDatepicker";
import FormTypeahead from "../Pages/Forms/Form Widgets/FormTypeahead";
import FormDefault from "../Pages/Forms/Form Layout/FormDefault";
import FormWizard from "../Pages/Forms/Form Layout/FormWizard";
import FormDateTime from "../Pages/Forms/Form Widgets/FormDateTime";
import FormSelect2 from "../Pages/Forms/Form Widgets/FormSelect2";
import FormSwitch from "../Pages/Forms/Form Widgets/FormSwitch";
import FormTouchspin from "../Pages/Forms/Form Widgets/FormTouchSpin";
import FormDateRangepicker from "../Pages/Forms/Form Widgets/FormDateRangepicker";
import FromClipboard from "../Pages/Forms/Form Widgets/FormClipboard";
import BlogDetail from "../Pages/Blog/BlogDetail";
import BlogPost from "../Pages/Blog/BlogPost";
import BlogSingle from "../Pages/Blog/BlogSingle";
import LearningList from "../Pages/Learning/LearningList";
import DetailedList from "../Pages/Learning/DetailedList";
import CardView from "../Pages/Job Search/CardView";
import ListView from "../Pages/Job Search/ListView";
import ApplyNow from "../Pages/Job Search/Apply";
import JobDetails from "../Pages/Job Search/JobDetail";
import CKEditor from "../Pages/Editor/CkEditor";
import MDEEditor from "../Pages/Editor/MDEEditor";
import ACEEditor from "../Pages/Editor/ACECodeEditor";
import KnowledgeBase from "../Pages/Knowledgebase/KnowledgeBase";
import KnowledgeCategory from "../Pages/Knowledgebase/KnowledgeCategory";
import KnowledgeDetails from "../Pages/Knowledgebase/KnowledgeDetails";
import SamplePage from "../Pages/Sample Page";
import LeafletMap from "../Pages/Maps/LeafletMap";
import MapJs from "../Pages/Maps/MapJs";
import SupportTickit from "../Pages/Support Tickit";
import FAQ from "../Pages/FAQ";
import GalleryGrid from "../Pages/Gallery/GalleryGrid";
import GalleryGridDesc from "../Pages/Gallery/GalleryGridDesc";
import MasonryGallery from "../Pages/Gallery/MasonryGallery";
import MasonryGalleryDesc from "../Pages/Gallery/MasonryGalleryDesc";
import HoverEffects from "../Pages/Gallery/HoverEffect";
import DataTable from "../Pages/Tables/DataTable";
import BasicTable from "../Pages/Tables/Reactstrap Table/BasicTable";
import SizingTable from "../Pages/Tables/Reactstrap Table/Sizingtable";
import StylingTable from "../Pages/Tables/Reactstrap Table/StylingTable";
import BorderTable from "../Pages/Tables/Reactstrap Table/BorderTable";
import DefaultStyle from "../Pages/Buttons/Defaultstyle";
import FlagIcon from "../Pages/Icons/FlagIcons";
import FontAwesome from "../Pages/Icons/FontAwesom";
import IcoIcons from "../Pages/Icons/IcoIcons";
import ThemifyIcon from "../Pages/Icons/ThemifyIcon";
import FeatherIcon from "../Pages/Icons/FeatherIcon";
import WhetherIcon from "../Pages/Icons/WhetherIcon";
import ApexChart from "../Pages/Charts/ApexChart";
import ChartistChart from "../Pages/Charts/ChartistChart";
import ChartJs from "../Pages/Charts/ChartJs";
import GoogleChart from "../Pages/Charts/GoogleChart";
import BasicCard from "../Pages/Bonus UI/BasicCard";
import CreativeCard from "../Pages/Bonus UI/CreativeCard";
import DraggingCard from "../Pages/Bonus UI/DraggingCard";
import TabCard from "../Pages/Bonus UI/TabCard";
import Carousel from "../Pages/Bonus UI/Carousels";
import BreadcrumbsClass from "../Pages/Bonus UI/Breadcrumb";
import ImageCroppers from "../Pages/Bonus UI/ImageCropper";
import Scrollables from "../Pages/Bonus UI/Scrollable";
import BootstrapNotify from "../Pages/Bonus UI/BootstrapNotify";
import RatingClass from "../Pages/Bonus UI/Rating";
import DropzoneClass from "../Pages/Bonus UI/Dropzone";
import Tours from "../Pages/Bonus UI/Tours";
import Ribbons from "../Pages/Bonus UI/Ribbon";
import RangeSlider from "../Pages/Bonus UI/RangeSilder";
import Pagination from "../Pages/Bonus UI/Pagination";
import ImageUpload from "../Pages/Bonus UI/ImageUpload";
import Sticky from "../Pages/Bonus UI/Sticky";
import TimeLines from "../Pages/Bonus UI/TimeLine";
import SweetAlerts from "../Pages/Bonus UI/SweetAleart";
import TreeViews from "../Pages/Bonus UI/TreeView";
import DragAndDrop from "../Pages/Bonus UI/DragAndDrop";
import Steps from "../Pages/Bonus UI/Steps";
import Dashboard from "../Pages/SidebarMenuPages/Dashboard/Dashboard";
import RoomServicesRequest from "../Pages/SidebarMenuPages/RoomServicesRequest/RoomServicesRequest";
import Notification from "../Pages/SidebarMenuPages/Notification/Notification";
import HotelConfig from "../Pages/SidebarMenuPages/HotelConfig/HotelConfig/HotelConfig";
import Amenities from "../Pages/SidebarMenuPages/HotelConfig/Amenities/Amenities";
import RoomTypes from "../Pages/SidebarMenuPages/HotelConfig/RoomTypes/RoomTypes";
import RatePlans from "../Pages/SidebarMenuPages/HotelConfig/RatePlans/RatePlans";
import Rooms from "../Pages/SidebarMenuPages/HotelConfig/Rooms/Rooms";
import PackagesLeftData from "../Pages/SidebarMenuPages/HotelConfig/Packages/PackagesLeftData";
import ExploreDetails from "../Pages/SidebarMenuPages/HotelConfig/ExploreDetails/ExploreDetails";
import Dining from "../Pages/SidebarMenuPages/HotelConfig/Dining/Dining";
import ReservationTime from "../Pages/SidebarMenuPages/HotelConfig/ReservationTime/ReservationTime";
import TVHome from "../Pages/SidebarMenuPages/HotelConfig/TVHome/TVHome";
import TVChannel from "../Pages/SidebarMenuPages/HotelConfig/TVChannel/TVChannel";
import LightCategory from "../Pages/SidebarMenuPages/DeviceConfig/LightCategory/LightCategory";
import AllDevices from "../Pages/SidebarMenuPages/DeviceConfig/AllDevices/AllDevices";
import Category from "../Pages/SidebarMenuPages/InRoomServices/Category/Category";
import Component from "../Pages/SidebarMenuPages/InRoomServices/Component/Component";
import CategoryComponent from "../Pages/SidebarMenuPages/InRoomServices/CategoryComponent/CategoryComponent";
import MeetingRoom from "../Pages/SidebarMenuPages/Meeting/MeetingRoom/MeetingRoom";
// import MeetingReservation from "../Pages/SidebarMenuPages/Meeting/MeetingReservation/MeetingReservation";
import MeetingTableLayout from "../Pages/SidebarMenuPages/Meeting/MeetingTableLayout/MeetingTableLayout";
import MeetingCategory from "../Pages/SidebarMenuPages/Meeting/MeetingCategory/MeetingCategory";
import Departments from "../Pages/SidebarMenuPages/HotelStaff/Departments/Departments";
import StaffConfig from "../Pages/SidebarMenuPages/HotelStaff/StaffConfig/StaffConfig";
import LoyaltyConfig from "../Pages/SidebarMenuPages/LoyaltyRewards/LoyaltyConfig/LoyaltyConfig";
import LoyaltyUnsettled from "../Pages/SidebarMenuPages/LoyaltyRewards/LoyaltyUnsettled/LoyaltyUnsettled";
import LoyaltyReport from "../Pages/SidebarMenuPages/LoyaltyRewards/LoyaltyReports/LoyaltyReport";
import CouponConfig from "../Pages/SidebarMenuPages/Coupons/CouponConfig/CouponConfig";
import MasterUsers from "../Pages/SidebarMenuPages/MasterUsers/MasterUsers";
import AppVersions from "../Pages/SidebarMenuPages/AppVersions/AppVersions";
import CategoryCompAdd from "../Pages/SidebarMenuPages/InRoomServices/CategoryComponent/CategoryCompAdd";
import LoyaltyPromotion from "../Pages/SidebarMenuPages/LoyaltyRewards/LoyaltyPromotion/LoyaltyPromotion";
import Categories from "../Pages/SidebarMenuPages/InRoomServices/Categories/Categories";

export const routes = [
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    Component: <Dashboard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/room_services_request`,
    Component: <RoomServicesRequest />,
  },
  {
    path: `${process.env.PUBLIC_URL}/notification`,
    Component: <Notification />,
  },
  {
    path: `${process.env.PUBLIC_URL}/hotel_config`,
    Component: <HotelConfig />,
  },
  {
    path: `${process.env.PUBLIC_URL}/hotel_config/amenities`,
    Component: <Amenities />,
  },
  {
    path: `${process.env.PUBLIC_URL}/hotel_config/room_types`,
    Component: <RoomTypes />,
  },
  {
    path: `${process.env.PUBLIC_URL}/hotel_config/rate_plans`,
    Component: <RatePlans />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/hotel_config/unplugged`,
  //   Component: <Unplugged />,
  // },
  {
    path: `${process.env.PUBLIC_URL}/hotel_config/rooms`,
    Component: <Rooms />,
  },
  {
    path: `${process.env.PUBLIC_URL}/hotel_config/packages`,
    Component: <PackagesLeftData />,
  },
  {
    path: `${process.env.PUBLIC_URL}/hotel_config/explore_details`,
    Component: <ExploreDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/hotel_config/dining`,
    Component: <Dining />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/hotel_config/reservation_time`,
  //   Component: <ReservationTime />,
  // },
  {
    path: `${process.env.PUBLIC_URL}/hotel_config/tv_home`,
    Component: <TVHome />,
  },
  {
    path: `${process.env.PUBLIC_URL}/hotel_config/tv_channel`,
    Component: <TVChannel />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/hotel_config/early_checkin_late_checkout`,
  //   Component: <EarlyCheckinLateCheckout />,
  // },
  {
    path: `${process.env.PUBLIC_URL}/device_config/light_category`,
    Component: <LightCategory />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/device_config/tv_conf`,
  //   Component: <TVConf />,
  // },
  {
    path: `${process.env.PUBLIC_URL}/device_config/all_devices`,
    Component: <AllDevices />,
  },
  {
    path: `${process.env.PUBLIC_URL}/in_room_services/categories`,
    Component: <Categories />,
  },
  {
    path: `${process.env.PUBLIC_URL}/in_room_services/category`,
    Component: <Category />,
  },
  {
    path: `${process.env.PUBLIC_URL}/in_room_services/component`,
    Component: <Component />,
  },
  {
    path: `${process.env.PUBLIC_URL}/in_room_services/category_component`,
    Component: <CategoryComponent />,
  },
  {
    path: `${process.env.PUBLIC_URL}/in_room_services/category_component/add`,
    Component: <CategoryCompAdd />,
  },
  {
    path: `${process.env.PUBLIC_URL}/meeting/room`,
    Component: <MeetingRoom />,
  },
  {
    path: `${process.env.PUBLIC_URL}/meeting/category`,
    Component: <MeetingCategory />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/meeting/reservation`,
  //   Component: <MeetingReservation />,
  // },
  {
    path: `${process.env.PUBLIC_URL}/meeting/table_layout`,
    Component: <MeetingTableLayout />,
  },

  {
    path: `${process.env.PUBLIC_URL}/hotel_staff/departments`,
    Component: <Departments />,
  },
  {
    path: `${process.env.PUBLIC_URL}/hotel_staff/staff_config`,
    Component: <StaffConfig />,
  },
  {
    path: `${process.env.PUBLIC_URL}/loyalty_rewards/promotion`,
    Component: <LoyaltyPromotion />,
  },
  {
    path: `${process.env.PUBLIC_URL}/loyalty_rewards/config`,
    Component: <LoyaltyConfig />,
  },
  {
    path: `${process.env.PUBLIC_URL}/loyalty_rewards/reports`,
    Component: <LoyaltyReport />,
  },
  {
    path: `${process.env.PUBLIC_URL}/loyalty_rewards/unsettled`,
    Component: <LoyaltyUnsettled />,
  },
  {
    path: `${process.env.PUBLIC_URL}/coupons/config`,
    Component: <CouponConfig />,
  },
  {
    path: `${process.env.PUBLIC_URL}/master_users`,
    Component: <MasterUsers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app_versions`,
    Component: <AppVersions />,
  },

  //   template data
  {
    path: `${process.env.PUBLIC_URL}/dashboard`,
    Component: <Default />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/ecommerce/`,
    Component: <Ecommerce />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/crypto/`,
    Component: <Crypto />,
  },

  {
    path: `${process.env.PUBLIC_URL}/widgets/general/`,
    Component: <General />,
  },
  { path: `${process.env.PUBLIC_URL}/widgets/chart/`, Component: <Charts /> },

  {
    path: `${process.env.PUBLIC_URL}/project/projectlist/`,
    Component: <ProjectList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/project/createlist/`,
    Component: <CreateList />,
  },

  { path: `${process.env.PUBLIC_URL}/filemanager`, Component: <FileManager /> },

  { path: `${process.env.PUBLIC_URL}/kanbanboard`, Component: <KanbanBoard /> },

  {
    path: `${process.env.PUBLIC_URL}/ecommerce/product`,
    Component: <Products />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/productpage`,
    Component: <ProductPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/productlist`,
    Component: <ProductList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/paymentdetails`,
    Component: <PaymentDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/orderhistory`,
    Component: <OrderHistory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/invoice`,
    Component: <Invoice />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/cart`,
    Component: <ProductCart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/wishlist`,
    Component: <WishList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/checkout`,
    Component: <CheckOut />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ecommerce/pricing`,
    Component: <PricingMembership />,
  },

  { path: `${process.env.PUBLIC_URL}/email/mailbox`, Component: <MailInbox /> },
  { path: `${process.env.PUBLIC_URL}/email/readmail`, Component: <ReadMail /> },
  { path: `${process.env.PUBLIC_URL}/email/compose`, Component: <Compose /> },

  { path: `${process.env.PUBLIC_URL}/chat/chatapp`, Component: <ChatApp /> },
  {
    path: `${process.env.PUBLIC_URL}/chat/videocall`,
    Component: <VideoChat />,
  },

  {
    path: `${process.env.PUBLIC_URL}/users/userprofile`,
    Component: <UsersProfile />,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/useredit`,
    Component: <UsersEdit />,
  },
  {
    path: `${process.env.PUBLIC_URL}/users/usercard`,
    Component: <UsersCardss />,
  },

  { path: `${process.env.PUBLIC_URL}/bookmarks`, Component: <Bookmarks /> },

  { path: `${process.env.PUBLIC_URL}/social-app`, Component: <SocialApp /> },

  {
    path: `${process.env.PUBLIC_URL}/calandar/basic-calandar`,
    Component: <Calender />,
  },
  {
    path: `${process.env.PUBLIC_URL}/calandar/draggable-calandar`,
    Component: <Draggable />,
  },

  {
    path: `${process.env.PUBLIC_URL}/searchresult`,
    Component: <SearchResult />,
  },

  {
    path: `${process.env.PUBLIC_URL}/forms-control/formValidation`,
    Component: <FormValidation />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms-control/baseInput`,
    Component: <BaseInput />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms-control/inputGroup`,
    Component: <InputGroups />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms-control/megaOptions`,
    Component: <MegaOptions />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms-control/radioCheckbox`,
    Component: <RadioCheckbox />,
  },

  {
    path: `${process.env.PUBLIC_URL}/form-widget/datepicker`,
    Component: <FormDatapicker />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/typeahead`,
    Component: <FormTypeahead />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/datetimepicker`,
    Component: <FormDateTime />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/select2`,
    Component: <FormSelect2 />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/switch`,
    Component: <FormSwitch />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/touchspin`,
    Component: <FormTouchspin />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/rangepicker`,
    Component: <FormDateRangepicker />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/clipboard`,
    Component: <FromClipboard />,
  },

  {
    path: `${process.env.PUBLIC_URL}/form-layout/formDefault`,
    Component: <FormDefault />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-layout/formWizard`,
    Component: <FormWizard />,
  },

  { path: `${process.env.PUBLIC_URL}/todo`, Component: <Todo /> },
  {
    path: `${process.env.PUBLIC_URL}/todofirebase`,
    Component: <TodoFirebase />,
  },

  { path: `${process.env.PUBLIC_URL}/tasks`, Component: <Tasks /> },

  { path: `${process.env.PUBLIC_URL}/contacts`, Component: <Contact /> },
  {
    path: `${process.env.PUBLIC_URL}/contacts/firebase`,
    Component: <ContactFirebase />,
  },

  {
    path: `${process.env.PUBLIC_URL}/blog/blogdetail`,
    Component: <BlogDetail />,
  },
  {
    path: `${process.env.PUBLIC_URL}/blog/blogsingle`,
    Component: <BlogSingle />,
  },
  { path: `${process.env.PUBLIC_URL}/blog/blogpost`, Component: <BlogPost /> },

  {
    path: `${process.env.PUBLIC_URL}/ui-kits/typography/`,
    Component: <Typography />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/avatar/`, Component: <Avatars /> },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/helperclass/`,
    Component: <HelperClasses />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/grid/`, Component: <Grid /> },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tagsandpills/`,
    Component: <TagAndPills />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/progress-bar/`,
    Component: <Progressbars />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/modal/`, Component: <Modals /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/alert/`, Component: <Alerts /> },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/popover/`,
    Component: <PopOvers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tooltips/`,
    Component: <Tooltips />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/spinner/`,
    Component: <Spinners />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/dropdown/`,
    Component: <Dropdowns />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tab-bootstrap/`,
    Component: <TabBootstrap />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tab-line/`,
    Component: <TabLine />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/accordion/`,
    Component: <Accordian />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/shadow/`, Component: <Shadow /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/list/`, Component: <List /> },

  {
    path: `${process.env.PUBLIC_URL}/learning/learning-list`,
    Component: <LearningList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/learning/learning-detail`,
    Component: <DetailedList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/jobSearch/cardView`,
    Component: <CardView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/jobSearch/job-list`,
    Component: <ListView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/jobSearch/job-detail`,
    Component: <JobDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/jobSearch/job-apply`,
    Component: <ApplyNow />,
  },

  {
    path: `${process.env.PUBLIC_URL}/editor/ckEditor`,
    Component: <CKEditor />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editor/mdeEditor`,
    Component: <MDEEditor />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editor/ace-code-editor`,
    Component: <ACEEditor />,
  },

  {
    path: `${process.env.PUBLIC_URL}/knowledgebase`,
    Component: <KnowledgeBase />,
  },
  {
    path: `${process.env.PUBLIC_URL}/knowledgebase/knowledge-category`,
    Component: <KnowledgeCategory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/knowledgebase/knowledge-details`,
    Component: <KnowledgeDetails />,
  },

  {
    path: `${process.env.PUBLIC_URL}/sample-page`,
    Component: <SamplePage />,
  },

  {
    path: `${process.env.PUBLIC_URL}/mapsjs`,
    Component: <MapJs />,
  },
  {
    path: `${process.env.PUBLIC_URL}/leafletmap`,
    Component: <LeafletMap />,
  },

  {
    path: `${process.env.PUBLIC_URL}/support-ticket`,
    Component: <SupportTickit />,
  },

  {
    path: `${process.env.PUBLIC_URL}/faq`,
    Component: <FAQ />,
  },

  {
    path: `${process.env.PUBLIC_URL}/gallery/imageGallery`,
    Component: <GalleryGrid />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/imageWithDesc`,
    Component: <GalleryGridDesc />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/mesonryGallery`,
    Component: <MasonryGallery />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/mesonryDesc`,
    Component: <MasonryGalleryDesc />,
  },
  {
    path: `${process.env.PUBLIC_URL}/gallery/imageHover`,
    Component: <HoverEffects />,
  },

  {
    path: `${process.env.PUBLIC_URL}/tables/basictable`,
    Component: <BasicTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tables/sizingtable`,
    Component: <SizingTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tables/stylingtable`,
    Component: <StylingTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tables/bordertable`,
    Component: <BorderTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/tables/datatable`,
    Component: <DataTable />,
  },

  {
    path: `${process.env.PUBLIC_URL}/button`,
    Component: <DefaultStyle />,
  },

  {
    path: `${process.env.PUBLIC_URL}/icons/flagicon`,
    Component: <FlagIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/fontawesome`,
    Component: <FontAwesome />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/ico`,
    Component: <IcoIcons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/thimify`,
    Component: <ThemifyIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/feather`,
    Component: <FeatherIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/wheater`,
    Component: <WhetherIcon />,
  },

  {
    path: `${process.env.PUBLIC_URL}/charts/apex`,
    Component: <ApexChart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/charts/chartist`,
    Component: <ChartistChart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/charts/chartjs`,
    Component: <ChartJs />,
  },
  {
    path: `${process.env.PUBLIC_URL}/charts/google`,
    Component: <GoogleChart />,
  },

  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/basicCards`,
    Component: <BasicCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/creativeCards`,
    Component: <CreativeCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/draggingCards`,
    Component: <DraggingCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/tabCard`,
    Component: <TabCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/carousel`,
    Component: <Carousel />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/breadcrumb`,
    Component: <BreadcrumbsClass />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/imageCropper`,
    Component: <ImageCroppers />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/scrollable`,
    Component: <Scrollables />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/bootstrap-notify`,
    Component: <BootstrapNotify />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/rating`,
    Component: <RatingClass />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/dropzone`,
    Component: <DropzoneClass />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/tourComponent`,
    Component: <Tours />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/ribbons`,
    Component: <Ribbons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/rangeSlider`,
    Component: <RangeSlider />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/pagination`,
    Component: <Pagination />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/image-upload`,
    Component: <ImageUpload />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/stickyNotes`,
    Component: <Sticky />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/timelines/timeline1`,
    Component: <TimeLines />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/sweetAlert`,
    Component: <SweetAlerts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/treeview`,
    Component: <TreeViews />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/dragNDropComp`,
    Component: <DragAndDrop />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/step`,
    Component: <Steps />,
  },
];
