import { createSlice } from "@reduxjs/toolkit";
import { fetchPackages, addOrEditAddonPackage, addEditOrDeleteOptionsInAddonPackage } from "./api";

const initialState = {
  packagesData: [],
  isLoading: false,
  status: {
    apiMessage: "",
    apiSuccess: true,
  },
};

const packagesSlice = createSlice({
  initialState,
  name: "fetchPackages",
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchPackages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchPackages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.packagesData = action.payload.data?.addonPackagesData ?? [];
      })
      .addCase(fetchPackages.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR ADD AND EDIT PACKAGES
      .addCase(addOrEditAddonPackage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addOrEditAddonPackage.fulfilled, (state, { payload }) => {
        state.packagesData = payload?.data?.addonPackagesData ?? [];
        state.isLoading = false;
      })
      .addCase(addOrEditAddonPackage.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR ADD, EDIT AND DELETE OPTIONS FROM ADDON PACKAGE
      .addCase(addEditOrDeleteOptionsInAddonPackage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addEditOrDeleteOptionsInAddonPackage.fulfilled, (state, { payload }) => {
        state.packagesData = payload?.data?.addonPackagesData ?? [];
        state.isLoading = false;
      })
      .addCase(addEditOrDeleteOptionsInAddonPackage.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      });
  },
});

export default packagesSlice.reducer;
export const { setStatus } = packagesSlice.actions;
export const packagesSelector = (state) => state.fetchPackages;
