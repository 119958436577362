import React, { useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import { useFieldArray, useForm } from "react-hook-form";

const MeetingRoomModal = ({ isOpen, ModalTitle, toggler, editData }) => {
  const { register, control, handleSubmit, reset, watch, errors } = useForm({
    defaultValues: {
      test: [],
    },
  });
  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "test",
    });

  const onSubmit = (data) => {
    console.log("data", data);
  };
  console.log("errors:", errors);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  return (
    <>
      <CommonModal isOpen={isOpen} title={ModalTitle} toggler={toggler}>
        <Container>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Room Number:"}</Label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="MSD-07"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <FormGroup>
                  <Label>{"Maintenance Date:"}</Label>
                  <DatePicker
                    className="form-control digits myBorder"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      setDateRange(update);
                    }}
                    isClearable={true}
                  />
                </FormGroup>
              </Col>
            </Row>
          </form>
        </Container>
      </CommonModal>
    </>
  );
};

export default MeetingRoomModal;
