import React, { useState, useMemo, useEffect } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, FormGroup, Label, Row } from "reactstrap";
import InputPrev from "../../../../mycomponents/InputPrev";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { addExploreCategory, deleteCategory } from "./api";
import { toast } from "react-toastify";

const ExploreDetailsModalCat = ({
  isOpen,
  ModalTitle,
  toggler,
  editCategory,
  setApiResponseData,
  setActiveTab
}) => {
  const dispatch = useDispatch();

  const [imageFile, setImageFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return editCategory;
    }, [editCategory]),
  });

  useEffect(() => {
    setValue("image", imageFile);
  }, [imageFile]);

  useEffect(() => {
    if (editCategory) {
      setImageFile(editCategory?.image);
    }
  }, [editCategory]);

  const functionCB = (data) => {
    reset();
    if (data.success == true) {
      setApiResponseData(data);
      if(editCategory) return toast.success("Category edited successfully.");
      else return toast.success(data.result?.message || "Successfully added");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message || "Something went wrong, please try again later."
      );
    }
  };

  const onSubmit = (data) => {
    if (data) {
      toggler();
      dispatch(addExploreCategory({ ...data, CB: functionCB }));
    }
  };

  const deleteCategoryCallback = (data) => {
    if (data.success == true) {
      setApiResponseData(data);
      setActiveTab(0);
      return toast.success(data.result?.message || "Successfully deleted");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message || "Something went wrong, please try again later."
      );
    }
  };

  const handleDeleteCategory = () => {
    let confirm = window.confirm(`Are you sure that you want to delete "${editCategory.name}" category and related details ?`);
    if(confirm && editCategory._id) {
      dispatch(deleteCategory({ id: editCategory._id, CB: deleteCategoryCallback }));
      toggler();
    }
  };

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={toggler}
        togglerSave={handleSubmit(onSubmit)}
        togglerDelete={handleDeleteCategory}
        isUploading={isUploading}
        explrCat={editCategory}
      >
        <Container fluid={true} className="bd-example-row">
          <Row>
            <Col>
              <FormGroup>
                <Label>{"Name:"}</Label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Category name"
                  name="name"
                  {...register("name", { required: true })}
                />
                <span style={{ color: "#ff5370" }}>
                  {errors.name && "Name is required"}
                </span>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label>{"Display Order:"}</Label>
                <input
                  className="form-control"
                  type="number"
                  placeholder="Display Order"
                  name="index"
                  {...register("index", { required: true })}
                />
                <span style={{ color: "#ff5370" }}>
                  {errors.index && "Order is required"}
                </span>
              </FormGroup>
            </Col>
          </Row>

          <InputPrev
            inputLabel={"Change Image:"}
            inputLabelClass={"col-sm-6"}
            imageLabel={"Image:"}
            imageLabelClass={"col-sm-5"}
            src={imageFile}
            setImageFile={setImageFile}
            isUploading={isUploading}
            setIsUploading={setIsUploading}
          />
        </Container>
      </CommonModal>
    </>
  );
};

export default ExploreDetailsModalCat;
