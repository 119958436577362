// import React, { Fragment, useState } from "react";
// import { Card, Container } from "reactstrap";
// import { Breadcrumbs, Btn } from "../../../AbstractElements";
// import { Send, Submit } from "../../../Constant";
// import { useForm } from "react-hook-form";
// import {
//   CardBody,
//   CardFooter,
//   Col,
//   Form,
//   FormGroup,
//   Input,
//   Label,
//   Row,
// } from "reactstrap";
// import MyDropdown from "../../../mycomponents/MyDropdown";

// const Notification = () => {
//   const [title, setTitle] = useState("Select Category");

//   const { handleSubmit } = useForm();
//   const onSubmit = (data) => {};
//   return (
//     <Fragment>
//       <Container fluid={true}>
//         <Breadcrumbs parent="Push Notification" title="Push Notification" />

//         <Row>
//           <Col sm="12">
//             <Card>
//               <Form
//                 className="form theme-form"
//                 onSubmit={handleSubmit(onSubmit)}
//               >
//                 <CardBody>
//                   <Row className="mb-4">
//                     <Col>
//                       <MyDropdown
//                         title={title}
//                         setTitle={setTitle}
//                         data={[
//                           { opt: "All" },
//                           { opt: "Android" },
//                           { opt: "iOS" },
//                         ]}
//                       />
//                     </Col>
//                   </Row>

//                   <Row>
//                     <Col>
//                       <FormGroup>
//                         <Label>{"Select Users"}</Label>
//                         <Input
//                           type="select"
//                           name="select"
//                           className="form-control digits"
//                           multiple=""
//                           defaultValue="1"
//                         >
//                           <option>{"1"}</option>
//                           <option>{"2"}</option>
//                           <option>{"3"}</option>
//                           <option>{"4"}</option>
//                           <option>{"5"}</option>
//                         </Input>
//                       </FormGroup>
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col>
//                       <FormGroup className="mb-0">
//                         <Label>{"Title"}</Label>
//                         <Input
//                           className="form-control"
//                           type="text"
//                           placeholder="New Message"
//                         />
//                       </FormGroup>
//                     </Col>
//                   </Row>
//                   <Row>
//                     <Col>
//                       <FormGroup className="mb-0">
//                         <Label>{"Data"}</Label>
//                         <Input
//                           className="form-control"
//                           type="text"
//                           placeholder="Notification Data"
//                         />
//                       </FormGroup>
//                     </Col>
//                   </Row>

//                   <Btn
//                     attrBtn={{
//                       color: "primary",
//                       type: "submit",
//                       className: "me-2 mt-2",
//                     }}
//                   >
//                     {Send}
//                   </Btn>
//                 </CardBody>
//               </Form>
//             </Card>
//           </Col>
//         </Row>
//       </Container>
//     </Fragment>
//   );
// };

// export default Notification;

import React from "react";
import CommonModal from "../../../_core/Ui-kits/Modals/common/modal";
import { Container } from "reactstrap";

const NotificationModal = ({ isOpen, ModalTitle, toggler }) => {
  return (
    <>
      <CommonModal isOpen={isOpen} title={ModalTitle} toggler={toggler}>
        <Container fluid={true} className="bd-example-row"></Container>
      </CommonModal>
    </>
  );
};

export default NotificationModal;
