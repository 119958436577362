import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Label, ModalFooter, Row } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { hotelConfigSelector } from "./hotelConfigSlice";
import { editHotel, fetchHotelConfig } from "./api";
import { toast } from "react-toastify";

const BookingEngine = () => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  const { hotelConfigData } = useSelector(hotelConfigSelector);

  useEffect(() => {
    dispatch(fetchHotelConfig());
  }, [dispatch]);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return hotelConfigData;
    }, [hotelConfigData]),
  });

  useEffect(() => {
    if(Object.keys(hotelConfigData).length && hotelConfigData?.customBookingEngine) setSelectedOption("true");
    else if(Object.keys(hotelConfigData).length && hotelConfigData?.customBookingEngine == false) setSelectedOption("false");
    else setSelectedOption(null);
  }, [hotelConfigData])

  const functionCB = (data) => {
    if (data.success === true) {
      return toast.success(data.result?.message || "Successful");
    } else if (data.success === false) {
      return toast.error(
        data.result?.message ||
          "Oops.. Something went wrong. Please try again later"
      );
    }
  };

  const onSubmit = (data) => {
    data.customBookingEngine = selectedOption ? (selectedOption == "true" ? true : false) : null;
    if (hotelConfigData) {
      dispatch(editHotel({ ...data, CB: functionCB }));
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
        <Row className="align-items-center">
          <Col>
            <Label className="d-block" for="edo-ani">
              <input
                className="radio_animated"
                id="edo-ani"
                type="radio"
                name="rdo-ani"
                value={true}
                checked={selectedOption == "true" ? true : false}
                onChange={handleOptionChange}
              />
              Shashi Booking Engine
            </Label>
          </Col>
          <Col>
            <Label className="d-block" for="edo-ani1">
              <input
                className="radio_animated"
                id="edo-ani1"
                type="radio"
                name="rdo-ani"
                value={false}
                checked={selectedOption == "false" ? true : false}
                onChange={handleOptionChange}
              />
              Travelclick Booking Engine
            </Label>
          </Col>

          <Col>
            <ModalFooter>
              <Btn attrBtn={{ color: "primary" }} type="submit">
                Save Changes
              </Btn>
            </ModalFooter>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default BookingEngine;
