import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Form, FormGroup, Label, ModalFooter, Row } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import MyDropdown from "../../../../mycomponents/MyDropdown";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";
import { hotelConfigSelector } from "./hotelConfigSlice";
import { editHotel, fetchHotelConfig } from "./api";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const Room = () => {
  const [title, setTitle] = useState("US/pacific");
  const dispatch = useDispatch();
  const { hotelConfigData, isLoading, status } =
    useSelector(hotelConfigSelector);
  // console.log("isLoading:", isLoading);
  // console.log("status:", status);
  // console.log("hotelConfigData:", hotelConfigData);

  const [startDateA, setStartDateA] = useState(
    hotelConfigData?.checkInEnableTime
  );
  const [startDateB, setStartDateB] = useState();
  const [startDateC, setStartDateC] = useState();

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: useMemo(() => {
      return hotelConfigData;
    }, [hotelConfigData]),
  });

  useEffect(() => {
    dispatch(fetchHotelConfig());
  }, []);

  useEffect(() => {
    if (!startDateB) {
      setStartDateB(hotelConfigData.checkInTime);
    }
    if (!startDateC) {
      setStartDateC(hotelConfigData.checkOutTime);
    }
  }, [hotelConfigData]);

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Sccessful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message ||
          "Oops.. Something went wrong Please try again later"
      );
    }
  };

  const timeFormaterMilliSeconds = (data) => {
    // const timeString = "03:00 AM";

    const timeString = data;
    const date = new Date();
    const [hours, minutes] = timeString.split(":");
    date.setHours(parseInt(hours, 10), parseInt(minutes, 10), 0, 0);
    const milliseconds = date.getTime();

    return milliseconds;
  };

  const timeFormaterAmPm = (data) => {
    // const timestamp = 1688074200000;
    const timestamp = data;

    const date = new Date(timestamp);

    let hours = date.getHours();
    const minutes = date.getMinutes();
    const period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${period}`;

    return formattedTime;
  };

  const handleChangeA = (date) => {
    // console.log("  date:", date);
    // console.log(" result:", new Date(date).getTime());
    setStartDateA(new Date(date).getTime());
  };
  const handleChangeB = (date) => {
    // console.log(timeFormaterAmPm(new Date(date).getTime()));
    setStartDateB(timeFormaterAmPm(new Date(date).getTime()));
  };
  const handleChangeC = (date) => {
    // console.log(timeFormaterAmPm(new Date(date).getTime()));
    setStartDateC(timeFormaterAmPm(new Date(date).getTime()));
  };

  console.log(startDateA, startDateB, startDateC);

  const onSubmit = (data) => {
    // console.log(data);

    data.checkInEnableTime = startDateA;
    data.checkInTime = startDateB;
    data.checkOutTime = startDateC;

    dispatch(editHotel({ ...data, CB: functionCB }));
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
        <Row>
          <Col>
            <FormGroup>
              <Label>{"Min Room Per Person:"}</Label>
              <input
                className="form-control"
                type="number"
                name="minRoomPerPerson"
                {...register("minRoomPerPerson", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.min_room_per_person &&
                  "Min room per person is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Max Room Per Person:"}</Label>
              <input
                className="form-control"
                type="number"
                name="maxRoomPerPerson"
                {...register("maxRoomPerPerson", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.max_room_per_person &&
                  "Max room per person is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Min Adult Per Room:"}</Label>
              <input
                className="form-control"
                type="number"
                name="minAdultPerRoom"
                {...register("minAdultPerRoom", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.min_adult_per_room && "Min adult per room is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Max Adult Per Room:"}</Label>
              <input
                className="form-control"
                type="number"
                name="maxAdultPerRoom"
                {...register("maxAdultPerRoom", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.max_adult_per_room && "Max adult per room is required"}
              </span>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Label>{"Min Child Per Room:"}</Label>
              <input
                className="form-control"
                type="number"
                name="minChildPerRoom"
                {...register("minChildPerRoom", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.min_child_per_room && "Min child per room is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Max Child Per Room:"}</Label>
              <input
                className="form-control"
                type="number"
                name="maxChildPerRoom"
                {...register("maxChildPerRoom", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.max_child_per_room && "Max child per room is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Min Pets Per Room:"}</Label>
              <input
                className="form-control"
                type="number"
                name="minPetsPerRoom"
                {...register("minPetsPerRoom", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.min_pets_per_room && "Min pets per room is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Max Pets Per Room:"}</Label>
              <input
                className="form-control"
                type="number"
                name="maxPetsPerRoom"
                {...register("maxPetsPerRoom", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.max_pets_per_room && "Max pets per room is required"}
              </span>
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col>
            <FormGroup>
              <Label>{"Total Person Per Room:"}</Label>
              <input
                className="form-control"
                type="number"
                name="totalPersonPerRoom"
                {...register("totalPersonPerRoom", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.total_person_per_room &&
                  "Max pets per room is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Info Email:"}</Label>
              <input
                className="form-control"
                type="email"
                name="infoEmail"
                {...register("infoEmail", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.info_email && "Info email is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Time Zone:"}</Label>
              <input
                className="form-control"
                type="text"
                name="timeZone:"
                {...register("timeZone", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.timezone && "Time zone is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Moment Time Zone:"}</Label>
              <MyDropdown
                title={title}
                setTitle={setTitle}
                data={[{ opt: "US/Pacific" }]}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Label>{"Rating:"}</Label>
              <input
                className="form-control"
                type="text"
                name="rating:"
                {...register("rating", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.rating && "Rating is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"CheckIn Enable Time:"}</Label>
              <div
                className="date d-flex"
                id="dt-minimum"
                data-target-input="nearest"
              >
                {/* {console.log("startDateA", startDateA)} */}
                <DatePicker
                  className="form-control datetimepicker-input digits"
                  selected={startDateA}
                  onChange={handleChangeA}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                />
                <div
                  className="input-group-text"
                  data-target="#dt-time"
                  data-toggle="datetimepicker"
                >
                  <i className="fa fa-clock-o"></i>
                </div>
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"CheckIn Time:"}</Label>

              <div
                className="date d-flex"
                id="dt-minimum"
                data-target-input="nearest"
              >
                <DatePicker
                  className="form-control datetimepicker-input digits"
                  selected={startDateB && timeFormaterMilliSeconds(startDateB)}
                  onChange={handleChangeB}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                />
                <div
                  className="input-group-text"
                  data-target="#dt-time"
                  data-toggle="datetimepicker"
                >
                  <i className="fa fa-clock-o"></i>
                </div>
              </div>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"CheckOut Time:"}</Label>

              <div
                className="date d-flex"
                id="dt-minimum"
                data-target-input="nearest"
              >
                <DatePicker
                  className="form-control datetimepicker-input digits"
                  selected={startDateC && timeFormaterMilliSeconds(startDateC)}
                  onChange={handleChangeC}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Time"
                  dateFormat="h:mm aa"
                />
                <div
                  className="input-group-text"
                  data-target="#dt-time"
                  data-toggle="datetimepicker"
                >
                  <i className="fa fa-clock-o"></i>
                </div>
              </div>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <ModalFooter>
              <Btn
                attrBtn={{
                  color: "primary",
                  // onClick: props.togglerSave,
                }}
              >
                SaveChanges
              </Btn>
            </ModalFooter>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Room;
