import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Col, Form, FormGroup, Label, ModalFooter, Row } from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { hotelConfigSelector } from "./hotelConfigSlice";
import { editHotel, fetchHotelConfig } from "./api";
import { toast } from "react-toastify";

const Scanner = () => {
  const dispatch = useDispatch();

  const { hotelConfigData, isLoading, status } =
    useSelector(hotelConfigSelector);

  console.log("isLoading:", isLoading);
  console.log("status:", status);
  console.log("hotelConfigData:", hotelConfigData);

  useEffect(() => {
    dispatch(fetchHotelConfig());
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: useMemo(() => {
      return hotelConfigData;
    }, [hotelConfigData]),
  });

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message ||
          "Oops.. Something went wrong Please try again later"
      );
    }
  };

  const onSubmit = (data) => {
    console.log(data);

    if (hotelConfigData) {
      dispatch(editHotel({ ...data, CB: functionCB }));
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
        <Row>
          <Col>
            <FormGroup>
              <Label>{"Restaurants Tax(%):"}</Label>
              <input
                className="form-control"
                type="number"
                name="restaurantsTax"
                {...register("restaurantsTax", {
                  required: true,
                })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.restaurants_tax && "Restaurants tax is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Restaurants Default Gratuity(%):"}</Label>
              <input
                className="form-control"
                type="number"
                name="restaurantDefaultGratuityInPercentage"
                {...register("restaurantDefaultGratuityInPercentage", {
                  required: true,
                })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.restaurants_default_gratuity &&
                  "Restaurants default gravity is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Scanner Folio Success Message:"}</Label>
              <textarea
                className="form-control"
                name="scannerFolioSuccessMessage"
                {...register("scannerFolioSuccessMessage", {
                  required: true,
                })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.scanner_folio_success_message &&
                  "Scanner folio success message is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Scanner Folio Error Message:"}</Label>
              <textarea
                className="form-control"
                name="scannerFolioErrorMessage"
                {...register("scannerFolioErrorMessage", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.scanner_folio_error_message &&
                  "Scanner folio error message is required"}
              </span>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <ModalFooter>
              <Btn
                attrBtn={{
                  color: "primary",
                  // onClick: props.togglerSave,
                }}
              >
                SaveChanges
              </Btn>
            </ModalFooter>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Scanner;
