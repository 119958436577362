import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

const TableHeaderComp = ({ headingData, activeTab, setActiveTab }) => {
  return (
    <Nav tabs>
      {headingData?.map((headName, i) => (
        <NavItem key={i} className="m-b-15">
          <NavLink
            className={activeTab === i ? "active" : ""}
            onClick={() => setActiveTab(i)}
          >
            {headName.title}
          </NavLink>
        </NavItem>
      ))}
    </Nav>
  );
};
export default TableHeaderComp;
