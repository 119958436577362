import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_RESERVATION } from "../../../../utils/axiosInterceptors";
import { config } from "../../../../config";

function returnErrorObj(err) {
  return ({
    status: {
      apiSuccess: false,
      apiMessage: err?.result?.message || "Something went wrong, Please try again later."
    }
  });
}

export const fetchExploreDetails = createAsyncThunk(
  "exploreDetails/getexploreCategories",
  async (state, { getState, rejectWithValue, dispatch }) => {
    // console.log("amenities payload:", state);

    try {
      let { data } = await AXIOS_RESERVATION.get(`getexploreCategories`);
      // console.log("data:", data);

      return data;
    } catch (err) {
      // console.log("err:", err);

      throw rejectWithValue(returnErrorObj(err));
    }
  }
);

export const addExploreCategory = createAsyncThunk(
  "exploreDetails/saveExploreCategory",
  async (state, { getState, rejectWithValue, dispatch }) => {
    let { CB, ...others } = state;
    others.hotelId = config.HOTELID;

    try {
      let { data } = await AXIOS_RESERVATION.post(
        `saveExploreCategory`,
        others
      );

      CB && CB(data);
      if (data?.success == true) return data;
    } catch (err) {
      CB && CB(err);
      throw rejectWithValue(returnErrorObj(err));
    }
  }
);

export const deleteCategory = createAsyncThunk(
  "exploreDetails/deleteExploreCategory",
  async (state, { getState, rejectWithValue, dispatch }) => {
    let { CB, id } = state;

    try {
      let { data } = await AXIOS_RESERVATION.delete(`deleteExploreCategory?id=${id}`);
      CB && CB(data);
      if(data?.success == true) return data;
    } catch (err) {
      CB && CB(err);
      throw rejectWithValue(returnErrorObj(err));
    }
  }
);

export const addOrEditItemInsideCategory = createAsyncThunk(
  "exploreDetails/saveExploreItem",
  async (state, { getState, rejectWithValue, dispatch }) => {
    let { CB, ...others } = state;
    others.hotelId = config.HOTELID;

    try {
      let { data } = await AXIOS_RESERVATION.post(
        `saveExploreItem`,
        others
      );

      CB && CB(data);
      if (data?.success == true) return data;
    } catch (err) {
      CB && CB(err);
      throw rejectWithValue(returnErrorObj(err));
    }
  }
);

export const deleteExploreItem = createAsyncThunk(
  "exploreDetails/deleteExploreItem",
  async (state, { getState, rejectWithValue, dispatch }) => {
    let { CB, id } = state;

    try {
      let { data } = await AXIOS_RESERVATION.delete(`deleteExploreItem?id=${id}`);
      CB && CB(data);
      if(data?.success == true) return data;
    } catch (err) {
      CB && CB(err);
      throw rejectWithValue(returnErrorObj(err));
    }
  }
);