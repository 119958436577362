import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import PackagesModal from "./PackagesModal";
import { Breadcrumbs, Btn } from "../../../../AbstractElements";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import LeftSelectCategory from "../../InRoomServices/CategoryComponent/LeftSelectCategory";
import Packages from "./Packages";
import { packagesSelector } from "./packagesSlice";
import { useSelector } from "react-redux";
import { fetchPackages } from "./api";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

const PackagesLeftData = () => {
  const dispatch = useDispatch();
  const { packagesData, isLoading } = useSelector(packagesSelector);

  const [addonPackages, setAddonPackages] = useState([]);
  const [packageTitle, setPackageTitle] = useState(null);
  const [activeTab, setActiveTab] = useState(0);
  const [rowDatas, setRowDatas] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState({});

  useEffect(() => {
    dispatch(fetchPackages({ CB: CB }));
  }, []);

  useEffect(() => {
    setAddonPackages(
      packagesData
        ?.map((obj) => {
          if (obj.title?.toLowerCase()?.includes(searchText.toLowerCase())) {
            return {
              ...obj,
              name: obj.title
            };
          } else return null;
        })
        ?.filter((val) => val !== null) ?? []
    );
  }, [packagesData, searchText]);

  useEffect(() => {
    setActiveTab(0);
  }, [searchText]);

  useEffect(() => {
    if(addonPackages?.length) {
      let requestBodyKey = addonPackages?.[activeTab]?.requestBodyKey;
      setPackageTitle(
        requestBodyKey == "roomPackage"
          ? addonPackages?.[activeTab]?.title
          : addonPackages?.[activeTab]?.options?.[0]?.packageName
      );
      setRowDatas(addonPackages?.[activeTab]);
    } else {
      setPackageTitle(null);
      setRowDatas([]);
    };
  }, [addonPackages, activeTab]);

  function CB(err) {
    if(err.success == false) {
      return toast.error(err?.response?.data?.message || err?.message || "Something went wrong. Please try again later.");
    }
  }

  const handleAdd = () => setIsAdd(!isAdd);
  const handleEdit = () => {
    setIsEdit(!isEdit);
    setEditData(addonPackages?.[activeTab] ?? {});
  };

  const handleSearch = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <PackagesModal
        isOpen={isAdd}
        ModalTitle={"Add New Addon Package"}
        toggler={handleAdd}
        packagesData={packagesData}
      />

      {isEdit && editData ? (
        <PackagesModal
          isOpen={isEdit}
          ModalTitle={`Edit ${editData?.title ?? "Addon Package"}`}
          toggler={handleEdit}
          editData={editData}
          packagesData={packagesData}
        />
      ) : null}

      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs parent="Packages Data" title="Packages Data" />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />

            <Btn
              attrBtn={{
                className: "m-r-20 m-l-20 d-block",
                color: "primary",
                onClick: handleAdd,
                disabled: isLoading
              }}
            >
              Add Package
            </Btn>

            <Btn
              attrBtn={{
                className: "m-r-15 d-block",
                color: "primary",
                onClick: handleEdit,
                disabled: isLoading
              }}
            >
              Edit Package
            </Btn>
          </div>
        </div>

        <div className="email-wrap bookmark-wrap">
          <Row>
            <Col xl="3" className="box-col-3 xl-30">
              <div className="md-sidebar">
                <div className={"md-sidebar-aside open"}>
                  <Card>
                    <CardBody>
                      <div className="email-app-sidebar left-bookmark custom-scrollbar">
                        <LeftSelectCategory
                          title={"Packages"}
                          activeTab={activeTab}
                          setActiveTab={setActiveTab}
                          listData={addonPackages}
                        />
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </div>
            </Col>

            <Col xl="9" md="12" className="box-col-9 xl-70">
              <div className="email-right-aside bookmark-tabcontent">
                <Card className="email-body radius-left">
                  <div className="ps-0">
                    <Packages
                      listData={rowDatas}
                      isLoading={isLoading}
                      packageTitle={packageTitle}
                    />
                  </div>
                </Card>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};

export default PackagesLeftData;
