import { createSlice } from "@reduxjs/toolkit";
import { addNewRoom, editRoom, fetchRooms, removeRoom } from "./api";

const initialState = {
  roomsData: {},
  isLoading: false,
  status: {
    apiMessage: "",
    apiSuccess: true,
  },
};

const roomsSlice = createSlice({
  initialState,
  name: "fetchRooms",
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchRooms.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchRooms.fulfilled, (state, action) => {
        // console.log("fetchRooms fulfilled:", action.payload);

        state.isLoading = false;
        state.roomsData = action.payload;
      })
      .addCase(fetchRooms.rejected, (state, { payload }) => {
        // console.log("fetchRooms rejected:", payload);

        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR ADD ROOMS --->>>
      .addCase(addNewRoom.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewRoom.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(addNewRoom.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FRO EDITROOM --->>>
      .addCase(editRoom.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editRoom.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(editRoom.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR DELETE ROOMS --->>>
      .addCase(removeRoom.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeRoom.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(removeRoom.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      });
  },
});

export default roomsSlice.reducer;
export const { setStatus } = roomsSlice.actions;
export const roomsSelector = (state) => state.fetchRooms;
