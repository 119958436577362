import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import MyDropdown from "../../../mycomponents/MyDropdown";
import CommonModal from "../../../_core/Ui-kits/Modals/common/modal";
import { useDispatch } from "react-redux";
import { useMemo } from "react";
import { toast } from "react-toastify";
import { addappversions, editappversions } from "./api";

const AppVersionsModal = ({ isOpen, ModalTitle, toggler, editData }) => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState("Select OS");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [update, setUpdate] = useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return editData;
    }, [editData]),
  });

  useEffect(() => {
    setValue("forceUpdate", forceUpdate);
    setValue("update", update);
  }, [update, forceUpdate]);

  useEffect(() => {
    if (editData) {
      setTitle(editData?.osType);
      setForceUpdate(editData?.forceUpdate);
      setUpdate(editData?.update);
    }
  }, [editData]);

  const functionCB = (data) => {
    if (data.success == true) {
      reset();
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message ||
          "Oops.. something went wrong Please try again later"
      );
    }
  };

  const onSubmit = (data) => {
    if (editData) {
      dispatch(editappversions({ ...data, CB: functionCB }));
    } else {
      dispatch(addappversions({ ...data, CB: functionCB }));
    }
    toggler();
  };

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={toggler}
        togglerSave={handleSubmit(onSubmit)}
        isUploading={isUploading}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Container fluid={true}>
            <Row>
              {
                editData ? (
                  <Col>
                    <FormGroup>
                      <Label>{"OS:"}</Label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="OS"
                        name="osType"
                        {...register(`osType`, {
                          required: true,
                        })}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                ) : (
                  <Col sm="6" col="4" className="btn-showcase">
                    <FormGroup>
                      <Label>{"Select OS:"}</Label>
                      <MyDropdown
                        title={title}
                        setTitle={setTitle}
                        data={[{ opt: "android" }, { opt: "ios" }]}
                        registerProps={register("osType", { required: true })}
                      />
                      <span style={{ color: "#ff5370" }}>
                        {errors.osType && "OS Type is required"}
                      </span>
                    </FormGroup>
                  </Col>
                )
              }
              <Col>
                <FormGroup>
                  <Label>{"App Version:"}</Label>
                  <input
                    className="form-control"
                    type="number"
                    placeholder="App Version"
                    name="appVersion"
                    {...register(`appVersion`, {
                      required: true,
                    })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.appVersion && "App version is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="checkbox1"
                    name="forceUpdate"
                    type="checkbox"
                    checked={forceUpdate}
                    onChange={(e) => setForceUpdate(e.target.checked)}
                  />
                  <Label className="d-block" htmlFor="checkbox1">
                    Force Update?
                  </Label>
                </div>
              </Col>
            </Row>

            <Row className="m-b-10">
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="checkbox2"
                    name="update"
                    type="checkbox"
                    checked={update}
                    onChange={(e) => setUpdate(e.target.checked)}
                  />
                  <Label className="d-block" htmlFor="checkbox2">
                    Update?
                  </Label>
                </div>
              </Col>
            </Row>
          </Container>
        </form>
      </CommonModal>
    </>
  );
};

export default AppVersionsModal;
