import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { Breadcrumbs, Btn, H6, H4, H5 } from "../../../../AbstractElements";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import TableHeaderComp from "../../../../mycomponents/TableHeaderComp";
import HotelBranding from "./HotelBranding";
import Room from "./Room";
import URLHotel from "./URLHotel";
import PaymentText from "./PaymentText";
import Scanner from "./Scanner";
import InRoomDining from "./InRoomDining";
import BookingEngine from "./BookingEngine";
import PolicyTC from "./PolicyTC";
import Messages from "./Messages";
import PortIP from "./PortIP";
import { useDispatch } from "react-redux";
import { fetchHotelConfig } from "./api";
import { hotelConfigSelector } from "./hotelConfigSlice";
import { useSelector } from "react-redux";

const HotelConfig = () => {
  const dispatch = useDispatch();

  
  const { hotelConfigData, isLoading, status } = useSelector(hotelConfigSelector);
  console.log("isLoading:", isLoading);
  console.log("status:", status);
  console.log("hotelConfigData:", hotelConfigData);

  useEffect(() => {
    dispatch(fetchHotelConfig());
  }, []);

  const [activeTab, setActiveTab] = useState(0);

  const headingData = [
    { title: "HOTEL BRANDING" },
    { title: "ROOM" },
    { title: "PORTS/IP" },
    { title: "URL" },
    { title: "PAYMENT & TEXT" },
    { title: "SCANNER" },
    { title: "IN-ROOM DINING" },
    { title: "BOOKING ENGINE" },
    { title: "POLICY/T&C" },
    { title: "MESSAGES" },
  ];

  return (
    <Fragment>
      <Container fluid={true}>
        <Breadcrumbs parent="Hotel Configuration" title="Hotel Configuration" />
        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab">
                  <TableHeaderComp
                    headingData={headingData}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                  />
                </div>
              </Col>

              <CardBody>
                {activeTab == 0 ? (
                  <HotelBranding />
                ) : activeTab == 1 ? (
                  <Room />
                ) : activeTab == 2 ? (
                  <PortIP />
                ) : activeTab == 3 ? (
                  <URLHotel />
                ) : activeTab == 4 ? (
                  <PaymentText />
                ) : activeTab == 5 ? (
                  <Scanner />
                ) : activeTab == 6 ? (
                  <InRoomDining />
                ) : activeTab == 7 ? (
                  <BookingEngine />
                ) : activeTab == 8 ? (
                  <PolicyTC />
                ) : activeTab == 9 ? (
                  <Messages />
                ) : (
                  ""
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default HotelConfig;
