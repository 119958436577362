import React, { Fragment } from "react";
import { Container } from "reactstrap";

function IntroPage() {
  return (
    <div className="introPage-div">
      <section>
        <img src="/images/icons/hotelCartoon.svg" alt="" />
        <h2>This is the Shashi Booking Engine page.</h2>
        <h3>
          Please go to the hotel-specific booking page to continue with your
          reservation process.
        </h3>
      </section>
    </div>
  );
}

export default IntroPage;
