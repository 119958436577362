import React, { Fragment, useEffect, useRef, useState } from "react";
import { Card, CardBody, Col, Container, Label, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn, H6 } from "../../../../AbstractElements";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import DatePicker from "react-datepicker";
import { useReactToPrint } from "react-to-print";
import { useSelector } from "react-redux";
import { loyaltyReportSelector } from "./loyaltyReportSlice";
import { useDispatch } from "react-redux";
import { getLoyaltyReports } from "./loyaltyReportsApi";
import BarLoader from "../../../../Layout/Loader/BarLoader";
import { toast } from "react-toastify";
import moment from "moment-timezone";

const LoyaltyReport = () => {
  const { reportData, isLoading } = useSelector(loyaltyReportSelector);
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState("");
  const [startDate, setStartDate] = useState(new Date(getTodaysDate().startOfTheDay));
  const [endDate, setEndDate] = useState(new Date(getTodaysDate().startOfTheDay));
  const [totalEarned, setTotalEarned] = useState("");
  const [totalRedeemed, setTotalRedeemed] = useState("");

  useEffect(() => {
    dispatch(getLoyaltyReports({
      startDate: getTodaysDate().startOfTheDay,
      endDate: getTodaysDate().endOfTheDay,
      firstLoad: true,
      CB: showError
    }));
  }, [])

  useEffect(() => {
    setTotalEarned(reportData?.result?.data?.totalEarned?.toFixed(2) ?? 0);
    setTotalRedeemed(reportData?.result?.data?.totalRedeemed?.toFixed(2) ?? 0);
  }, [reportData])

  function getTodaysDate() {
    return {
      startOfTheDay: moment().tz("US/Pacific").startOf("day"),
      endOfTheDay: moment().tz("US/Pacific").endOf("day")
    };
  }

  const handleSearch = (e) => {
    e.preventDefault();
  };

  const LoyaltyColumn = [
    {
      name: <H6>#</H6>,
      selector: (row) => row.index,
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Name </H6>,
      selector: (row) => row.name,
      sortable: true,
      center: true,
      wrap: true,
      width: "166px",
    },
    {
      name: <H6>Email</H6>,
      selector: (row) => row.email,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Points ($)</H6>,
      selector: (row) => row.points,
      sortable: true,
      center: true,
      wrap: true,
      width: "145px",
    },
    {
      name: <H6>Date</H6>,
      selector: (row) => row.date,
      sortable: false,
      center: true,
      wrap: true,
      width: "110px",
    }
  ];

  function showError(status) {
    if(status == false) {
      return toast.error("Something went wrong, please try again.");
    }
  }

  const handleSubmit = () => {
    dispatch(getLoyaltyReports({
      startDate: startDate,
      endDate: endDate,
      CB: showError
    }))
  };

  const loyaltyEarnedRows = reportData?.result?.data?.earnedTr?.filter(obj => {
    return (
      obj.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      obj.email?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  }).map((obj, index) => {
    return { index: index + 1, ...obj };
  })

  const loyaltyRedeemedRows = reportData?.result?.data?.redeemedTr?.filter(obj => {
    return (
      obj.name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      obj.email?.toLowerCase()?.includes(searchText?.toLowerCase())
    );
  }).map((obj, index) => {
    return { index: index + 1, ...obj };
  })

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs
            parent="Loyalty Rewards Report"
            title="Loyalty Rewards Report"
          />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />
          </div>
        </div>

        <div ref={componentRef}>
          <Row>
            <Col sm="12">
              <Card>
                <div className="d-flex justify-content-space-between align-items-center">
                  <div className="social-tab p-b-5">
                    <Row>
                      <Col>
                        <Label>{"From:"}</Label>
                        <DatePicker
                          className="form-control digits"
                          selected={startDate}
                          minDate={new Date("09/24/2020")}
                          onChange={(update) => {
                            setStartDate(update);
                            if(update >= endDate) setEndDate(update);
                          }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                      </Col>
                      <Col>
                        <Label>{"To:"}</Label>
                        <DatePicker
                          className="form-control digits"
                          selected={endDate}
                          minDate={startDate}
                          onChange={(update) => {
                            setEndDate(update);
                          }}
                          peekNextMonth
                          showMonthDropdown
                          showYearDropdown
                          dropdownMode="select"
                          placeholderText="Select end date"
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                        />
                      </Col>

                      <Col className="social-tab">
                        <Btn
                          attrBtn={{
                            className: "m-r-15 m-l-15 d-block",
                            color: "primary",
                            // outline: true,
                            onClick: handleSubmit,
                            disabled: startDate && endDate && !isLoading ? false : true
                          }}
                        >
                          Generate
                        </Btn>
                      </Col>
                    </Row>
                  </div>

                  {/* <div className="print-btn">
                    <Btn
                      attrBtn={{
                        color: "success",
                        onClick: handlePrint,
                      }}
                    >
                      Print
                    </Btn>
                  </div> */}
                </div>

                <CardBody className="p-t-0">
                  <Row>
                    <Col sm="6">
                      <div className="social-tab" style={{ paddingLeft: "0" }}>
                        <H6>{`Total Earnned: $${totalEarned}`}</H6>
                      </div>

                      <div className="order-history table-responsive">
                        <DataTable
                          data={loyaltyEarnedRows}
                          columns={LoyaltyColumn}
                          responsive={true}
                          pagination
                          paginationComponentOptions={paginationOptions}
                          progressPending={isLoading}
                          progressComponent={<BarLoader />}

                          // paginationTotalRows={totalRows}
                          // paginationDefaultPage={currentPage}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          // searching={true}
                        />
                      </div>
                    </Col>
                    <Col sm="6">
                      <div className="social-tab" style={{ paddingLeft: "0" }}>
                        <H6>{`Total Redeemed: $${totalRedeemed}`}</H6>
                      </div>
                      <div className="order-history table-responsive">
                        <DataTable
                          data={loyaltyRedeemedRows}
                          columns={LoyaltyColumn}
                          responsive={true}
                          pagination
                          paginationComponentOptions={paginationOptions}
                          progressPending={isLoading}
                          progressComponent={<BarLoader />}

                          // paginationTotalRows={totalRows}
                          // paginationDefaultPage={currentPage}
                          // onChangeRowsPerPage={handlePerRowsChange}
                          // onChangePage={handlePageChange}
                          // searching={true}
                        />
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

export default LoyaltyReport;
