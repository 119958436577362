import { createSlice } from "@reduxjs/toolkit";
import { fetchLoyaltyUnsettled, settleLoyalty } from "./api";

const initialState = {
  loyaltyUnsettledData: {},
  isLoading: false,
  status: {
    apiMessage: "",
    apiSuccess: true,
  },
};

const loyaltyUnsettledSlice = createSlice({
  initialState,
  name: "fetchLoyaltyUnsettled",
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },

  extraReducers: (builder) => {
    // CASE FOR FETCH LOYALTY UNSETTLED
    builder
      .addCase(fetchLoyaltyUnsettled.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchLoyaltyUnsettled.fulfilled, (state, action) => {
        state.isLoading = false;
        state.loyaltyUnsettledData = action.payload;
      })
      .addCase(fetchLoyaltyUnsettled.rejected, (state, { payload }) => {
        state.isLoading = false;
      })

      // CASE FOR SETTLE LOYALTY
      .addCase(settleLoyalty.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(settleLoyalty.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(settleLoyalty.rejected, (state, { payload }) => {
        state.isLoading = false;
      });
  },
});

export default loyaltyUnsettledSlice.reducer;
export const { setStatus } = loyaltyUnsettledSlice.actions;
export const loyaltyUnsettledSelector = (state) => state.fetchLoyaltyUnsettled;
