import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import {
  Col,
  Form,
  FormGroup,
  InputGroup,
  Label,
  ModalFooter,
  Row,
} from "reactstrap";
import { Btn } from "../../../../AbstractElements";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { hotelConfigSelector } from "./hotelConfigSlice";
import { editHotel, fetchHotelConfig } from "./api";
import { toast } from "react-toastify";

const PortIP = () => {
  const dispatch = useDispatch();

  const { hotelConfigData, isLoading, status } =
    useSelector(hotelConfigSelector);
  console.log("isLoading:", isLoading);
  console.log("status:", status);
  console.log("hotelConfigData:", hotelConfigData);

  useEffect(() => {
    dispatch(fetchHotelConfig());
  }, []);

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    defaultValues: useMemo(() => {
      return hotelConfigData;
    }, [hotelConfigData]),
  });

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Sccessful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message ||
          "Oops.. Something went wrong Please try again later"
      );
    }
  };

  const onSubmit = (data) => {
    console.log(data);
    if (hotelConfigData) {
      dispatch(editHotel({ ...data, CB: functionCB }));
    }
  };
  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} className="needs-validation">
        <Row>
          <Col>
            <FormGroup>
              <Label>{"BACnet Port:"}</Label>
              <input
                className="form-control"
                type="text"
                name="BACnetPort"
                {...register("BACnetPort", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.bacnet && "BACnet port is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"BBMD IP:"}</Label>
              <input
                className="form-control"
                type="text"
                name="BBMDIP"
                {...register("BBMDIP", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.bbmd_ip && "BBMD IP is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"BBMD Port:"}</Label>
              <input
                className="form-control"
                type="text"
                name="BBMDPort"
                {...register("BBMDPort", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.bbmd_port && "BBMD port is required"}
              </span>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <FormGroup>
              <Label>{"BBMD Enable:"}</Label>
              <input
                className="form-control"
                type="text"
                name="BBMDEnable"
                {...register("BBMDEnable", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.bbmd_enabled && "BBMD is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Hotel Device Instance:"}</Label>
              <input
                className="form-control"
                type="number"
                name="hotelDeviceInstance"
                {...register("hotelDeviceInstance", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.max_adult_per_room && "Max adult per room is required"}
              </span>
            </FormGroup>
          </Col>
          <Col>
            <FormGroup>
              <Label>{"Device Instance:"}</Label>
              <input
                className="form-control"
                type="number"
                name="DeviceInstance"
                {...register("DeviceInstance", { required: true })}
              />
              <span style={{ color: "#ff5370" }}>
                {errors.min_child_per_room && "Min child per room is required"}
              </span>
            </FormGroup>
          </Col>
        </Row>

        <Row>
          <Col>
            <ModalFooter>
              <Btn
                attrBtn={{
                  color: "primary",
                  // onClick: props.togglerSave,
                }}
              >
                SaveChanges
              </Btn>
            </ModalFooter>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default PortIP;
