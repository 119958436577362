import { createSlice } from "@reduxjs/toolkit";
import { editHotel, fetchHotelConfig } from "./api";

const initialState = {
  hotelConfigData: {},
  hotelConfigData2: {},
  isLoading: false,
  status: {
    apiMessage: "",
    apiSuccess: true,
  },
};

const hotelConfigSlice = createSlice({
  initialState,
  name: "fetchHotelConfig",
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(fetchHotelConfig.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchHotelConfig.fulfilled, (state, action) => {
        console.log("hotel fulfilled:", action.payload);

        state.isLoading = false;
        state.hotelConfigData = action.payload.result?.data[0];
        state.hotelConfigData2 = action.payload.result?.data[1];
      })
      .addCase(fetchHotelConfig.rejected, (state, { payload }) => {
        // console.log("hotel rejected:", payload);

        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR EDIT HOTELCONFIG --->>>
      .addCase(editHotel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editHotel.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(editHotel.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      });
  },
});

export default hotelConfigSlice.reducer;
export const { setStatus } = hotelConfigSlice.actions;
export const hotelConfigSelector = (state) => state.fetchHotelConfig;
