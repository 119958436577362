import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { categoriesSelector } from '../categoriesSlice';
import { DiningCategoryDetails } from './DetailsComponents/DiningCategoryDetails';

const DiningCategory = ({ searchText, setShowSearchInput }) => {
  const { categoryComponentsData, departmentsData } = useSelector(categoriesSelector);
  let diningTabs = JSON.parse(
    JSON.stringify(
      categoryComponentsData?.component?.Dining ??
        categoryComponentsData?.component?.dining ??
        []
    )
  ); // As Dining comes both as "Dining" and "dining" in database as of now
  
  diningTabs = diningTabs?.filter((obj) => {
    return obj.name.toLowerCase().includes(searchText.toLowerCase());
  })?.map((obj) => {
    let departmentName = departmentsData?.find(depObj => depObj._id == obj.department)?.departmentName;
    obj.departmentName = departmentName;
    return obj;
  });

  return (
    <Fragment>
      <DiningCategoryDetails
        diningTabs={diningTabs}
        searchText={searchText}
        setShowSearchInput={setShowSearchInput}
      />
    </Fragment>
  );
}

export default DiningCategory;