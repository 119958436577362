import React, { useEffect, useMemo, useState } from "react";
import { Col, Form, FormGroup, Label, ModalFooter, Row } from "reactstrap";
import { useForm } from "react-hook-form";
import { Btn } from "../../../../AbstractElements";
import InputPrev from "../../../../mycomponents/InputPrev";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { hotelConfigSelector } from "./hotelConfigSlice";
import { editHotel, fetchHotelConfig } from "./api";
import { toast } from "react-toastify";

const HotelBranding = () => {
  const dispatch = useDispatch();

  const { hotelConfigData, isLoading, status } =
    useSelector(hotelConfigSelector);
  const [imageFile, setImageFile] = useState(null);
  const [imageFileB, setImageFileB] = useState(null);
  const [hotelImage, setHotelImage] = useState([]);
  const [logo, setLogo] = useState(null);
  const [appLogo, setAppLogo] = useState(null);
  const [isUploading, setIsUploading] = useState(false);

  console.log("isLoading:", isLoading);
  console.log("status:", status);
  // console.log("IMAGE:", hotelConfigData?.logo);
  useEffect(() => {
    dispatch(fetchHotelConfig());
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return hotelConfigData;
    }, [hotelConfigData]),
  });
  // console.log(
  //   "🚀 ~ file: HotelBranding.jsx:34 ~ HotelBranding ~ register:",
  //   register
  // );
  // console.log(
  //   "🚀 ~ file: HotelBranding.jsx:33 ~ defaultValues:useMemo ~ hotelConfigData?.result?.data[0];:",
  //   hotelConfigData?.result?.data[0]
  // );

  useEffect(() => {
    let cUrl = "https://res.cloudinary.com/shashigroup/image/upload/";

    setImageFile(cUrl + hotelConfigData?.hotelImage?.[0]?.image?.[0]);
    setImageFileB(cUrl + hotelConfigData?.hotelImage?.[1]?.image?.[0]);
    setLogo(hotelConfigData?.logo);
    setAppLogo(hotelConfigData?.appLogo);

    setValue("hotelImage", hotelImage);
    setValue("hotelLocation", hotelConfigData.hotelLocation);
    setValue("hotelName", hotelConfigData.hotelName);
    setValue("hotelDescription", hotelConfigData.hotelDescription);
    setValue("HotelGeoLocation", hotelConfigData.HotelGeoLocation);
    setValue("address", hotelConfigData.address);
    setValue("PMSname", hotelConfigData.PMSname);
    setValue("hotelVisiopnlineIp", hotelConfigData.hotelVisiopnlineIp);
    setValue("phone", hotelConfigData.phone);
  }, [hotelConfigData]);

  // useEffect(() => {
  //   setHotelImage([
  //     {
  //       image: [imageFile],
  //       tag: "primary",
  //     },
  //     {
  //       image: [imageFileB],
  //       tag: "secondary",
  //     },
  //   ]);

  //   setValue("hotelImage", hotelImage);
  //   setValue("logo", logo);
  //   setValue("appLogo", appLogo);
  // }, [imageFile, imageFileB, logo, appLogo]);
  // console.log(
  //   "🚀 ~ file: HotelBranding.jsx:77 ~ HotelBranding ~ imageFileB:",
  //   imageFileB
  // );

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message ||
          "Oops.. Something went wrong Please try again later"
      );
    }
  };

  const onSubmit = (data) => {
    console.log(data);

    data.hotelImage = [
      {
        image: imageFile.split("/").slice(-1),
        tag: "primary",
      },
      {
        image: imageFileB.split("/").slice(-1),
        tag: "secondary",
      },
    ];

    data.logo = logo;
    data.appLogo = appLogo;

    if (hotelConfigData) {
      dispatch(editHotel({ ...data, CB: functionCB }));
    }
  };

  return (
    <>
      <form
        className="needs-validation"
        noValidate=""
        onSubmit={handleSubmit(onSubmit)}
      >
        <Row>
          <Col xl="6" sm="12">
            <Row>
              <FormGroup>
                <Label>{"Name:"}</Label>
                <input
                  className="form-control"
                  type="text"
                  name="hotelName"
                  {...register("hotelName", { required: true })}
                />
                <span style={{ color: "#ff5370" }}>
                  {errors.hotelName && "Name is required"}
                </span>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup>
                <Label>{"Hotel Description:"}</Label>
                <textarea
                  rows="12"
                  className="form-control"
                  name="hotelDescription"
                  {...register("hotelDescription", { required: true })}
                />
                <span style={{ color: "#ff5370" }}>
                  {errors.hotelDescription && "Description is required"}
                </span>
              </FormGroup>
              <FormGroup className="col-sm-6">
                <Label>{"Hotel Location:"}</Label>
                <input
                  className="form-control"
                  type="text"
                  name="hotelLocation"
                  {...register("hotelLocation", { required: true })}
                />
                <span style={{ color: "#ff5370" }}>
                  {errors.location && "Hotel location is required"}
                </span>
              </FormGroup>
              <FormGroup className="col-sm-6">
                <Label>{"Hotel Geo Location:"}</Label>
                <input
                  className="form-control"
                  type="text"
                  name="HotelGeoLocation"
                  {...register("HotelGeoLocation", { required: true })}
                />
                <span style={{ color: "#ff5370" }}>
                  {errors.geo_location && "Hotel geo location is required"}
                </span>
              </FormGroup>
              <FormGroup className="col-sm-6">
                <Label>{"Hotel Address(for email):"}</Label>
                <input
                  className="form-control"
                  type="text"
                  name="address"
                  {...register("address", { required: true })}
                />
                <span style={{ color: "#ff5370" }}>
                  {errors.address && "Hotel address is required"}
                </span>
              </FormGroup>
              <FormGroup className="col-sm-6">
                <Label>{"Hotel phone(for email):"}</Label>
                <input
                  className="form-control"
                  type="text"
                  name="phone"
                  {...register("phone", { required: true })}
                />
                <span style={{ color: "#ff5370" }}>
                  {errors.phone && "Hotel phone is required"}
                </span>
              </FormGroup>
              <FormGroup className="col-sm-6">
                <Label>{"PMS Name:"}</Label>
                <input
                  className="form-control"
                  type="text"
                  name="PMSname"
                  {...register("PMSname", { required: true })}
                />
                <span style={{ color: "#ff5370" }}>
                  {errors.pms_name && "PMS Name is required"}
                </span>
              </FormGroup>
              <FormGroup className="col-sm-6">
                <Label>{"VisionLine IP:"}</Label>
                <input
                  className="form-control"
                  type="text"
                  name="hotelVisiopnlineIp"
                  {...register("hotelVisiopnlineIp", { required: true })}
                />
                <span style={{ color: "#ff5370" }}>
                  {errors.visionline && "visionline IP is required"}
                </span>
              </FormGroup>
            </Row>
          </Col>

          {/* Logos */}
          <Col xl="6" sm="12">
            <InputPrev
              inputLabel={"For new image change choose file:"}
              inputLabelClass={"col-sm-9"}
              imageLabel={"hotelImage Image:"}
              imageLabelClass={"col-sm-3"}
              setImageFile={setImageFile}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              src={imageFile}
            />

            <InputPrev
              inputLabel={"For new image change choose file:"}
              inputLabelClass={"col-sm-9"}
              imageLabel={"Secondary Image:"}
              imageLabelClass={"col-sm-3"}
              setImageFile={setImageFileB}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              src={imageFileB}
            />
            <InputPrev
              inputLabel={"For new image change choose file:"}
              inputLabelClass={"col-sm-9"}
              imageLabel={"Logo:"}
              imageLabelClass={"col-sm-3"}
              setImageFile={setLogo}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              src={logo}
            />

            <InputPrev
              inputLabel={"For new image change choose file:"}
              inputLabelClass={"col-sm-9"}
              imageLabel={"App Logo:"}
              imageLabelClass={"col-sm-3"}
              setImageFile={setAppLogo}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              src={appLogo}
            />
          </Col>
        </Row>
        <Row className="m-r-10 m-t-5">
          <Col className="p-r-0">
            <ModalFooter>
              <Btn
                attrBtn={{
                  color: "primary",
                  // onClick: props.togglerSave,
                }}
              >
                Save Changes
              </Btn>
            </ModalFooter>
          </Col>
        </Row>
      </form>
    </>
  );
};

export default HotelBranding;
