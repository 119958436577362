import React, { Fragment } from 'react';
import { Media } from 'reactstrap';
import { MARKJENCO, MARKJENCOEMAIL } from '../../Constant';
import { H6, Image, P } from '../../AbstractElements';
import { Link, useParams } from 'react-router-dom';

const HeaderProfile = () => {
  const params = useParams()
  return (
    <Fragment>
      <Media >
        <div className="media-size-email">
          <Image attrImage={{ className: 'me-3 rounded-circle', src: `${require('../../assets/images/user/user.png')}`, alt: '' }} />
        </div>
        <Media body>
          <H6 attrH6={{ className: 'f-w-600' }} >
            <Link to={`${process.env.PUBLIC_URL}/${params.hotelcode}/users/userprofile`}>{MARKJENCO}</Link></H6>
          <P>{MARKJENCOEMAIL}</P>
        </Media>
      </Media>
    </Fragment>
  );
};

export default HeaderProfile;