import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  ModalFooter,
  Row,
} from "reactstrap";
import { Breadcrumbs, Btn, H4, H6 } from "../../../../AbstractElements";
import { useForm } from "react-hook-form";
import MySelectDropd from "../../../../mycomponents/MySelectDropd";
import { useDispatch } from "react-redux";
import { edithotel, fetchLoyaltyConfig } from "./api";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { loyaltyConfigSelector } from "./loyaltyConfigSlice";
import BarLoader from "../../../../Layout/Loader/BarLoader";

const LoyaltyConfig = () => {
  const dispatch = useDispatch();

  const {
    // loyaltyConfigData,
    loyaltyConfigData,
    isLoading,
    status,
  } = useSelector(loyaltyConfigSelector);

  const [existingEmailSC, setExistingEmailSC] = useState([]);
  const [existingEmailRP, setExistingEmailRP] = useState([]);
  const [existingEligibleSC, setExistingEligibleSC] = useState([]);
  const [existingEligibleRP, setExistingEligibleRP] = useState([]);
  const [couponEligibleSourceCode, setCouponEligibleSourceCode] = useState([]);
  const [couponEligibleRatePlan, setCouponEligibleRatePlan] = useState([]);
  const [loyConfigData, setLoyConfigData] = useState({});

  useEffect(() => {
    dispatch(fetchLoyaltyConfig());
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setExistingEmailSC(
      loyaltyConfigData?.result?.data[0]?.loyaltyEmailSourceCodes
    );
    setExistingEmailRP(
      loyaltyConfigData?.result?.data[0]?.loyaltyEmailRatePlanCodes
    );
    setExistingEligibleSC(
      loyaltyConfigData?.result?.data[0]?.loyaltyEligibleSourceCodes
    );
    setExistingEligibleRP(
      loyaltyConfigData?.result?.data[0]?.loyaltyEligibleRatePlanCodes
    );

    setCouponEligibleSourceCode(
      loyaltyConfigData?.result?.data[0]?.shashiCashEligibleSourceCodeOptions
    );
    setCouponEligibleRatePlan(
      loyaltyConfigData?.result?.data[0]?.couponEligibleRatePlanCodeOptions
    );
    setLoyConfigData(loyaltyConfigData?.result?.data[0]);
  }, [loyaltyConfigData]);

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message ||
          "Oops.. Something went wrong Please try again later"
      );
    }
  };

  const handleChanges = (data) => {
    setLoyConfigData((prevData) => {
      return {
        ...prevData,
        [data.name]: data.value,
      };
    });
  };

  const onSubmit = () => {
    if (
      loyConfigData["loyaltyEmailSourceCodes"].length === 0 ||
      loyConfigData["loyaltyEmailRatePlanCodes"].length === 0 ||
      loyConfigData["loyaltyEligibleSourceCodes"].length === 0 ||
      loyConfigData["loyaltyEligibleRatePlanCodes"].length === 0
    ) {
      toast.warning("Please fill in all required fields before submitting.");
    } else {
      dispatch(edithotel({ loyConfigData, CB: functionCB }));
    }
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs
            parent="Loyalty Configuration"
            title="Loyalty Configuration"
          />
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab p-b-5">
                  <H4>LOYALTY CONFIGURATION</H4>
                </div>
              </Col>
              {!isLoading ? (
                <CardBody className="p-5 p-t-0">
                  <form
                    onSubmit={(e) => e.preventDefault()}
                    className="needs-validation"
                  >
                    <Row>
                      <div
                        className="social-tab"
                        style={{ paddingLeft: "0", paddingBottom: "0" }}
                      >
                        <H6>EMAIL CONFIGURATION</H6>
                      </div>
                      <hr />
                      <>
                        <Col>
                          <Label className="f-16">
                            Send email Source Codes:
                          </Label>
                          {couponEligibleSourceCode && (
                            <MySelectDropd
                              options={couponEligibleSourceCode}
                              name={"loyaltyEmailSourceCodes"}
                              getNameAndValue={handleChanges}
                              selectedValues={existingEmailSC}
                              key={existingEmailSC.join("-")}
                            />
                          )}
                        </Col>
                        <Col>
                          <Label className="f-16">Email Rate Plan Codes:</Label>
                          <div>
                            {couponEligibleRatePlan && (
                              <MySelectDropd
                                options={couponEligibleRatePlan}
                                name={"loyaltyEmailRatePlanCodes"}
                                getNameAndValue={handleChanges}
                                selectedValues={existingEmailRP}
                                key={existingEmailRP.join("-")}
                              />
                            )}
                          </div>
                        </Col>
                      </>
                    </Row>

                    <Row className="p-t-15">
                      <div
                        className="social-tab"
                        style={{ paddingLeft: "0", paddingBottom: "0" }}
                      >
                        <H6>ELIGIBLE FOR LOYALTY</H6>
                      </div>
                      <hr />

                      <>
                        <Col>
                          <Label className="f-16">Eligible Source Codes:</Label>

                          {couponEligibleSourceCode && (
                            <MySelectDropd
                              options={couponEligibleSourceCode}
                              name={"loyaltyEligibleSourceCodes"}
                              getNameAndValue={handleChanges}
                              selectedValues={existingEligibleSC}
                              key={existingEligibleSC.join("-")}
                            />
                          )}
                        </Col>
                        <Col>
                          <Label className="f-16">
                            Eligible Rate Plan Codes:
                          </Label>

                          {couponEligibleRatePlan && (
                            <MySelectDropd
                              options={couponEligibleRatePlan}
                              name={"loyaltyEligibleRatePlanCodes"}
                              getNameAndValue={handleChanges}
                              selectedValues={existingEligibleRP}
                              key={existingEligibleSC.join("-")}
                            />
                          )}
                        </Col>
                      </>
                    </Row>

                    <Row className="m-r-5 m-t-25">
                      <Col className="p-r-0">
                        <ModalFooter>
                          <Btn
                            attrBtn={{
                              color: "primary",
                              onClick: handleSubmit(onSubmit),
                            }}
                          >
                            Save Changesss
                          </Btn>
                        </ModalFooter>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              ) : (
                <BarLoader />
              )}
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default LoyaltyConfig;
