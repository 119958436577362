import React, { useEffect, useMemo, useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { addNewlightCategory, editlightCategory } from "./api";

const LightCategoryModal = ({ isOpen, ModalTitle, toggler, editData }) => {
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return editData;
    }, [editData]),
  });

  const functionCB = (data) => {
    if (data.success == true) {
      toggler();
      reset();
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message || "Something went wrong. Please try again laetr."
      );
    }
  };

  const onSubmit = (data) => {
    console.log(data);
    if (editData) {
      dispatch(editlightCategory({ ...data, CB: functionCB }));
    } else {
      dispatch(addNewlightCategory({ ...data, CB: functionCB }));
    }
  };

  // useEffect(() => {
  //   setValue("lightCategory", title);
  // }, [title])

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={toggler}
        togglerSave={handleSubmit(onSubmit)}
      >
        <Container fluid={true} className="bd-example-row">
          <form
            className="needs-validation"
            noValidate=""
            onSubmit={handleSubmit(onSubmit)}
          >
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Light Category Name:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Hue"
                    {...register("lightCategory", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.lightCategory && "lightCategory is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>
          </form>
        </Container>
      </CommonModal>
    </>
  );
};

export default LightCategoryModal;
