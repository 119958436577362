import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn, H6, H4 } from "../../../../AbstractElements";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import MeetingRoomModal from "./MeetingRoomModal";
import { fetchMeetingRoom } from "./api";
import { useDispatch } from "react-redux";
import { meetingRoomSelector } from "./meetingRoomSlice";
import { useSelector } from "react-redux";

const MeetingRoom = () => {
  const dispatch = useDispatch();
  const { meetingRoomData, isLoading, status } = useSelector(meetingRoomSelector);
  console.log("isLoading:", isLoading);
  console.log("status:", status);
  console.log(meetingRoomData, 'meeting')

  const [searchText, setSearchText] = useState("");
  useEffect(() => {
    dispatch(fetchMeetingRoom());
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();

    console.log("searchText:", searchText);
  };

  const [isAdd, setIsAdd] = useState(false);
  const handleAdd = () => setIsAdd(!isAdd);

  const [isEdit, setIsEdit] = useState(false);
  const handleEdit = () => setIsEdit(!isEdit);

  const handleDelete = () => {
    console.log("click");
    let conf = window.confirm("Are you sure you want to delete this amenity?");

    console.log(conf);
  };
 
  console.log(meetingRoomData, 'meetingRoomData')
  const MeetingRoomRData = meetingRoomData?.map((v, i) => ({
    index: i + 1,
    room_number: v.roomNo,
    maintenance_date: "05/09/2023 - 05/26/2023",
    action: (
      <Container className="d-flex btn-showcase">
        <Btn
          attrBtn={{
            color: "primary",
            outline: true,
            onClick: handleEdit,
          }}
        >
          {"Edit"}
        </Btn>
        <Btn
          attrBtn={{
            color: "danger",
            outline: true,
            onClick: handleDelete,
          }}
        >
          {"Delete"}
        </Btn>
      </Container>
    ),
  }));
  

  const MeetingRoomColumns = [
    {
      name: <H6>#</H6>,
      selector: (row) => row.index,
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6> Room Number</H6>,
      selector: (row) => row.room_number,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Maintenance Date</H6>,
      selector: (row) => row.maintenance_date,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
      width: "555px",
    },
  ];

  return (
    <Fragment>
      <MeetingRoomModal
        isOpen={isAdd}
        ModalTitle={"Add Component"}
        toggler={handleAdd}
      />
      <MeetingRoomModal
        isOpen={isEdit}
        ModalTitle={"Edit Component"}
        toggler={handleEdit}
      />

      {isLoading  ? null : (
        <Container fluid={true}>
          <div className="d-flex">
            <Breadcrumbs
              parent="Meeting Room Configuration"
              title="Meeting Room Configuration"
            />

            <div className="social-tab">
              <MySearchInput
                handleSearch={handleSearch}
                searchText={searchText}
                setSearchText={setSearchText}
              />

              <Btn
                attrBtn={{
                  className: "m-r-15 m-l-15 d-block",
                  color: "success",
                  // outline: true,
                  onClick: handleAdd,
                }}
              >
                Add Meeting Room
              </Btn>
            </div>
          </div>

          <Row>
            <Col sm="12">
              <Card>
                <Col sm="12 box-col-12">
                  <div className="social-tab">
                    <H4>MEETING ROOMS</H4>
                  </div>
                </Col>

                <CardBody className="p-t-0">
                  <div className="order-history table-responsive">
                    <DataTable
                      pagination
                      paginationServer
                      data={MeetingRoomRData}
                      columns={MeetingRoomColumns}
                      responsive={true}

                      // progressPending={loading}
                      // progressComponent={<Loader />}
                      // paginationTotalRows={totalRows}
                      // paginationDefaultPage={currentPage}
                      // onChangeRowsPerPage={handlePerRowsChange}
                      // onChangePage={handlePageChange}
                      // searching={true}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      )}
    </Fragment>
  );
};

export default MeetingRoom;
