import { createAsyncThunk } from "@reduxjs/toolkit";
import { AXIOS_HOSPITALITY, AXIOS_RESERVATION } from "../../../utils/axiosInterceptors";
import { config } from "../../../config";

export const getadminuser = createAsyncThunk(
  "adminuser/getadminuser",
  async (state, { getState, rejectWithValue, dispatch }) => {

    try {
      let { data } = await AXIOS_RESERVATION.get(`getadminuser`);

      return data;
    } catch (err) {

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const addnewadminuser = createAsyncThunk(
  "adminuser/addnewadminuser",
  async (state, { getState, rejectWithValue, dispatch }) => {
    let { CB, ...others } = state;
    others.hotelId = config.HOTELID;


    var headers = {
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.post(`addnewadminuser`, others, {
        headers,
      });

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(getadminuser());

        return data;
      }
    } catch (err) {
      CB && CB(err);
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const editadminuser = createAsyncThunk(
  "adminuser/editadminuser",
  async (state, { getState, rejectWithValue, dispatch }) => {
    let { CB, ...others } = state;

    try {
      let { data } = await AXIOS_RESERVATION.post(`editadminuser`, others);

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(getadminuser());

        return data;
      }
    } catch (err) {

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong Please try again later",
        },
      });
    }
  }
);

export const removeadminuser = createAsyncThunk(
  "adminuser/removeadminuser",
  async (state, { getState, rejectWithValue, dispatch }) => {

    let { id, CB } = state;

    var headers = {
      _id: id,
      "Access-Control-Allow-Origin": "*",
    };

    try {
      let { data } = await AXIOS_RESERVATION.delete(`removeadminuser`, {
        headers,
      });

      if (data?.success == false) {
        CB && CB(data);
      } else {
        CB && CB(data);
        dispatch(getadminuser());

        return data;
      }
    } catch (err) {

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const frontdeskPages = createAsyncThunk(
  "frontDeskData/frontdeskPages",
  async (state, { getState, rejectWithValue, dispatch }) => {

    try {
      let { data } = await AXIOS_HOSPITALITY.get(`frontdeskPages`);

      return data;
    } catch (err) {

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const frontdeskPermissions = createAsyncThunk(
  "frontDeskData/frontdeskPermissions",
  async (state, { getState, rejectWithValue, dispatch }) => {

    try {
      let { data } = await AXIOS_HOSPITALITY.get(`frontdeskPermissions`);

      return data;
    } catch (err) {

      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage:
            err?.result?.message ||
            "Something went wrong please try again later",
        },
      });
    }
  }
);

export const adminRoles = createAsyncThunk(
  "frontDeskData/adminRoles",
  async( state, { getState, rejectWithValue, dispatch }) => {
    try {
      let { data } = await AXIOS_HOSPITALITY.get(`adminRoles`);
      return data;
    } catch (error) {
      throw rejectWithValue({
        status: {
          apiSuccess: false,
          apiMessage: error?.result?.message || "Something went wrong please try again later"
        }
      })
    }
  }
)