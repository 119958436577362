import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn, H6, H4 } from "../../../AbstractElements";
import AppVersionsModal from "./AppVersionsModal";
import MySearchInput from "../../../mycomponents/MySearchInput";
import { deleteappversions, fetchAppVersions } from "./api";
import { useDispatch } from "react-redux";
import { appVersionsSelector } from "./appVerisonsSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import BarLoader from "../../../Layout/Loader/BarLoader";

const AppVersions = () => {
  const dispatch = useDispatch();
  const { appVersionsData, isLoading, status } =
    useSelector(appVersionsSelector);

  const [searchText, setSearchText] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(false);

  useEffect(() => {
    dispatch(fetchAppVersions());
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
  };

  const handleAdd = () => setIsAdd(!isAdd);
  const handleEdit = (data) => {
    setIsEdit(!isEdit);
    setEditData(data);
  };

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Data successfully deleted");
    } else {
      return toast.error(
        data.result?.message || "Oops.. Something went wrong Please try again"
      );
    }
  };

  const handleDelete = (id) => {

    let conf = window.confirm("Are you sure that you want to delete this details ?");

    if (conf && id) {
      dispatch(deleteappversions({ id, CB: functionCB }));
    }
  };

  const AppRow = appVersionsData?.result?.data
    ?.filter((val, i) => {
      const { appVersion, forceUpdate, osType, update } = val;

      return (
        (String(appVersion).includes(searchText)) ||
        (String(osType).toLowerCase().includes(searchText.toLowerCase()))
      );
    })
    .map((val, i) => {
      return {
        index: i + 1,
        appVersion: val.appVersion,
        forceUpdate: String(val.forceUpdate), // Convert to string representation
        osType: val.osType,
        update: String(val.update), // Convert to string representation
        action: (
          <Container className="d-flex btn-showcase">
            <Btn
              attrBtn={{
                color: "primary",
                outline: true,
                onClick: () => handleEdit(val),
              }}
            >
              {"Edit"}
            </Btn>
            <Btn
              attrBtn={{
                color: "danger",
                outline: true,
                onClick: () => handleDelete(val._id),
              }}
            >
              {"Delete"}
            </Btn>
          </Container>
        ),
      };
    });

  const AppColumn = [
    {
      name: <H6>#</H6>,
      selector: (row) => row.index,
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Operating System</H6>,
      selector: (row) => row.osType,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>App Version</H6>,
      selector: (row) => row.appVersion,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Force Update</H6>,
      selector: (row) => row.forceUpdate,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Update</H6>,
      selector: (row) => row.update,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
      width: "266px"
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <Fragment>
      <AppVersionsModal
        isOpen={isAdd}
        ModalTitle={"Add App Version"}
        toggler={handleAdd}
      />
      {isEdit && editData ? (
        <AppVersionsModal
          isOpen={isEdit}
          ModalTitle={"Edit App Version"}
          toggler={handleEdit}
          editData={editData}
        />
      ) : null}

      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs parent="App Versions" title="App Versions" />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />

            <Btn
              attrBtn={{
                className: "m-r-15 m-l-15 d-block",
                color: "success",
                // outline: true,
                onClick: handleAdd,
              }}
            >
              Add App Versions
            </Btn>
          </div>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab">
                  <H4>APP VERSIONS</H4>
                </div>
              </Col>

              <CardBody className="p-t-0">
                <div className="order-history table-responsive">
                  <DataTable
                    data={AppRow}
                    columns={AppColumn}
                    responsive={true}
                    pagination
                    paginationComponentOptions={paginationOptions}
                    subHeaderAlign="center"
                    progressPending={isLoading}
                    progressComponent={<BarLoader />}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AppVersions;
