import axios from "axios";
import React, { useState } from "react";
import { Col, FormGroup, Label, Row } from "reactstrap";
import { config } from "../../src/config";
import { Loader } from "react-feather";
// import Loader from "../Layout/Loader";

const InputPrev = (props) => {
  // console.log("props.src", props.src);

  const handleImageChange = async (e) => {
    props.setIsUploading(true);

    let img = Array.from(e.target.files);

    if (img) {
      props.setIsUploading(true);
    }

    let tempData = [];
    let formData = new FormData();

    for (let k = 0; k < img.length; k++) {
      formData.append("images", img[k]);
    }
    // console.log("formData", formData);

    await axios.post(config.reservationBackend, formData).then((result) => {
      // console.log("result", result);

      tempData.push(result.data.result.image);
    });
    // console.log("tempData:", tempData);

    let cldURL = `https://res.cloudinary.com/shashigroup/image/upload/`;
    props.setImageFile(`${cldURL}${tempData[0][0]}`);
    props.setIsUploading(false);
  };

  const handleRemove = () => {
    props.setIsUploading(false);
    props.setImageFile(null);
  };

  return (
    <>
      <Row className="d-flex justify-content-between">
        <Col className={props.inputLabelClass ? props.inputLabelClass : ""}>
          <FormGroup>
            <Label>{props.inputLabel}</Label>
            <div className="d-flex align-items-center">
              <input
                className="form-control"
                type="file"
                accept="image/*"
                onChange={handleImageChange}
              />
              <i
                className={
                  props.src ? "icon-close cursor-pointer m-l-5 f-20" : "d-none"
                }
                onClick={handleRemove}
              ></i>
            </div>
          </FormGroup>
        </Col>
        <Col className={props.imageLabelClass ? props.imageLabelClass : ""}>
          <FormGroup>
            <Label className="d-block">{props.imageLabel}</Label>

            {props.isUploading ? (
              <div className="d-flex justify-content-center m-t-35">
                <Loader />
              </div>
            ) : (
              <img
                className={props.src ? "img-prev" : "d-none"}
                src={props.src}
                alt=""
              />
            )}
          </FormGroup>
        </Col>
      </Row>
    </>
  );
};

export default InputPrev;
