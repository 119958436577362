import React from 'react'
import { Modal, ModalBody, ModalFooter } from 'reactstrap'
import { Btn } from '../../../../AbstractElements';

export const SettlementInfoModal = ({
  isOpen,
  toggle,
  data
}) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle} centered>
      <ModalBody>
        <span className="text-black">{data}</span>
      </ModalBody>
      <ModalFooter>
        <Btn attrBtn={{ color: "dark", outline: true, onClick: toggle }} >OK</Btn>
      </ModalFooter>
    </Modal>
  );
}