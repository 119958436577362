import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Col, Container, FormGroup, Label, Row } from "reactstrap";
import CommonModal from "../../../_core/Ui-kits/Modals/common/modal";
import MySelectDropd from "../../../mycomponents/MySelectDropd";
import { toast } from "react-toastify";
import { addnewadminuser, adminRoles, editadminuser, frontdeskPages, frontdeskPermissions } from "./api";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { frontDeskPagesSelector } from "./frontDeskPagesSlice";

const MasterUsersModal = ({ isOpen, ModalTitle, toggler, editData }) => {
  const dispatch = useDispatch();
  const frontDeskDataFromApi = useSelector(frontDeskPagesSelector);
  const [accessDataOpt, setAccessDataOpt] = useState([]);
  const [accessPages, setAccessPages] = useState([]);
  const [permissionsOpt, setPermissionsOpt] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [rolesOpt, setRolesOpt] = useState([]);
  const [roles, setRoles] = useState([]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: useMemo(() => {
      return editData;
    }, [editData]),
  });

  useEffect(() => {
    dispatch(adminRoles());
    dispatch(frontdeskPages());
    dispatch(frontdeskPermissions());
  }, [])

  useEffect(() => {
    if (frontDeskDataFromApi.status.apiSuccess) {
      let permissions = frontDeskDataFromApi?.frontDeskPermissions?.data?.map((val) => {
        if (val.isActive) return val.permission;
      })
      setRolesOpt(frontDeskDataFromApi.adminRoles.data);
      setAccessDataOpt(frontDeskDataFromApi.frontDeskPagesData.data);
      setPermissionsOpt(permissions);
    }
  }, [frontDeskDataFromApi])

  useEffect(() => {
    setValue("rolesOpt", rolesOpt);
    setValue("accessPages", accessPages);
  }, [rolesOpt, accessPages]);

  useEffect(() => {
    if(editData && accessDataOpt?.length) {
      let pages = [];
      for(let x of accessDataOpt) {
        for(let y of editData?.allowedPages ?? []) {
          if(x.path == y.path) pages.push(y.path);
        }
      }
      setAccessPages(pages);
    }
    if(editData) {
      setRoles(editData.roles?.map(roleObj => roleObj.role) ?? []);
      if(editData.allowedPages?.length) setPermissions(Object.keys(editData.allowedPages[0])?.filter(val => val != "path"));
    }
  }, [editData, rolesOpt, accessDataOpt, permissionsOpt])

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message ||
        "Something went wrong. Please try again later"
      );
    }
  };

  const onSubmit = (data) => {
    let rolesDetails = rolesOpt.filter(obj => roles.some(role => obj.role === role));
    let allPagePermissions = [];
    if(accessPages.length && permissions.length) {
      allPagePermissions = accessPages.map(page => {
        let perObj = {}
        permissions.map((permission) => {
          perObj[permission] = true
        })
    
        return {
          path: page,
          ...perObj
        };
      })
    }

    if(data.rolesOpt) delete data["rolesOpt"];
    if(data.accessPages) delete data["accessPages"];
    data.roles = rolesDetails;
    data.allowedPages = allPagePermissions;

    if (editData) {
      dispatch(editadminuser({ ...data, CB: functionCB }));
    } else {
      dispatch(addnewadminuser({ ...data, CB: functionCB }));
    }
    handleClose();
  };

  function getSelectedPermissions(page, data) {
    const matchedObject = data && data.length ? data.find(obj => obj.path === page) : {};
  
    if (matchedObject && permissionsOpt?.length) {
      return Object.keys(matchedObject).filter(key => permissionsOpt.includes(key));
    } else {
      return [];
    }
  }

  const handleClose = () => {
    toggler();
    reset();
    setRoles([]);
    setAccessPages([]);
  }

  return (
    <>
      <CommonModal
        isOpen={isOpen}
        title={ModalTitle}
        toggler={handleClose}
        togglerSave={handleSubmit(onSubmit)}
      >
        <Container fluid={true}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <FormGroup>
                  <Label>{"Email ID:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="xyz@gmail.com"
                    name="email"
                    {...register(`email`, {
                      required: "Email is required",
                      pattern: {
                        value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                        message: "Provide a valid Email ID"
                      }
                    })}
                    disabled={ editData ? true : false }
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.email && errors.email.message}
                  </span>
                </FormGroup>
              </Col>

              <Col>
                <FormGroup>
                  <Label>{"Name:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="John Doe"
                    name="name"
                    {...register(`name`, {
                      required: true,
                    })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.name && "Name is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            {!editData && (
              <Row>
                <Col>
                  <FormGroup>
                    <Label>{"Password:"}</Label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="*******"
                      name="password"
                      {...register(`password`, {
                        required: true,
                      })}
                    />
                    <span style={{ color: "#ff5370" }}>
                      {errors.password && "Password is required"}
                    </span>
                  </FormGroup>
                </Col>
              </Row>
            )}
          </form>

          <Row className="m-b-10">
            <Col>
              <Label className="f-16">User Roles:</Label>
              <MySelectDropd
                name={"userRoles"}
                selectedOpt={(role) => {
                  setRoles(role)
                }}
                options={
                  rolesOpt && rolesOpt.map(roleObj => {
                    return {
                      key: roleObj.role,
                      label: roleObj.displayRoleName
                    };
                  })
                }
                selectedValues={
                  editData 
                    ? editData.roles?.map(obj => obj.role)
                    : roles
                }
              />
            </Col>
          </Row>

          <Row className="m-b-10">
            <Col>
              <Label className="f-16">Access Pages:</Label>
              <MySelectDropd
                name={"accessPages"}
                selectedOpt={(page) => {
                  setAccessPages(page)
                }}
                options={accessDataOpt
                  && accessDataOpt.map(page => {
                    return {
                      key: page.path,
                      label: page.title
                    }
                  })
                }
                selectedValues={
                  editData
                    ? accessDataOpt &&
                    accessDataOpt.flatMap(x => {
                      const matchedPage = editData?.allowedPages?.find(y => x.path === y.path);
                      return matchedPage ? [matchedPage.path] : [];
                    })
                    : accessPages
                }
              />
            </Col>
          </Row>

          {
            accessPages?.length ?
              <Row className="m-b-10">
                <Col>
                  <Label className="f-16 m-t-5">Permissions: </Label>
                  <MySelectDropd
                      name={"permissions"}
                      selectedOpt={permission => {
                        setPermissions(permission)
                      }}
                      options={permissionsOpt}
                      selectedValues={
                        editData && editData.allowedPages
                          ? getSelectedPermissions(editData.allowedPages[0]?.path, editData.allowedPages)
                          : []
                      }
                    />
                </Col>
              </Row>
              : null
          }

        </Container>
      </CommonModal>
    </>
  );
};

export default MasterUsersModal;
