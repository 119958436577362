import {
  fetchDepartments,
  addDepartment,
  editDepartment,
  removeDepartment,
  fetchStaffUser,
  addNewStaffUser,
  editStaffUser,
  removeStaff,
} from "../Api/api";
const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  departmentsData: {},
  hotelStaffsData: {},
  isLoading: false,
  status: {
    apiMessage: "",
    apiSuccess: true,
  },
};

const hotelStaffSlice = createSlice({
  initialState,
  name: "hotelStaff",
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },

  extraReducers: (builder) => {
    // CASE FOR FETCH DEPARTMENTS --->>>
    builder
      .addCase(fetchDepartments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchDepartments.fulfilled, (state, action) => {
        state.isLoading = false;
        state.departmentsData = action.payload;
      })
      .addCase(fetchDepartments.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR ADD DEPARTMENTS --->>>
      .addCase(addDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addDepartment.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(addDepartment.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR EDIT DEPARTMENTS --->>>
      .addCase(editDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editDepartment.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(editDepartment.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      //  CASE FOR REMOVE DEPARTMENTS  --->>>
      .addCase(removeDepartment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeDepartment.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(removeDepartment.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR FETCH HOTEL STAFF DATA --->>>
      .addCase(fetchStaffUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchStaffUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hotelStaffsData = action.payload;
      })
      .addCase(fetchStaffUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR ADD NEW STAFF USER --->>>
      .addCase(addNewStaffUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addNewStaffUser.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(addNewStaffUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR EDIT STAFF USER --->>>
      .addCase(editStaffUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editStaffUser.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(editStaffUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      //  CASE FOR REMOVE STAFF USER  --->>>
      .addCase(removeStaff.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeStaff.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(removeStaff.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      });
  },
});

export default hotelStaffSlice.reducer;
export const { setStatus } = hotelStaffSlice.actions;
export const hotelStaffSelector = (state) => state.hotelStaff;
