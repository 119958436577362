import React, { Fragment, useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import DataTable from "react-data-table-component";
import {
  Breadcrumbs,
  Btn,
  H6,
  H5,
  UL,
  LI,
  OL,
  H4,
} from "../../../../AbstractElements";
import RatePlanModal from "./RatePlanModal";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import { useDispatch } from "react-redux";
import { ratePlansSelector } from "./ratePlansSlice";
import { useSelector } from "react-redux";
import { fetchRatePlans, removeRatePlanData } from "./api";
import BarLoader from "../../../../Layout/Loader/BarLoader";
import { toast } from "react-toastify";
import { memo } from "react";

const RatePlans = () => {
  const dispatch = useDispatch();
  const { ratePlansData, isLoading, status } = useSelector(ratePlansSelector);
  console.log("status:", status);
  console.log("ratePlansData:", ratePlansData);

  const [searchText, setSearchText] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(false);

  useEffect(() => {
    dispatch(fetchRatePlans());

    //
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();

    console.log("searchText:", searchText);
  };

  const handleAdd = () => setIsAdd(!isAdd);
  const handleEdit = (data) => {
    setIsEdit(!isEdit);
    setEditData(data);
  };

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Data successfully deleted");
    } else {
      return toast.error(
        data.result?.message ||
          "Oops! Something went wrong Please try again  later"
      );
    }
  };

  const handleDelete = (id) => {
    console.log("delete ic", id);
    let conf = window.confirm(
      "Are you sure you want to delete this Rate Plan?"
    );

    if (conf) {
      dispatch(removeRatePlanData({ id, CB: functionCB }));
    }

    console.log(conf);
  };

  const RoomTypesRow = ratePlansData?.result?.data
    ?.filter((val, i) => {
      return (
        val.ratePlanName.includes(searchText) ||
        val.ratePlanCode.includes(searchText) ||
        val.ratePlanName.toLowerCase().includes(searchText.toLowerCase()) ||
        val.ratePlanCode.toLowerCase().includes(searchText.toLowerCase()) ||
        val.segment.toLowerCase().includes(searchText.toLowerCase())
      );
    })
    .map((val, i) => {
      return {
        index: i + 1,
        name: (
          <H5>
            {val.isPrimary
              ? `${val.ratePlanName}   (Default)`
              : val.ratePlanName}
          </H5>
        ),

        is_corporate: val.isCorporate ? "Yes" : "No",
        code: val.ratePlanCode,
        segment: val.segment,
        is_hidden: val.isHide ? "Yes" : "No",
        action: (
          <Container className="d-flex btn-showcase">
            <Btn
              attrBtn={{
                color: "primary",
                outline: true,
                onClick: () => handleEdit(val),
              }}
            >
              {"Edit"}
            </Btn>
            <Btn
              attrBtn={{
                color: "danger",
                outline: true,
                onClick: () => handleDelete(val._id),
              }}
            >
              {"Delete"}
            </Btn>
          </Container>
        ),
      };
    });

  const RoomTypesColumns = [
    {
      name: <H6>#</H6>,
      selector: (row) => row.index,
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Name </H6>,
      selector: (row) => row.name,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Is Corporate</H6>,
      selector: (row) => row.is_corporate,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Code</H6>,
      selector: (row) => row.code,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Segment</H6>,
      selector: (row) => row.segment,
      sortable: true,
      center: true,
      wrap: true,
    },

    {
      name: <H6>Is Hidden</H6>,
      selector: (row) => row.is_hidden,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
      width: "333px",
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <Fragment>
      <RatePlanModal
        isOpen={isAdd}
        ModalTitle={"Add Rate Plan "}
        toggler={handleAdd}
      />

      {isEdit && editData ? (
        <RatePlanModal
          isOpen={isEdit}
          ModalTitle={"Edit Rate Plan"}
          toggler={handleEdit}
          editData={editData}
        />
      ) : null}

      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs
            parent="Rate Plan Configuration"
            title="Rate Plan Configuration"
          />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />

            <Btn
              attrBtn={{
                className: "m-r-15 m-l-15 d-block",
                color: "success",
                // outline: true,
                onClick: handleAdd,
              }}
            >
              Add Rate Plan
            </Btn>
          </div>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab">
                  <H4>RATE PLANS</H4>
                </div>
              </Col>

              <CardBody className="p-t-0">
                <div className="order-history table-responsive">
                  <DataTable
                    data={RoomTypesRow}
                    columns={RoomTypesColumns}
                    responsive={true}
                    pagination
                    paginationComponentOptions={paginationOptions}
                    subHeaderAlign="center"
                    progressPending={isLoading}
                    progressComponent={<BarLoader />}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default memo(RatePlans);
