import React, { useState } from "react";
import { Col, Container, FormGroup, Label, ModalFooter, Row } from "reactstrap";
import MyDropdown from "../../../../../mycomponents/MyDropdown";
import { useFieldArray, useForm } from "react-hook-form";
import { Btn } from "../../../../../AbstractElements";

const ButtonComp = ({ isEdit }) => {
  const [title, setTitle] = useState("Select Department");

  const { register, control, handleSubmit, reset, watch, errors } = useForm({
    defaultValues: {
      test: [],
    },
  });
  const { fields, append, prepend, remove, swap, move, insert, replace } =
    useFieldArray({
      control,
      name: "test",
    });

  const onSubmit = (data) => {
    console.log("data", data);
  };
  console.log("errors:", errors);

  return (
    <>
      <Container>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Row className="d-flex align-items-center">
            <Col>
              <FormGroup>
                <Label>{"Department:"}</Label>
                <MyDropdown
                  title={title}
                  setTitle={setTitle}
                  data={[
                    { opt: "Maintanance" },
                    { opt: "Front Dest" },
                    { opt: "Valet" },
                    { opt: "F&B" },
                    { opt: "Housekeeping" },
                    { opt: "Deployer" },
                    { opt: "Engineering" },
                  ]}
                />
              </FormGroup>
            </Col>
          </Row>
          <hr />

          <Row>
            <Col>
              <FormGroup>
                <Label>{"Description:"}</Label>
                <textarea
                  className="form-control"
                  placeholder=""
                  {...register(`type`, {
                    required: true,
                  })}
                />
                {/* <span style={{ color: "#ff5370" }}>
                  {errors.description && "Description is required"}
                </span> */}
              </FormGroup>
            </Col>
          </Row>

          {!isEdit ? (
            <ModalFooter className="m-t-5">
              <Btn
                attrBtn={{
                  color: "primary",
                  onClick: () => {
                    handleSubmit(onSubmit);
                  },
                }}
              >
                Save Changes
              </Btn>
            </ModalFooter>
          ) : (
            ""
          )}
        </form>
      </Container>
    </>
  );
};

export default ButtonComp;
