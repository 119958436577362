const { createSlice } = require("@reduxjs/toolkit");
const { frontdeskPages, frontdeskPermissions, adminRoles } = require("./api");

const initialState = {
    frontDeskPagesData: {},
    frontDeskPermissions: {},
    adminRoles: {},
    status: {
        apiMessage: "API successfull",
        apiSuccess: true,
    },
};

const frontDeskPagesSlice = createSlice({
    initialState,
    name: "frontDeskData",
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(adminRoles.pending, (state) => {
                // console.log("adminRoles api pending");
            })
            .addCase(adminRoles.fulfilled, (state, { payload }) => {
                state.adminRoles = payload.result;
            })
            .addCase(adminRoles.rejected, (state, { payload }) => {
                state.status = payload && payload.status;
            })

            .addCase(frontdeskPages.pending, (state) => {
                // console.log("frontdeskPages api is pending");
            })
            .addCase(frontdeskPages.fulfilled, (state, { payload }) => {
                state.frontDeskPagesData = payload.result;
            })
            .addCase(frontdeskPages.rejected, (state, { payload }) => {
                state.status = payload && payload.status;
            })

            .addCase(frontdeskPermissions.pending, (state) => {
                // console.log("frontdeskPermissions api pending");
            })
            .addCase(frontdeskPermissions.fulfilled, (state, { payload }) => {
                state.frontDeskPermissions = payload.result;
            })
            .addCase(frontdeskPermissions.rejected, (state, { payload }) => {
                state.status = payload && payload.status;
            });
    },
});

export default frontDeskPagesSlice.reducer;
export const { setStatus } = frontDeskPagesSlice.actions;
export const frontDeskPagesSelector = (state) => state.frontDeskData;