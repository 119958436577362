import React, { Fragment, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn, H6, H5, H4 } from "../../../../AbstractElements";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import ButtonCompModal from "./SelectComponets/ButtonCompModal";

const CategoryCompData = ({ activeTab, listData }) => {
  const [modalTitle, setModalTitle] = useState("Edit Data");

  const [isEdit, setIsEdit] = useState(false);
  const handleEdit = (val) => {
    setModalTitle(`Edit ${val} Data`);
    setIsEdit(!isEdit);

    //
  };

  const handleDelete = () => {
    console.log("click");
    let conf = window.confirm("Are you sure you want to delete this amenity?");

    console.log(conf);
  };

  const CategoryComponentRData = [
    {
      index: "1",
      name: "Bottled Water",
      category: "Essencial",
      component: "webView",
      action: (
        <Container className="d-flex btn-showcase">
          <Btn
            attrBtn={{
              color: "primary",
              outline: true,
              onClick: () => handleEdit("webView"),
            }}
          >
            {"Edit"}
          </Btn>
          <Btn
            attrBtn={{
              color: "danger",
              outline: true,
              onClick: handleDelete,
            }}
          >
            {"Delete"}
          </Btn>
        </Container>
      ),
    },
    {
      index: "2",
      name: "Water",
      category: "Essencial",
      component: "increment",
      action: (
        <Container className="d-flex btn-showcase">
          <Btn
            attrBtn={{
              color: "primary",
              outline: true,
              onClick: () => handleEdit("increment"),
            }}
          >
            {"Edit"}
          </Btn>
          <Btn
            attrBtn={{
              color: "danger",
              outline: true,
              onClick: handleDelete,
            }}
          >
            {"Delete"}
          </Btn>
        </Container>
      ),
    },
  ];
  const CategoryComponentColumns = [
    {
      name: <H6>#</H6>,
      selector: (row) => row.index,
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Name</H6>,
      selector: (row) => row.name,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Category</H6>,
      selector: (row) => row.category,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Component</H6>,
      selector: (row) => row.component,
      sortable: true,
      center: true,
      wrap: true,
    },
    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
      width: "555px",
    },
  ];

  return (
    <>
      <ButtonCompModal
        isOpen={isEdit}
        ModalTitle={modalTitle}
        toggler={handleEdit}
        isEdit={isEdit}
      />

      <Container>
        <div className="social-tab p-b-0">
          <H4> {listData[activeTab].name} </H4>
        </div>
        <hr />

        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="order-history table-responsive">
                  <DataTable
                    pagination
                    paginationServer
                    data={CategoryComponentRData}
                    columns={CategoryComponentColumns}
                    responsive={true}

                    // responsive={true}
                    // progressPending={loading}
                    // progressComponent={<Loader />}
                    // paginationTotalRows={totalRows}
                    // paginationDefaultPage={currentPage}
                    // onChangeRowsPerPage={handlePerRowsChange}
                    // onChangePage={handlePageChange}
                    // searching={true}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CategoryCompData;
