import React, { useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { toast } from "react-toastify";
import { useForm } from "react-hook-form";
import { addDepartment, editDepartment } from "../Api/api";
import { useDispatch } from "react-redux";

const DepartmentsModal = ({ isOpen, ModalTitle, toggler, editData }) => {
 
 const dispatch = useDispatch()

  const {
    register,
    handleSubmit,
    reset,

    formState: { errors },
  } = useForm({
    defaultValues:editData
  });

  const functionCB = (data) => {
    if (data.success == true) {
      toggler();
      return toast.success(data.result?.message || "Successful");
    } else if (data.success == false) {
      return toast.error(
        data.result?.message || "Something went wrong. Please try again laetr."
      );
    }
  };

  const onSubmit = (data) => {
    console.log('onsubmit data',data);
    const newData = { ...data, CB: functionCB };
    
     if (editData) {
       dispatch(editDepartment(newData));
     } else {
       dispatch(addDepartment(newData));
     }
  };

  return (
    <>
      <CommonModal isOpen={isOpen} title={ModalTitle} toggler={toggler} togglerSave={handleSubmit(onSubmit)}>
        <Container fluid={true} className="bd-example-row">
          <form
            className="needs-validation"
            noValidate=""
           // onSubmit={handleSubmit(onSubmit)}
          >
            <Row>
              <Col sm="9">
                <FormGroup>
                  <Label>{"Department Name:"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Department"
                    {...register("departmentName", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.departmentName && "Department name is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>
          </form>
        </Container>
      </CommonModal>
    </>
  );
};

export default DepartmentsModal;
