import { createSlice } from "@reduxjs/toolkit";
import { addappversions, editappversions, fetchAppVersions } from "./api";

const initialState = {
    appVersionsData: {},
    isLoading: false,
    status: {
        apiMessage: "",
        apiSuccess: true,
    },
};

const appVersionsSlice = createSlice({
    initialState,
    name: "fetchAppVersions",
    reducers: {
        setStatus: (state, action) => {
            state.status = action.payload;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(fetchAppVersions.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(fetchAppVersions.fulfilled, (state, action) => {
                state.isLoading = false;
                state.appVersionsData = action.payload;
            })
            .addCase(fetchAppVersions.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.status = payload.status;
            })

            // CASE FOR ADD APPVERSION
            .addCase(addappversions.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(addappversions.fulfilled, (state, { payload }) => {
                state.isLoading = false;
            })
            .addCase(addappversions.rejected, (state, { payload }) => {
                state.isLoading = true;
                state.status = payload.status;
            })

            // CASE FOR EDIT APPVESRIONS

            .addCase(editappversions.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(editappversions.fulfilled, (state, { payload }) => {
                state.isLoading = false;
            })
            .addCase(editappversions.rejected, (state, { payload }) => {
                state.isLoading = false;
                state.status = payload.status;
            })
    },
});

export default appVersionsSlice.reducer;
export const { setStatus } = appVersionsSlice.actions;
export const appVersionsSelector = (state) => state.fetchAppVersions;
