import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { Breadcrumbs, Btn, H6, H4 } from "../../../../AbstractElements";
import MySearchInput from "../../../../mycomponents/MySearchInput";
import LightCategoryModal from "./LightCategoryModal";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { lightCategorySelector } from "./lightCategorySlice";
import { fetchLightCategory, removelightCategory } from "./api";
import { toast } from "react-toastify";
import BarLoader from "../../../../Layout/Loader/BarLoader";

const LightCategory = () => {
  const dispatch = useDispatch();
  const { lightCategoryData, isLoading, status } = useSelector(
    lightCategorySelector
  );
  console.log("isLoading:", isLoading);
  console.log("status:", status);
  console.log("lightCategoryData:", lightCategoryData);

  const [searchText, setSearchText] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [editData, setEditData] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  useEffect(() => {
    dispatch(fetchLightCategory());
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    console.log("searchText:", searchText);
  };

  const handleAdd = () => setIsAdd(!isAdd);
  const handleEdit = (data) => {
    setIsEdit(!isEdit);
    setEditData(data);
  };

  const functionCB = (data) => {
    if (data.success == true) {
      return toast.success(data.result?.message || "Data successfully deleted");
    } else {
      return toast.error(
        data.result?.message ||
          "Oops.. Something went wrong Please try again later"
      );
    }
  };

  const handleDelete = (id) => {
    console.log("delete id ", id);
    let conf = window.confirm("Are you sure you want to delete this amenity?");

    if (conf) {
      dispatch(removelightCategory({ id, CB: functionCB }));
    }
  };

  const LightCategoryRData = lightCategoryData?.result?.data
    ?.filter((val, i) => {
      return (
        val &&
        val.lightCategory &&
        (val.lightCategory.includes(searchText) ||
          val.lightCategory.toLowerCase().includes(searchText.toLowerCase()))
      );
    })
    .map((val, i) => {
      if (val) {
        return {
          name: val.lightCategory, 
          code: val.lightCategory, 
          category: val.lightCategory, 
          action: (
            <Container className="d-flex btn-showcase">
              <Btn
                attrBtn={{
                  color: "primary",
                  outline: true,
                  onClick: () => handleEdit(val),
                }}
              >
                {"Edit"}
              </Btn>
              <Btn
                attrBtn={{
                  color: "danger",
                  outline: true,
                  onClick: () => handleDelete(val._id),
                }}
              >
                {"Delete"}
              </Btn>
            </Container>
          ),
        };
      } else {
        return null;
      }
    });

  const LightCategoryColumns = [
    {
      name: <H6>#</H6>,
      selector: (row, index) => (currentPage - 1) * perPage + (index + 1),
      sortable: true,
      center: true,
      wrap: true,
      width: "77px",
    },
    {
      name: <H6>Name</H6>,
      selector: (row) => row.name,
      sortable: true,
      center: true,
      wrap: true,
    },

    {
      name: <H6>Action</H6>,
      selector: (row) => row.action,
      center: true,
      wrap: true,
      width: "555px",
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    setPerPage(newPerPage);
    setCurrentPage(page);
  };

  return (
    <Fragment>
      <LightCategoryModal
        isOpen={isAdd}
        ModalTitle={"Add Light Category"}
        toggler={handleAdd}
      />
      {isEdit && editData ? (
        <LightCategoryModal
          isOpen={isEdit}
          ModalTitle={"AddOns"}
          toggler={handleEdit}
          editData={editData}
        />
      ) : null}

      <Container fluid={true}>
        <div className="d-flex">
          <Breadcrumbs
            parent="Department Configuration"
            title="Department Configuration"
          />

          <div className="social-tab">
            <MySearchInput
              handleSearch={handleSearch}
              searchText={searchText}
              setSearchText={setSearchText}
            />

            <Btn
              attrBtn={{
                className: "m-r-15 m-l-15 d-block",
                color: "success",
                // outline: true,
                onClick: handleAdd,
              }}
            >
              Add
            </Btn>
          </div>
        </div>

        <Row>
          <Col sm="12">
            <Card>
              <Col sm="12 box-col-12">
                <div className="social-tab">
                  <H4>DEPARTMENTS DATA</H4>
                </div>
              </Col>

              <CardBody>
                <div className="order-history table-responsive">
                  <DataTable
                    data={LightCategoryRData}
                    columns={LightCategoryColumns}
                    responsive={true}
                    pagination
                    paginationComponentOptions={paginationOptions}
                    subHeaderAlign="center"
                    progressPending={isLoading}
                    progressComponent={<BarLoader />}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default LightCategory;
