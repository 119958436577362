import React, { Fragment, useEffect, useState } from 'react';
import { H6 } from '../../../../../../AbstractElements';
import { Card, CardBody, Row } from 'reactstrap';
import TableHeaderComp from '../../../../../../mycomponents/TableHeaderComp';
import DataTable from 'react-data-table-component';

export const TVandMusic = ({ musics, tvs, currentNavItemLink }) => {
   const [activeTab, setActiveTab] = useState(0);

   useEffect(() => {
      setActiveTab(0);
   }, [currentNavItemLink]);

   const wakeUpCallSubTabs = [
      { title: "Music" },
      { title: "TV" }
   ]

   let tableColumns = [
      {
         name: <H6>Name</H6>,
         selector: (row) => row.name,
         center: true,
         wrap: true,
         minWidth: "223px"
      },
      {
         name: <H6>URL</H6>,
         selector: (row) => <a href={row.url} target='_blank' rel="noreferrer">{row.url}</a>,
         center: true,
         wrap: true,
         minWidth: "223px",
         omit: activeTab == 1 ? true : false
      }
  ];

  return (
    <Fragment>
      <Row className='m-0'>
         <Card className="email-body radius-left">
            <div className="social-tab align-items-baseline">
               <TableHeaderComp
                  headingData={wakeUpCallSubTabs}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
               />
            </div>
            <CardBody className='pt-0'>
               <div className="order-history table-responsive">
                  {
                     activeTab == 0 ?
                        <DataTable
                           data={musics}
                           columns={tableColumns}
                           responsive={true}
                        />
                     :
                        <DataTable
                           data={tvs}
                           columns={tableColumns}
                           responsive={true}
                        />
                  }
               </div>
            </CardBody>
         </Card>
      </Row>
    </Fragment>
  )
}