const { createSlice } = require("@reduxjs/toolkit");
const {
  fetchAllLightOfHotel,
  fetchAllWifiOfHotel,
  fetchAllThermostatOfHotel,
  addAllDevices,
  editAllDevices,
  removeAllDevices,
  updateThermostate
} = require("./api");

const initialState = {
  allLightDeviceData: {},
  allWifiOfHotelData: {},
  allThermostatData: {},
  isLoading: false,
  status: {
    apiMessage: "",
    apiSuccess: true,
  },
};

const allDeviceSlice = createSlice({
  initialState,
  name: "allDevicesOfHotel",
  reducers: {
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },

  extraReducers: (builder) => {
    // CASE FOR FETCH ALL LIGHT OF HOTEL --->>>
    builder
      .addCase(fetchAllLightOfHotel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllLightOfHotel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allLightDeviceData = action.payload;
      })
      .addCase(fetchAllLightOfHotel.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR FETCH ALL WIFI OF HOTEL --->>>
      .addCase(fetchAllWifiOfHotel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllWifiOfHotel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allWifiOfHotelData = action.payload;
      })
      .addCase(fetchAllWifiOfHotel.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR FETCH ALL THERMOSTAT OF HOTEL --->>>
      .addCase(fetchAllThermostatOfHotel.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchAllThermostatOfHotel.fulfilled, (state, action) => {
        state.isLoading = false;
        state.allThermostatData = action.payload;
      })
      .addCase(fetchAllThermostatOfHotel.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR ADD ALL DEVICES --->>>
      .addCase(addAllDevices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addAllDevices.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(addAllDevices.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      // CASE FOR EDIT ALL DEVICES --->>>
      .addCase(editAllDevices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editAllDevices.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(editAllDevices.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      //  CASE FOR REMOVE ALL DEVICES  --->>>
      .addCase(removeAllDevices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeAllDevices.fulfilled, (state, { payload }) => {
        state.isLoading = false;
      })
      .addCase(removeAllDevices.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })

      //  CASE FOR UPDATE THERMOSTATE  --->>>
      .addCase(updateThermostate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateThermostate.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(updateThermostate.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.status = payload.status;
      })
  },
});

export default allDeviceSlice.reducer;
export const { setStatus } = allDeviceSlice.actions;
export const allDeviceSelector = (state) => state.allDevicesOfHotel;
