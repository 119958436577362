import React, { useEffect, useState } from "react";
import CommonModal from "../../../../_core/Ui-kits/Modals/common/modal";
import { Col, Container, FormGroup, Input, Label, Row } from "reactstrap";
import MyDropdown from "../../../../mycomponents/MyDropdown";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { categoriesSelector } from "./categoriesSlice";
import { useDispatch } from "react-redux";
import { addNewCategoryComponent, editCategoryComponent } from "./categoriesApi";
import { config } from "../../../../config";
import { toast } from "react-toastify";

export const EssentialsModal = ({ isOpen, ModalTitle, toggler, editData, existingViewRanks }) => {
  const { departmentsData, error } = useSelector(categoriesSelector);
  const dispatch = useDispatch();
  const [componentName, setComponentName] = useState("increment");
  const [department, setDepartment] = useState("Select Department");
  const [inactive, setInactive] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if(editData) {
      setComponentName(editData.viewType);
      if(editData.department) {
        setDepartment(departmentsData?.find(obj => obj._id == editData.department)?.departmentName ?? "Select Department");
      }
      setIsVisible(editData.isVisible);
      setInactive(editData.inactive ?? false);
    }
  }, [editData]);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    defaultValues: editData ? { ...editData, categoryName: "Essentials" } : { categoryName: "Essentials", type: "request" }
  });

  useEffect(() => {
    setValue("componentName", componentName);
  }, [componentName]);

  useEffect(() => {
    if(department !== "Select Department") setValue("department", department);
  }, [department]);

  useEffect(() => {
    setValue("inactive", inactive);
  }, [inactive]);

  useEffect(() => {
    setValue("isVisible", isVisible);
  }, [isVisible]);

  if(editData) existingViewRanks = existingViewRanks.filter(val => val != editData.viewRank);

  let departments = JSON.parse(JSON.stringify(departmentsData ?? []));
  departments = departments?.map((obj) => {
    obj.opt = obj.departmentName;
    return obj;
  });

  const functionCB = (data) => {
    if(data.success) {
      return toast.success("New Essential Added Successfully");
    } else {
      return toast.error(error ?? "Something went wrong. Please try again later.");
    }
  }

  const editCB = (data) => {
    if(data?.success) {
      toast.success("Edited Successfully");
    } else {
      return toast.error("Something went wrong. Please try again later");
    }
  }

  const handleClose = () => {
    toggler();
    reset();
    setComponentName("increment");
    setValue("componentName", "increment");
    setDepartment("Select Department");
    setInactive(false);
    setValue("inactive", false);
    setIsVisible(true);
    setValue("isVisible", true);
  }

  const onSubmit = (formValues) => {
    handleClose();

    formValues.amount = parseInt(formValues.amount);
    formValues.quantity = parseInt(formValues.quantity);
    formValues.maxQuantityPerRoom = parseInt(formValues.maxQuantityPerRoom);
    formValues.viewRank = parseInt(formValues.viewRank);

    let { categoryName, componentName, isVisible, ...data } = formValues;
    data.hotelId = config.HOTELID;

    for(let key in data) {
      delete formValues[`${key}`];
    }

    data.department = departmentsData?.find(obj => obj.departmentName == data.department)?._id;

    delete data._id;
    formValues.data = data;

    if(editData) {
      dispatch(editCategoryComponent({ ...formValues, id: editData._id, CB: editCB }));
    } else {
      dispatch(addNewCategoryComponent({ ...formValues, CB: functionCB }));
    }
  }

  return (
    <>
      <CommonModal
         isOpen={isOpen}
         title={ModalTitle}
         toggler={handleClose}
         togglerSave={handleSubmit(onSubmit)}
      >
        <Container>
          <form
            className="needs-validation"
            noValidate=""
          >
            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Category Name"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="categoryName"
                    {...register("categoryName", { required: true })}
                    disabled={true}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.categoryName && "Category Name is required"}
                  </span>
                </FormGroup>
              </Col>
              <Col sm="6" col="9" className="btn-showcase">
                <FormGroup>
                  <Label>{"Component Name"}</Label>
                  <MyDropdown
                    title={componentName}
                    setTitle={setComponentName}
                    data={[{ opt: "increment" }, { opt: "incrementwithprice" }]}
                    registerProps={register("componentName", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.componentName && "Component Name is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row className="d-flex">
              <Col>
                <FormGroup>
                  <Label>{"Item Name"}</Label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    {...register("name", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.name && "Item Name is required"}
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label>{"Amount"}</Label>
                  <input
                    className="form-control"
                    type="number"
                    name="amount"
                    {...register("amount", { required: true })}
                  />
                  <span style={{ color: "#ff5370" }}>
                    {errors.amount && "Amount is required"}
                  </span>
                </FormGroup>
              </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{"Description"}</Label>
                     <input
                        className="form-control"
                        type="text"
                        name="description"
                        {...register("description", { required: true })}
                     />
                    <span style={{ color: "#ff5370" }}>
                      {errors.description && "Description is required"}
                    </span>
                  </FormGroup>
              </Col>
            </Row>

            <Row>
               <Col>
                  <FormGroup>
                     <Label>{"Quantity"}</Label>
                     <input
                        className="form-control"
                        type="number"
                        name="quantity"
                        {...register("quantity", { required: true })}
                     />
                    <span style={{ color: "#ff5370" }}>
                      {errors.quantity && "Quantity is required"}
                    </span>
                  </FormGroup>
               </Col>
               <Col>
                  <FormGroup>
                     <Label>{"Max Quantity Per Room"}</Label>
                     <input
                        className="form-control"
                        type="number"
                        name="maxQuantityPerRoom"
                        {...register("maxQuantityPerRoom", { required: true })}
                     />
                    <span style={{ color: "#ff5370" }}>
                      {errors.maxQuantityPerRoom && "Max Quantity is required"}
                    </span>
                  </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col sm="6">
                <FormGroup>
                    <Label>{"Type"}</Label>
                    <input
                      className="form-control"
                      type="text"
                      name="type"
                      {...register("type", { required: true })}
                      disabled={true}
                    />
                  <span style={{ color: "#ff5370" }}>
                    {errors.type && "Type is required"}
                  </span>
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                    <Label>{"View Rank"}</Label>
                    <input
                      className="form-control"
                      type="number"
                      name="viewRank"
                      {...register("viewRank", {
                          required: true,
                          min: { value: 1 },
                          validate: {
                            existing: (value) => !existingViewRanks.includes(parseInt(value)),
                          }
                      })}
                    />
                    <span style={{ color: "#ff5370" }}>
                      {errors.viewRank?.type == "min" && "View rank should be at least 1"}
                      {errors.viewRank?.type == "existing" && "This view rank already exists"}
                      {errors.viewRank?.type == "required" && "View rank is required"}
                    </span>
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-3">
               <Col sm="6" col="9" className="btn-showcase">
                  <FormGroup>
                     <Label>{"Department"}</Label>
                     <MyDropdown
                        title={department}
                        setTitle={setDepartment}
                        data={departments}
                        registerProps={register("department", { required: true })}
                     />
                    <span style={{ color: "#ff5370" }}>
                      {errors.department && "Department is required"}
                    </span>
                  </FormGroup>
               </Col>
            </Row>

            <Row className="mb-2">
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="inactive"
                    name="inactive"
                    type="checkbox"
                    checked={inactive}
                    onChange={(e) => setInactive(e.target.checked)}
                  />
                  <Label className="d-block" htmlFor="inactive">
                    Inactive ?
                  </Label>
                </div>
              </Col>
              <Col>
                <div className="d-flex">
                  <Input
                    className="checkbox_animated"
                    id="isVisible"
                    name="isVisible"
                    type="checkbox"
                    checked={isVisible}
                    onChange={(e) => setIsVisible(e.target.checked)}
                  />
                  <Label className="d-block" htmlFor="isVisible">
                    Is Visible ?
                  </Label>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </CommonModal>
    </>
  );
};