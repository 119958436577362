import { createSlice } from "@reduxjs/toolkit";
import { fetchMeetingRoom } from "./api";

const initialState = {
   meetingRoomData:[],
    isLoading: false,
    status: {
      apiMessage: "",
      apiSuccess: true,
    },
  

};

const meetingRoomSlice = createSlice({
    initialState,
    name: "fetchMeetingRoomConfig",
    reducers: {
      setStatus: (state, action) => {
        state.status = action.payload;
      },
    },
  
    extraReducers: (builder) => {
      builder
        .addCase(fetchMeetingRoom.pending, (state) => {
          state.isLoading = true;
        })
        .addCase(fetchMeetingRoom.fulfilled, (state, action) => {
          console.log("meetingRoom fulfilled:", action.payload);
  
          state.isLoading = false;
          state.meetingRoomData = action.payload.result?.data;
         
        })
        .addCase(fetchMeetingRoom.rejected, (state, { payload }) => {
          // console.log("meetingRoom rejected:", payload);
  
          state.isLoading = false;
          state.status = payload.status;
        })
  
        // // CASE FOR EDIT meetingRoom --->>>
        // .addCase(editMeetingRoom.pending, (state) => {
        //   state.isLoading = true;
        // })
        // .addCase(editMeetingRoom.fulfilled, (state, { payload }) => {
        //   state.isLoading = false;
        // })
        // .addCase(editMeetingRoom.rejected, (state, { payload }) => {
        //   state.isLoading = false;
        //   state.status = payload.status;
        // });
    },
  });


export default meetingRoomSlice.reducer;
export const { setStatus } = meetingRoomSlice.actions;
export const meetingRoomSelector = (state) => state.fetchMeetingRoom;
